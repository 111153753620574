import {createAction, handleActions} from 'redux-actions';
import {getUrl, postUrl} from "../../utils/api";

const REPORTS_PAGE_LOADED = "REPORTS_PAGE_LOADED";
const REPORTS_PAGE_UNLOADED = "REPORTS_PAGE_UNLOADED";

export function loadReportsPage(page, size) {
    return async (dispatch) => {
        const pageResponse = await dispatch(getUrl('/reports', {page, size}));
        dispatch(pageLoaded(pageResponse));
        return pageResponse;
    }
}

export function sendDayReports() {
    return async dispatch => await dispatch(postUrl('/reports/send-day-reports'));
}

export function sendWeekReports() {
    return async dispatch => await dispatch(postUrl('/reports/send-week-reports'));
}

export function sendMonthReports() {
    return async dispatch => await dispatch(postUrl('/reports/send-month-reports'));
}

function pageLoaded(page) {
    return createAction(REPORTS_PAGE_LOADED)({page});
}

export function unloadReportsPage() {
    return createAction(REPORTS_PAGE_UNLOADED)();
}

export default handleActions({
    [REPORTS_PAGE_LOADED]: (state, {payload: {page}}) => page,
    [REPORTS_PAGE_UNLOADED]: () => {return {}}
}, {});