import React, {useState} from "react";
import RegistersGraph from "../registers/RegistersGraph";
import moment from "moment";
import DateFilterForm from "../../../components/forms/DateFilterForm";
import {useDispatch} from "react-redux";
import BoardModal from "../../../components/modals/BoardModal";
import PropTypes from "prop-types";
import {createRegistersDashboard, DASHBOARD_TYPE} from "../../../reducers/dashboards/entities";
import {METRIC_VIZUALIZATION} from "../../../constants";
import MetricOptions from "../../../components/MetricOptions";

const initState = {
    from: moment().subtract(1, 'days').format(),
    to: moment().format(),
    interval: null,
    vizualization: METRIC_VIZUALIZATION.GAUGE
};

const RegistersGraphBoardModal = ({dataKey, registerIds, handleClose}) => {
    const [state, setState] = useState(initState);
    const dispatch = useDispatch();
    const {from, to, interval, vizualization} = state;

    const handleSubmitFilter = ({from, to}) => {
        setState(Object.assign({}, state,{from, to}));
    };

    const handleCreateDashboard = async (_e) => {
        await dispatch(createRegistersDashboard(registerIds, DASHBOARD_TYPE.REGISTERS_GRAPH));
    }

    const onIntervalSelected = (interval) => {
        setState(Object.assign({}, state,{interval}));
    }

    const onVizualizationSelected = (vizualization) => {
        setState(Object.assign({}, state,{vizualization}));
    }

    return (
        <BoardModal
            title={"Graf"}
            handleClose={handleClose}
            handleCreateDashboard={handleCreateDashboard}>
                <DateFilterForm
                    from={moment(from)}
                    to={moment(to)}
                    onSubmit={handleSubmitFilter}/>
                <MetricOptions
                    selectedInterval={interval}
                    onIntervalSelected={onIntervalSelected}
                    selectedVizualization={vizualization}
                    onVizualizationSelected={onVizualizationSelected}/>
                <RegistersGraph
                    vizualization={vizualization}
                    interval={interval}
                    width={900}
                    label={"Graf"}
                    registerIds={registerIds}
                    dataKey={dataKey}
                    from={from}
                    to={to}/>
        </BoardModal>
    )
};

RegistersGraphBoardModal.propTypes = {
    dataKey: PropTypes.string.isRequired,
    registerIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    handleClose: PropTypes.func.isRequired
}

export default RegistersGraphBoardModal;
