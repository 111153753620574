import React from 'react';
import RegisterTypeForm from "../../../components/forms/registers/RegisterTypeForm";
import PropTypes from "prop-types";
import {handleApiError, MESSAGE_SEVERENITY, newMessage} from "../../../reducers/messages";
import {createRegisterType, updateRegisterType} from "../../../reducers/registerTypes/map";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";

const RegisterTypeEdit = ({registerType}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleOnSubmit = async (values) => {
        if (registerType) {
            try {
                await dispatch(updateRegisterType(registerType.id, values));
                dispatch(newMessage(MESSAGE_SEVERENITY.SUCCESS, "Typ registru uložen"));
            } catch (error) {
                dispatch(handleApiError(error));
            }
        } else {
            try {
                const registerType = await dispatch(createRegisterType(values));
                dispatch(newMessage(MESSAGE_SEVERENITY.SUCCESS, "Nový typ registru vytvořen"));
                history.push(`/registers/types/${registerType.id}`);
            } catch (error) {
                dispatch(handleApiError(error));
            }
        }
    }

    const initValue = !registerType ? {} : {
        name: registerType.name,
        coeficient: registerType.coeficient,
        minValue: registerType.minValue,
        maxValue: registerType.maxValue,
        rawValueType: registerType.rawValueType,
        valueType: registerType.valueType,
        reportType: registerType.reportType,
        unit: registerType.unit,
        precision: registerType.precision
    };
    return (
        <RegisterTypeForm
            initialValues={initValue}
            onSubmit={handleOnSubmit}/>
    )
}

RegisterTypeEdit.propsTypes = {
    registerType: PropTypes.object
}

export default RegisterTypeEdit;
