import React from "react";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import {useSelector} from "react-redux";
import SelectedRegistersEdit from "../dashboards/SelectedRegistersEdit";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";


const DashboardConfigRegistersModal = ({dashboardId, handleClose, handleConfirm}) => {
    const dashboard = useSelector(state => state.dashboards.entities[dashboardId]);

    return (
        <Dialog
            maxWidth={"md"}
            open={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{dashboard.name} dashboard - registry</DialogTitle>
            <DialogContent>
                <SelectedRegistersEdit dashboard={dashboard}/>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleConfirm}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
};

DashboardConfigRegistersModal.propTypes = {
    dashboardId: PropTypes.number.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default DashboardConfigRegistersModal;
