import React from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import RegisterEdit from "../../panels/registers/RegisterEdit";


const RegisterCreatePage = () => {
    return (
        <Grid container direction={"column"}>
            <Typography variant="h4" component="h1" gutterBottom>
                Nový registr
            </Typography>
            <RegisterEdit/>
        </Grid>
    );
}

export default RegisterCreatePage;
