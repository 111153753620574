import React from 'react';
import PropTypes from "prop-types";
import RegistersGrid from "../registers/RegistersGrid";
import DashboardContainer from "./DashboardContainer";

const UnreachableRegistersDashboard = ({dashboard}) => {
    const {name, id} = dashboard;
    return (
        <DashboardContainer
            title={name}
            id={id}>
            <RegistersGrid displayMode={"basic"} filterMode={"unreachable"}/>
        </DashboardContainer>
    )
}

UnreachableRegistersDashboard.propTypes = {
    dashboard: PropTypes.object.isRequired
};

export default UnreachableRegistersDashboard;
