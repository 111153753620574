import React, {useEffect} from "react";
import MaterialTable from "material-table";
import Paper from "@mui/material/Paper";
import {useDispatch} from "react-redux";
import {loadUserAccessesPage, unloadUserAccessesPage} from "../../../reducers/users/accessesPage";
import {formatDate} from "../../../utils/formatters";
import {handleApiError} from "../../../reducers/messages";
import {defaultMarcoTheme} from "../../../theme";
import {ThemeProvider} from "@mui/material";

const UserAccessesGrid = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        return () => dispatch(unloadUserAccessesPage())
    }, [])

    const getData = async ({pageSize, page}) => {
        try {
            const fetchedPage = await dispatch(loadUserAccessesPage(page, pageSize));
            return ({
                data: fetchedPage.content,
                page,
                totalCount: fetchedPage.totalElements
            });
        } catch (e) {
            dispatch(handleApiError(e));
        }

        return ({
            data: [],
            page,
            totalCount: 0
        });
    };

    return (
        <ThemeProvider theme={defaultMarcoTheme}>
        <MaterialTable
                options={{
                    sorting: false,
                    search: false,
                    cellStyle: {padding: '0.3em'},
                    headerStyle: {padding: '0.3em'},
                    pageSize:15,
                    pageSizeOptions: [15, 30, 100],
                    rowStyle: (access, index) => {
                        return {
                            backgroundColor: index % 2 ? "white" : "aliceBlue",
                            color: access.authenticated ? "inherited" : "darkred"
                        }
                    }
                }}
                components={{
                    Container: props => <Paper {...props} elevation={0}/>
                }}
                columns={[
                    {
                        title: 'Jméno',
                        field: 'username'
                    },{
                        title: 'Přihlášen',
                        field: 'authenticaged',
                        render: ({authenticated}) => authenticated ? "ano" : "ne"
                    }, {
                        title: 'Datum',
                        field: 'date',
                        render: ({date}) => formatDate(date)
                    }
                ]}
                data={getData}
                title="Přístupy"
            />
        </ThemeProvider>
    );
}


export default UserAccessesGrid