import {createAction, handleActions} from 'redux-actions';
import {deleteUrl, getUrl, NONE_RESPONSE, postUrl, putUrl} from "../../utils/api";
import {handleFetchError} from "../messages";
import {GLOBAL_RESET_ACTION} from "../global";

const USER_LOADED = 'USER_LOADED';
const USER_UNLOADED = 'USER_UNLOADED';
const initState = {};

export const userLoaded = user => {
    return createAction(USER_LOADED)({user});
};

export const unloadUser = id => {
    return createAction(USER_UNLOADED)({id});
}

export function loadUser(id) {
    return dispatch => {
        return dispatch(getUrl(`/users/${id}`))
            .then(user => dispatch(userLoaded(user)))
            .catch(error => dispatch(handleFetchError(error)));
    }
}

export function changeUserPassword(id, password) {
    return async dispatch => {
        await dispatch(putUrl(`/users/${id}/change-password`, {password}, null, NONE_RESPONSE))
        return true;
    }
}

export function deleteUser(id) {
    return dispatch => {
        return dispatch(deleteUrl(`/users/${id}`))
            .then(() => dispatch(unloadUser(id)));
    }
}

export function updateUser(id, values) {
    return dispatch => {
        return dispatch(putUrl(`/users/${id}`, values))
            .then(user => dispatch(userLoaded(user)));
    }
}

export function createUser(values) {
    return dispatch => {
        return dispatch(postUrl('/users', values))
            .then(user => {
                dispatch(userLoaded(user));
                return user;
            });
    }
}

export default handleActions({
    [GLOBAL_RESET_ACTION]: () => initState,
    [USER_LOADED]: (state, {payload: {user}}) => {
        return Object.assign({}, state, {[user.id]: user})
    },
    [USER_UNLOADED]: (state, {payload: {id}}) => {
        delete state[id];
        return Object.assign({}, state)
    }
}, initState);
