import React from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import * as PropsTypes from "prop-types";
import MaterialTable from "material-table";
import Paper from "@mui/material/Paper";


const AddUserObjectsModal = ({handleClose, handleAddObjects, objectsList}) => {
    return (
        <Dialog
            fullWidth={true}
            maxWidth={"lg"}
            open={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Přidělit objekty</DialogTitle>
            <DialogContent>
                <MaterialTable
                    actions={[{
                        icon: 'add',
                        position: "toolbarOnSelect",
                        tooltip: "Přidat",
                        onClick:  (_e, rows) => handleAddObjects(rows)
                    }]}
                    options={{
                        search: true,
                        showTitle: false,
                        selection: true,
                        cellStyle: {padding: '0.3em'},
                        headerStyle: {padding: '0.3em'},
                    }}
                    components={{
                        Container: props => <Paper {...props} elevation={0}/>
                    }}
                    columns={[
                        {title: 'Id', field: 'id'},
                        {title: 'Název', field: 'name'},
                        {title: 'Typ', field: 'type.name'},
                        {title: 'Společnost', field: 'company.name'},
                        {title: 'Město', field: 'city'}
                    ]}
                    data={objectsList}
                    title="Nepřidělené objekty"
                />
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained" color="secondary"
                    onClick={handleClose}>
                    Zavřít
                </Button>
            </DialogActions>
        </Dialog>
    )
}

AddUserObjectsModal.propTypes = {
    handleClose: PropsTypes.func.isRequired,
    handleAddObjects: PropsTypes.func.isRequired,
    objectsList: PropsTypes.array.isRequired
}

export default AddUserObjectsModal;
