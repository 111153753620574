import {createAction, handleActions} from 'redux-actions';
import {deleteUrl, getUrl, postUrl, putUrl} from "../../utils/api";
import {has} from "rambda";
import {handleApiError, handleFetchError} from "../messages";
import {GLOBAL_RESET_ACTION} from "../global";

const REGISTER_LOADED = 'REGISTER_LOADED';
const REGISTER_UNLOADED = 'REGISTER_UNLOADED';
const REGISTERS_LOADED = 'REGISTERS_LOADED';
const initState = {};

export const registerLoaded = register => {
    return createAction(REGISTER_LOADED)({register});
};

const registersLoaded = registers => {
    return createAction(REGISTERS_LOADED)({registers});
};

export function unloadRegister(id) {
  return createAction(REGISTER_UNLOADED)({id});
}

export function loadRegisters(ids) {
    return dispatch => {
        return dispatch(getUrl('/registers', {ids}))
            .then(registers => dispatch(registersLoaded(registers)))
            .catch(error => dispatch(handleFetchError(error)));
    }
}

export function loadRegister(id) {
    return async (dispatch) => {
        const register = await dispatch(getUrl(`/registers/${id}`));
        await dispatch(registerLoaded(register));
    }
}

export function updateRegister(id, values) {
    return dispatch => {
        return dispatch(putUrl(`/registers/${id}`, values))
            .then(register => dispatch(registerLoaded(register)))
            .catch(error => dispatch(handleApiError(error)));
    }
}

export function deleteRegisters(ids) {
    return dispatch => dispatch(deleteUrl(`/registers`, {ids}));
}

export function createRegister(values) {
    return dispatch => {
        return dispatch(postUrl('/registers', values))
            .then(register => {
                dispatch(registerLoaded(register));
                return register;
            })
            .catch(error => dispatch(handleApiError(error)));
    }
}

export function loadMissingRegisters(ids) {
    return (dispatch, getState) => {
        const registers = getState().registers.map;
        const missing = ids.reduce((missing, id) => {
            if (!has(id)(registers)) {
                missing.push(id);
            }
            return missing;
        }, []);

        if (missing.length > 0) {
            return dispatch(loadRegisters(missing));
        }

        return Promise.resolve([]);
    }
}

export default handleActions({
    [GLOBAL_RESET_ACTION]: () => initState,
    [REGISTER_LOADED]: (state, {payload: {register}}) => {
        return Object.assign({}, state, {[register.id]: register})
    },
    [REGISTER_UNLOADED]: (state, {payload: {id}}) => Object.assign({}, delete state[id]),
    [REGISTERS_LOADED]: (state, {payload}) => {
        const registers = payload.registers.reduce((map, register) => {
            map[register.id] = register;
            return map;
        }, {});
        return Object.assign({}, state, registers)
    },
}, initState);
