import React from "react";
import {Box} from "@mui/material";

const ObjectsMapPage = () => (
    <Box>
        <h2>Objekty - mapa</h2>
    </Box>
);

export default ObjectsMapPage;
