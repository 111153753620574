import {createAction, handleActions} from 'redux-actions';
import {deleteUrl, getUrl, postUrl, putUrl} from "../../utils/api";
import {handleFetchError} from "../messages";
import {GLOBAL_RESET_ACTION} from "../global";

const COMPANY_LOADED = 'COMPANY_LOADED';
const COMPANY_UNLOADED = 'COMPANY_UNLOADED';
const initState = {};

function companyLoaded(company) {
    return createAction(COMPANY_LOADED)({company});
}

export const loadCompany = (id) => {
    return dispatch => {
        return dispatch(getUrl(`/companies/${id}`))
            .then(company => dispatch(companyLoaded(company)))
            .catch(error => handleFetchError(error));
    }
};


export function deleteCompany(id) {
    return dispatch => {
        return dispatch(deleteUrl(`/companies/${id}`))
            .then(() => dispatch(unloadCompany(id)));
    }
}

function unloadCompany(id) {
    return createAction(COMPANY_UNLOADED)({id});
}

export function updateCompany(id, values) {
    return dispatch => {
        return dispatch(putUrl(`/companies/${id}`, values))
            .then(company => dispatch(companyLoaded(company)));
    }
}

export function createCompany(values) {
    return dispatch => {
        return dispatch(postUrl('/companies', values))
            .then(company => {
                dispatch(companyLoaded(company));
                return company;
            });
    }
}

export default handleActions({
    [GLOBAL_RESET_ACTION]: () => initState,
    [COMPANY_LOADED]: (state, {payload: {company}}) => Object.assign({}, state, {
        [company.id]: company
    }),
    [COMPANY_UNLOADED]: (state, {payload: {id}}) => Object.assign({}, delete state[id])
}, initState);
