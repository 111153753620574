import {createAction, handleActions} from "redux-actions";
import {GLOBAL_RESET_ACTION} from "../global";
import {getUrl} from "../../utils/api";

const REGISTER_VALUES_LOADED = "REGISTER_VALUES_LOADED";
const REGISTER_VALUES_UNLOAD = "REGISTER_VALUES_UNLOAD";

const initState = {

};

export const unloadRegisterValues = key => createAction(REGISTER_VALUES_UNLOAD)({key});
const valuesLoaded = (key, values) => createAction(REGISTER_VALUES_LOADED)({key, values});

export function loadRegistersValues(key, registerIds, from, to, vizualization, interval) {
  return async dispatch => {
      const values = await dispatch(getUrl('/register-values', {registerIds, from, to, vizualization, interval}));
      return dispatch(valuesLoaded(key, values));
  }
}

export default handleActions({
    [GLOBAL_RESET_ACTION]: () => initState,
    [REGISTER_VALUES_LOADED]: (state, {payload: {key, values}}) => Object.assign({}, state, {[key]: values}),
    [REGISTER_VALUES_UNLOAD]: (state, {payload: {key}}) => {
        const {[key]: values, ...newState} = state;
        return newState;
    }
}, initState);
