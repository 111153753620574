import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import MaterialTable from "material-table";
import Paper from "@mui/material/Paper";
import {loadCompaniesList} from "../../../reducers/companies/list";
import {useHistory} from "react-router-dom";
import {LOAD_STATE} from "../../../constants";
import CircularProgress from "@mui/material/CircularProgress";
import {handleFetchError} from "../../../reducers/messages";
import {defaultMarcoTheme} from "../../../theme";
import {ThemeProvider} from "@mui/material";

export default function CompaniesGrid() {
    const history = useHistory();
    const dispatch = useDispatch();
    const companiesList = useSelector(state => state.companies.list);

    const loadData = async () => {
        try {
            await dispatch(loadCompaniesList())
        } catch (error) {
            dispatch(handleFetchError(error));
        }
    }

    useEffect(() => {
        loadData();
    }, [dispatch]);

    const handleEdit = (_event, company) => {
        history.push(`/admin/companies/${company.id}`);
    };

    const handleCreate = () => {
        history.push("/admin/companies/create");
    }

    if (companiesList.loadState === LOAD_STATE.PENDING) {
        return (
            <CircularProgress/>
        )
    }

    return (
        <div>
            <ThemeProvider theme={defaultMarcoTheme}>
                <MaterialTable
                    options={{
                        pageSize:15,
                        pageSizeOptions: [15, 30, 100],
                        cellStyle: {padding: '0.3em'},
                        headerStyle: {padding: '0.3em'},
                        rowStyle: (_company, index) => {
                            return {
                                backgroundColor: index % 2 ? "white" : "aliceBlue",
                            }
                        }
                    }}
                    actions={[{
                        icon: 'edit',
                        position: "row",
                        tooltip: 'Editovat',
                        onClick: handleEdit
                    }, {
                        icon: 'add',
                        tooltip: 'Přidat',
                        position: "toolbar",
                        onClick: handleCreate
                    }]}

                    components={{
                        Container: props => <Paper {...props} elevation={0}/>
                    }}
                    columns={[
                        {title: 'Id', field: 'id'},
                        {title: 'Název', field: 'name'},
                        {title: 'Uživatelů', field: 'usersCount'},
                        {title: 'Objektů', field: 'objectsCount'}
                    ]}
                    data={companiesList.content}
                    title="Společnosti"
                />
            </ThemeProvider>
        </div>
    );
}
