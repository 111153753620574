import {createAction, handleActions} from "redux-actions";
import {deleteUrl, getUrl, postUrl} from "../../utils/api";
import {GLOBAL_RESET_ACTION} from "../global";

const USER_ROLES_LOADED = "USER_ROLES_LOADED";
const USER_ROLES_UNLOADED = "USER_ROLES_UNLOADED";

const userRolesLoaded =  (userId, roles) => createAction(USER_ROLES_LOADED) ({userId, roles});
export const unloadUserRoles = userId => createAction(USER_ROLES_UNLOADED)({userId});

export const loadUserRoles = userId => {
    return dispatch => dispatch(getUrl(`/users/${userId}/roles`))
        .then(roles => dispatch(userRolesLoaded(userId, roles)));
}

export const deleteUserRole = (userId, userRoleId) => {
    return dispatch => dispatch(deleteUrl(`/users/${userId}/roles/${userRoleId}`))
        .then(() => dispatch(loadUserRoles(userId)));
}

export function addUserRoles(userId, roles) {
    return async(dispatch) => {
        await dispatch(postUrl(`/users/${userId}/roles`, null, {roles}));
        await dispatch(loadUserRoles(userId));
    }
}

export default handleActions({
        [GLOBAL_RESET_ACTION]: () => {},
        [USER_ROLES_LOADED]: (state, {payload: {userId, roles}}) => Object.assign({}, state, {[userId]: roles}),
        [USER_ROLES_UNLOADED]: (state, {payload: {userId}}) => Object.assign({}, delete state[userId])
    }, {}
)