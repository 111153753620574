import React, {useMemo, useState} from 'react';
import PropTypes from "prop-types";
import RegistersGraph from "../registers/RegistersGraph";
import moment from "moment";
import HoursDateTimeFilter, {defaultHours} from "../../../components/HoursDateTimeFilter";
import DashboardContainer from "./DashboardContainer";
import MetricOptions from "../../../components/MetricOptions";
import {useDispatch} from "react-redux";
import {
    changeDashboardHours,
    changeDashboardInterval,
    changeDashboardVisualization
} from "../../../reducers/dashboards/entities";
import Grid from "@mui/material/Grid";


const RegistersGraphDashboard = ({dashboard}) => {
    const {name, id, registerIds, hours} = dashboard;
    const from = useMemo(() => moment().subtract(hours, 'hours').format(), [hours]);
    const to = useMemo (() =>moment().format(), [hours]);

    const dispatch = useDispatch();
    const onHoursSelected = hours => {
        dispatch(changeDashboardHours(id, hours));
    }

    const onIntervalSelected = interval => {
        dispatch(changeDashboardInterval(id, interval));
    }

    const onVisualizationSelected = visualization => {
        dispatch(changeDashboardVisualization(id, visualization));
    }

    return (
        <DashboardContainer
            title={name} id={id}
            registersMenuEnabled={true}>
                <Grid container>
                    <Grid item xs={8}>
                        <MetricOptions
                            selectedInterval={dashboard.interval}
                            onIntervalSelected={onIntervalSelected}
                            selectedVizualization={dashboard.vizualization}
                            onVizualizationSelected={onVisualizationSelected}/>
                    </Grid>
                    <Grid item xs={4}>
                        <HoursDateTimeFilter selectedHours={hours} onHoursSelected={onHoursSelected}/>
                    </Grid>
                </Grid>
                <RegistersGraph
                    vizualization={dashboard.vizualization}
                    interval={dashboard.interval}
                    width={950}
                    to={to}
                    from={from}
                    dataKey={`dashboard-${id}`}
                    label={name}
                    registerIds={registerIds}/>
        </DashboardContainer>
    )
}

RegistersGraphDashboard.propTypes = {
    dashboard: PropTypes.object.isRequired,
};

export default RegistersGraphDashboard;
