import React, {useEffect} from "react";
import {loadRegister, unloadRegister} from "../../../reducers/registers/map";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import OutOfRangeEventsGrid from "../../panels/registers/OutOfRangeEventsGrid";

const RegisterAlarmsPage = () => {
    const {id} = useParams();
    const history = useHistory();
    const register = useSelector(state => state.registers.map[id]);
    const dispatch = useDispatch();

    const loadData = async () => {
        await dispatch(loadRegister(id));
    }

    useEffect(() => {
        loadData();
        return () => dispatch(unloadRegister(id));
    }, [id, dispatch]);

    if (!id) {
        history.push('/registers');
    }

    if (!register) {
        return (<CircularProgress/>)
    }

    return (
        <Grid container direction={"column"}>
            <Typography variant="h4" component="h1" gutterBottom>
                Registr: {register.name}
            </Typography>
            <OutOfRangeEventsGrid registerId={id} displayMode={"singleRegister"}/>
        </Grid>
    )
}

export default RegisterAlarmsPage;