import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import RegistersGraphDashboard from "./RegistersGraphDashboard";
import Grid from "@mui/material/Grid";
import RegistersStatisticsDashboard from "./RegistersStatisticsDashboard";
import Button from "@mui/material/Button";
import {Icon} from "@mui/material";
import {changeDashboardPosition, DASHBOARD_TYPE, loadDashboards} from "../../../reducers/dashboards/entities";
import DashboardsSettingsModal from "../../../components/modals/DashboardsSettingsModal";
import {createSelector} from "reselect";
import * as R from "rambda";
import {values} from "rambda";
import UnreachableRegistersDashboard from "./UnreachableRegistersDashboard";
import OutOfRangeRegistersDashboard from "./OutOfRangeRegistersDashboard";
import SystemStatusDashboard from "./SystemStatusDashboard";
import DashboardContainer from "./DashboardContainer";
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {handleApiError} from "../../../reducers/messages";
import PanToolIcon from '@mui/icons-material/PanTool';
import SettingsIcon from '@mui/icons-material/Settings';

import ButtonGroup from "@mui/material/ButtonGroup";

const dashboardsSelector = createSelector(
    state => state.dashboards.entities,
    entities => values(entities)
);

function renderDashboard(dashboard) {
    const {id} = dashboard;
    switch (dashboard.type) {
        case DASHBOARD_TYPE.REGISTERS_GRAPH:
            return (<RegistersGraphDashboard key={id} dashboard={dashboard}/>);
        case DASHBOARD_TYPE.REGISTERS_STATS:
            return (<RegistersStatisticsDashboard key={id} dashboard={dashboard}/>);
        case DASHBOARD_TYPE.UNREACHABLE_REGISTERS:
            return (<UnreachableRegistersDashboard key={id} dashboard={dashboard}/>);
        case DASHBOARD_TYPE.OUT_OF_RANGE_REGISTERS:
            return (<OutOfRangeRegistersDashboard key={id} dashboard={dashboard}/>);
        case DASHBOARD_TYPE.SYSTEM_STATUS:
            return (<SystemStatusDashboard key={id} dashboard={dashboard}/>);
        default:
            return (
                <DashboardContainer key={id} title={dashboard.name} id={id}>
                    <p>Not implemented dashboard</p>
                </DashboardContainer>
            );
    }
}


const DashboardsColumn = ({droppableId, dashboards, isDragDisabled}) => (
    <Droppable droppableId={droppableId}>
        {(provided, _snapshot) => (
            <Grid
                direction={"column"}
                container {...provided.droppableProps}
                ref={provided.innerRef}>
                {dashboards ? R.sortBy(R.prop("rowIndex"), dashboards).map((dashboard) => (
                        <Draggable key={dashboard.id} draggableId={''+dashboard.id} index={dashboard.rowIndex} isDragDisabled={isDragDisabled}>
                            {(provided, _snapshot) => (
                                <div ref={provided.innerRef}
                                     {...provided.draggableProps}
                                     {...provided.dragHandleProps}
                                     style={provided.draggableProps.style}>
                                    {renderDashboard(dashboard)}
                                </div>
                            )}
                        </Draggable>
                    )
                ) : null}
                {provided.placeholder}
            </Grid>
        )}
    </Droppable>
)

const Dashboards = () => {
    const dashboards = useSelector(dashboardsSelector);
    const [showSettings, setShowSettings] = useState(false);
    const [isDragDisabled, setDragDisabled] = useState(true)
    const dispatch = useDispatch();

    const loadData = async()  => {
        await dispatch(loadDashboards());
    }

    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) {
            return;
        }

        const oldColumnIndex = Number.parseInt(source.droppableId.replace('column-', ''));
        const oldRowIndex = source.index;

        const newColumnIndex = Number.parseInt(destination.droppableId.replace('column-', ''));
        const newRowIndex = destination.index;

        updatePositions(oldColumnIndex, oldRowIndex, newColumnIndex, newRowIndex);
    };

    const updatePositions = async(oldColumnIndex, oldRowIndex, newColumnIndex, newRowIndex) => {
        try {
            await dispatch(changeDashboardPosition(oldColumnIndex, oldRowIndex, newColumnIndex, newRowIndex));
        } catch (e) {
            dispatch(handleApiError(e));
        }
    }

    useEffect(() => {
        loadData();
    }, [dispatch])

    if (!dashboards) {
        return null;
    }

    const column0Dashboards = dashboards.filter(dashboard => dashboard.columnIndex === 0);
    const column1Dashboards = dashboards.filter(dashboard => dashboard.columnIndex === 1);

    return (
        <Grid container>
            <Grid item md={12} style={{textAlign: 'right'}}>
                <ButtonGroup size="small">
                    <Button
                        onClick={_e => setShowSettings(true)}>
                        <SettingsIcon/>
                    </Button>
                    <Button
                        color={ isDragDisabled ? "info" : "warning" }
                        onClick={ _e => setDragDisabled(!isDragDisabled)}>
                        <PanToolIcon/>
                    </Button>
                </ButtonGroup>
            </Grid>
            <DragDropContext onDragEnd={onDragEnd}>
                <Grid container direction={"row"} justifyContent={"flex-start"}>
                    <Grid item md={6}>
                      <DashboardsColumn
                          isDragDisabled={isDragDisabled}
                          dashboards={column0Dashboards}
                          droppableId="column-0"/>
                    </Grid>
                    <Grid item md={6}>
                      <DashboardsColumn
                          isDragDisabled={isDragDisabled}
                          dashboards={column1Dashboards}
                          droppableId="column-1"/>
                    </Grid>
                </Grid>
            </DragDropContext>
            {showSettings && (<DashboardsSettingsModal handleClose={_e=> setShowSettings(false)}/>)}
        </Grid>
    );
}

export default Dashboards;
