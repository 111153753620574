import React from 'react';
import PropTypes from "prop-types";
import DashboardContainer from "./DashboardContainer";
import OutOfRangeEventsGrid from "../registers/OutOfRangeEventsGrid";

const OutOfRangeRegistersDashboard = ({dashboard: {id, name}}) => {
    return (
        <DashboardContainer title={name} id={id}>
            <OutOfRangeEventsGrid displayMode={"basic"} filterMode={"active"}/>
        </DashboardContainer>
    )
}

OutOfRangeRegistersDashboard.propTypes = {
    dashboard: PropTypes.object.isRequired
};

export default OutOfRangeRegistersDashboard;
