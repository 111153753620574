import React from 'react';
import UserEdit from "../../panels/users/UserEdit";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";


const UserCreatePage = () => {
    return (
        <Grid container direction={"column"}>
            <Typography variant="h4" component="h1" gutterBottom>
                Nový uživatel
            </Typography>
            <UserEdit/>
        </Grid>
    );
}

export default UserCreatePage;
