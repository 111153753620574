import {createAction, handleActions} from 'redux-actions';
import {deleteUrl, getUrl, postUrl, putUrl} from "../../utils/api";
import {GLOBAL_RESET_ACTION} from "../global";

const PLC_LOADED = 'PLC_LOADED';
const PLC_UNLOADED = 'PLC_UNLOADED';

export const loadPlc = (plcId) => {
    return async(dispatch) => {
        const plc = await dispatch(getUrl(`/plcs/${plcId}`));
        dispatch(plcLoaded(plcId, plc));
    }
}

export const createPlc = (objectId, vals) => async(dispatch) => {
    const plc = await dispatch(postUrl(`/plcs`, vals, {objectId}));
    dispatch(plcLoaded(plc.id, plc));
    return plc;
}

export const updatePlc = (id, vals) => async (dispatch) => {
    const plc = await dispatch(putUrl(`/plcs/${id}`, vals));
    dispatch(plcLoaded(plc.id, plc));
    return plc;
}

export const deletePlc = id => async(dispatch) => {
    await dispatch(deleteUrl(`/plcs/${id}`));
    dispatch(unloadPlc(id));
}

const plcLoaded = (id, plc) => createAction(PLC_LOADED)({id, plc});
export const unloadPlc = id => createAction(PLC_UNLOADED)({id});

export default handleActions({
    [GLOBAL_RESET_ACTION]: () => {},
    [PLC_LOADED]: (state, {payload: {id, plc}}) => Object.assign({}, state, {[id]: plc}),
    [PLC_UNLOADED]: (state, {payload: {id}}) => Object.assign({}, delete state[id])
},{})
