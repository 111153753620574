import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import ObjectEdit from "../../panels/objects/ObjectEdit";
import {useDispatch, useSelector} from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {deleteObject, loadObject} from "../../../reducers/objects/map";
import {handleApiError, MESSAGE_SEVERENITY, newMessage} from "../../../reducers/messages";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteConfirmModal from "../../../components/modals/DeleteConfirmModal";


const ObjectPage = () => {
    const {id} = useParams();
    const history = useHistory();
    const object = useSelector(state => state.objects.map[id]);
    const dispatch = useDispatch();
    const [deleteConfirm, setDeleteConfirm] = useState({open: false, items: []});
    const [deleteInProgress, setDeleteInProgress] = useState(false);

    const loadData = async () => {
        await dispatch(loadObject(id));
    }

    useEffect(() => {
        loadData();
    }, [id]);

    if (!id) {
        history.push('/objects');
    }

    if (!object || deleteInProgress) {
        return (<CircularProgress/>)
    }

    const handleDelete = async () => {
        try {
            setDeleteInProgress(true);
            await dispatch(deleteObject(id))
            dispatch(newMessage(MESSAGE_SEVERENITY.SUCCESS, "Objekt smazán"));
            history.push("/objects")
        } catch (e) {
            dispatch(handleApiError(e));
        } finally {
            setDeleteInProgress(false);
            setDeleteConfirm({open: false, items: []});
        }
    }

    return (
        <Grid container direction={"column"}>
            <Grid container direction={"row"} alignContent={"space-around"}>
                <Grid item xs={10}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Objekt: {object.name}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Button
                        onClick={() => setDeleteConfirm({open: true, items: [object.name]})}
                        variant="contained"
                        color="secondary"
                        startIcon={<DeleteIcon/>}>
                        Smazat
                    </Button>
                </Grid>
            </Grid>
            <ObjectEdit object={object}/>
            <DeleteConfirmModal
                open={deleteConfirm.open}
                items={deleteConfirm.items}
                handleConfirm={() => handleDelete()}
                handleClose={() => setDeleteConfirm({open: false, items:[]})}/>
        </Grid>
    );
}

export default ObjectPage;
