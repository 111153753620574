import React from 'react';
import {Form} from "react-final-form";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import PropTypes from "prop-types";
import {Checkboxes, TextField} from "mui-rff";
import {required} from "../../../utils/validators";

const CompanyForm = ({onSubmit, initialValues}) => {
    return (
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <Grid container direction={"row"} alignItems={"flex-start"}>
                        <Grid container direction={"column"} alignItems={"flex-start"} spacing={1} xs={3}>
                            <TextField
                                required
                                validate={required}
                                label="Název"
                                name="name"
                                margin="dense"
                                type="text"/>
                            <Checkboxes
                                name="maintenance"
                                data={[{label: "Správa objektů jiných společností", value: "maintenance"}]}
                                label="Správa objektů jiných společností"/>
                        </Grid>
                    </Grid>
                    <Button
                        type={"submit"}
                        startIcon={<SaveIcon/>}
                        variant="contained"
                        color="primary">
                        Uložit
                    </Button>
                </form>
            )}/>
    );
};

CompanyForm.propTypes = {
    initialValues: PropTypes.shape({
        name: PropTypes.string,
        maintenance: PropTypes.bool
    }),
    onSubmit: PropTypes.func.isRequired
}

export default CompanyForm;
