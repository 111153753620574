import React from "react";
import Dialog from "@mui/material/Dialog";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";

const ProgressDialog = ({open}) => {
    return (
        <Dialog open={open} maxWidth={"sm"} fullWidth>
            <LinearProgress style={{margin: 40}}/>
        </Dialog>
    );
}

ProgressDialog.propTypes = {
    open: PropTypes.bool.isRequired
}

export default ProgressDialog;