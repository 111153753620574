import React, {useEffect} from "react";
import {handleApiError} from "../../../reducers/messages";
import MaterialTable from "material-table";
import Paper from "@mui/material/Paper";
import {formatDate} from "../../../utils/formatters";
import {loadNotificationsPage, unloadNotificationsPage} from "../../../reducers/notifications/page";
import {useDispatch} from "react-redux";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {defaultMarcoTheme} from "../../../theme";
import {ThemeProvider} from "@mui/material";

const NotificationsGrid = ({displayMode, registerId, userId}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => dispatch(unloadNotificationsPage())
    }, [dispatch])

    const getData = async ({pageSize, page, filters}) => {
        try {
            const apiFilters = {registerId, userId, ...filters};
            const fetchedPage = await dispatch(loadNotificationsPage(page, pageSize, apiFilters));
            return ({
                data: fetchedPage.content,
                page,
                totalCount: fetchedPage.totalElements
            });
        } catch (e) {
            dispatch(handleApiError(e));
        }

        return ({
            data: [],
            page,
            totalCount: 0
        });
    };

    return (
        <ThemeProvider theme={defaultMarcoTheme}>
        <MaterialTable
                options={{
                    sorting: false,
                    search: false,
                    cellStyle: {padding: '0.3em'},
                    headerStyle: {padding: '0.3em'},
                    pageSizeOptions: [15, 30, 100],
                    rowStyle: (_access, index) => {
                        return {
                            backgroundColor: index % 2 ? "white" : "aliceBlue",
                        }
                    }
                }}
                components={{
                    Container: props => <Paper {...props} elevation={0}/>
                }}
                columns={[
                    {
                        title: 'Uživatel',
                        field: 'notifiedUsername',
                        render: ({userId, notifiedUsername}) => (
                            <Link to={`/users/${userId}`}>{notifiedUsername}</Link>
                        )
                    },{
                        title: 'Společnost',
                        field: 'userCompanyName',
                        hidden: displayMode === 'singleRegister'
                    }, {
                        title: 'Objekt',
                        field: 'objectName',
                        hidden: displayMode === 'singleRegister',
                        render: ({objectId, objectName}) => (
                            <Link to={`/objects/${objectId}`}>{objectName}</Link>
                        )
                    }, {
                        title: 'Registr',
                        field: 'registerName',
                        render: ({registerId, registerName}) => (
                            <Link to={`/registers/${registerId}`}>{registerName}</Link>
                        )
                    },{
                        title: 'Událost',
                        field: 'eventType',
                    }, {
                        title: 'Datum',
                        field: 'dateTime',
                        render: ({dateTime}) => formatDate(dateTime)
                    }
                ]}
                data={getData}
                title="Notifikace"
            />
        </ThemeProvider>
    );
}


NotificationsGrid.propTypes = {
    filterMode: PropTypes.oneOf(["all"]),
    displayMode: PropTypes.oneOf(["detailed", "singleRegister"]),
    registerId: PropTypes.number,
    userId: PropTypes.number
}

NotificationsGrid.defaultProps = {
    displayMode: "detailed",
    filterMode: "all",
}

export default NotificationsGrid;
