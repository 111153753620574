import {createAction, handleActions} from 'redux-actions';
import {getUrl} from "../../utils/api";
import {handleFetchError} from "../messages";
import {LOAD_STATE} from "../../constants";
import {GLOBAL_RESET_ACTION} from "../global";

const REGIONS_LIST_LOADED = 'REGIONS_LIST_LOADED';
const REGIONS_LIST_LOAD_ERROR = 'REGIONS_LIST_LOAD_ERROR';

const initState = {
    loadState: LOAD_STATE.PENDING,
    content: []
};

export const regionsListLoaded = regions => {
    return createAction(REGIONS_LIST_LOADED)({regions});
}

const loadError = error => {
    return dispatch => {
        dispatch(handleFetchError(error));
        dispatch(createAction(REGIONS_LIST_LOAD_ERROR)({error}));
    }
}


export function loadRegionsList() {
    return dispatch => {
        return dispatch(getUrl('/regions'))
            .then(regions => dispatch(regionsListLoaded(regions)))
            .catch(error => dispatch(loadError(error)));
    }
}


export default handleActions({
    [GLOBAL_RESET_ACTION]: () => initState,
    [REGIONS_LIST_LOADED]: (state, {payload: {regions}}) => Object.assign({}, state, {
        content: regions, loadState: LOAD_STATE.LOADED
    }),
    [REGIONS_LIST_LOAD_ERROR]: state => Object.assign({}, initState, {loadState: LOAD_STATE.ERROR})
}, initState);
