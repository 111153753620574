import React from "react";
import LoginForm from "../../components/forms/LoginForm";
import {useDispatch, useSelector} from "react-redux";
import {authenticate} from "../../reducers/auth";
import {Grid} from "@mui/material";
import {Redirect, useHistory} from "react-router-dom";
import {hasAnyAuthSelector} from "../../selectors/authSelectors";
import {handleApiError} from "../../reducers/messages";

const LoginPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const hasAuth = useSelector(hasAnyAuthSelector);

    const handleSubmit = ({username, password}) =>  {
        try {
            dispatch(authenticate(username, password))
                .then(() => history.push("/"));
        } catch (e) {
            dispatch(handleApiError(e));
        }
    };

    if (hasAuth) {
        console.debug("User is already authenticated");
        return (<Redirect to={"/"}/>);
    }

    return (
        <Grid container alignContent={"center"} justifyContent={"center"}>
            <LoginForm onSubmit={handleSubmit}/>
        </Grid>
    );
};

export default LoginPage;
