import { createTheme } from '@mui/material/styles';
import {blue, orange, purple, red} from "@mui/material/colors";
import green from "@mui/material/colors/green";

export const defaultMarcoTheme = createTheme({
    spacing: 4,
    palette: {
        primary: {
            main: '#920202',
        },
        secondary: {
            main: blue[800],
        },
    },
    components: {
        MuiFormControl: {
          styleOverrides: {
              root: {
                  margin: '3px',
                  padding: '3px'
              }
          }
        }
    }
});
