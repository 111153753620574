import React from 'react';
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {handleApiError, MESSAGE_SEVERENITY, newMessage} from "../../../reducers/messages";
import {createPlc, updatePlc} from "../../../reducers/plcs/map";
import PlcForm from "../../../components/forms/PlcForm";

const PlcEdit = ({objectId, plc}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleOnSubmit = async (values) => {
        if (plc) {
            try {
                await dispatch(updatePlc(plc.id, values));
                dispatch(newMessage(MESSAGE_SEVERENITY.SUCCESS, "PLC uloženo"));
            } catch (error) {
                dispatch(handleApiError(error));
            }
        } else {
            try {
                const plc = await dispatch(createPlc(objectId, values));
                dispatch(newMessage(MESSAGE_SEVERENITY.SUCCESS, "Nové PLC vytvořeno"));
                history.push(`/objects/${objectId}/plcs/${plc.id}`);
            } catch (error) {
                dispatch(handleApiError(error));
            }
        }
    }

    const initialValues = !plc ? {} : {
        ipAddress: plc.ipAddress,
        port: plc.port
    };

    return (
        <PlcForm
            initialValues={initialValues}
            onSubmit={handleOnSubmit}/>
    )
}

PlcEdit.propsTypes = {
    objectId: PropTypes.number.isRequired,
    plc: PropTypes.object
}

export default PlcEdit;
