import {createAction, handleActions} from 'redux-actions';
import {getUrl} from "../../utils/api";
import {handleApiError} from "../messages";

const SYSTEM_STATUS_LOADED = 'SYSTEM_STATUS_LOADED';
const SYSTEM_STATUS_UNLOADED = 'SYSTEM_STATUS_UNLOADED'

function systemStatusLoaded(systemStatus) {
    return createAction(SYSTEM_STATUS_LOADED)({systemStatus});
}

export function unloadSystemStatus() {
    return createAction(SYSTEM_STATUS_UNLOADED)();
}

export function loadSystemStatus() {
    return async dispatch => {
        try {
            const systemStatusApi = await dispatch(getUrl('/system-status'));
            dispatch(systemStatusLoaded(systemStatusApi));
            return systemStatusApi;
        } catch (e) {
            handleApiError(e);
            return null;
        }
    }
}

export default handleActions({
        [SYSTEM_STATUS_LOADED]: (status, {payload: {systemStatus}}) => systemStatus,
        [SYSTEM_STATUS_UNLOADED]: status => null
    }, null);