import React, {useEffect, useState} from "react";
import RegistersGrid from "../../panels/registers/RegistersGrid";
import {loadObject} from "../../../reducers/objects/map";
import CircularProgress from "@mui/material/CircularProgress";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import {handleApiError} from "../../../reducers/messages";

const ObjectRegistersPage = () => {
    const {id} = useParams();
    const object = useSelector(state => state.objects.map[id]);
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);

    const loadData = async () => {
        if (object) {
            return;
        }

        try {
            setLoading(true);
            await dispatch(loadObject(id));
        } catch (e) {
            dispatch(handleApiError(e));
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadData();
    }, [loadData]);

    if (isLoading) {
        return (<CircularProgress/>)
    }

    if (!object) {
        return null;
    }

    return (
        <div>
            <Typography variant="h4" component="h1" gutterBottom>
                Objekt: {object.name} - registry
            </Typography>
            <RegistersGrid displayMode={"object"} filterMode={"object"} objectId={id}/>
        </div>
    );
};

export default ObjectRegistersPage;