import React from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import * as PropsTypes from "prop-types";
import MaterialTable from "material-table";
import Paper from "@mui/material/Paper";
import localized from "../../utils/localized";


const AddUserRoleModal = ({handleClose, handleAddRoles, roles}) => {
    const data = roles.map(role => {
        return {name: role}
    });
    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Přidělit roli</DialogTitle>
            <DialogContent>
                <MaterialTable
                    actions={[{
                        icon: 'add',
                        position: "toolbarOnSelect",
                        tooltip: "Přidat",
                        onClick:  (_e, rows) => handleAddRoles(rows.map(row => row.name))
                    }]}
                    options={{
                        search: false,
                        showTitle: false,
                        selection: true,
                        cellStyle: {padding: '0.3em'},
                        rowStyle: (_userRole, index) => {
                            return {
                                backgroundColor: index % 2 ? "white" : "aliceBlue"
                            }
                        },
                        headerStyle: {padding: '0.3em'},
                    }}
                    components={{
                        Container: props => <Paper {...props} elevation={0}/>
                    }}
                    columns={[{
                        title: 'Role', sorting: false, render: role =>localized.getString(role.name)
                    }, {
                        title: 'Popis', sorting: false, render: role =>localized.getString(`${role.name}_description`)
                    }]}
                    data={data}
                    title="Nepřidělené role"
                />
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained" color="secondary"
                    onClick={handleClose}>
                    Zavřít
                </Button>
            </DialogActions>
        </Dialog>
    )
}

AddUserRoleModal.propTypes = {
    handleClose: PropsTypes.func.isRequired,
    handleAddRoles: PropsTypes.func.isRequired,
    roles: PropsTypes.array.isRequired
}

export default AddUserRoleModal;
