import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from "react-redux";
import {handleApiError, MESSAGE_SEVERENITY, newMessage} from "../../../reducers/messages";
import {updateProfile} from "../../../reducers/settings/profile";
import UserProfileForm from "../../../components/forms/settings/UserProfileForm";

const UserProfileEdit = ({profile}) => {
    const dispatch = useDispatch();
    const handleOnSubmit = async (values) => {
        try {
            await dispatch(updateProfile(values));
            dispatch(newMessage(MESSAGE_SEVERENITY.SUCCESS, "Profil uložen"));
        } catch (error) {
            dispatch(handleApiError(error));
        }
    }

    const initialValues = !profile ? {} : {
        firstName: profile.firstName,
        surname: profile.surname,
        email: profile.email,
        phone: profile.phone,
        sendNotifs: profile.sendNotifs,
        sendDayReport: profile.sendDayReport,
        sendWeekReport: profile.sendWeekReport,
        sendMonthReport: profile.sendMonthReport
    };

    return (
        <UserProfileForm
            initialValues={initialValues}
            onSubmit={handleOnSubmit}/>
    );
}

UserProfileEdit.propsTypes = {
    profile: PropTypes.object.isRequired
}

export default UserProfileEdit;
