import React, {useEffect, useState} from "react";
import {handleApiError} from "../../../reducers/messages";
import MaterialTable from "material-table";
import Paper from "@mui/material/Paper";
import {formatDate} from "../../../utils/formatters";
import {useDispatch} from "react-redux";
import {
    loadReportsPage,
    sendDayReports,
    sendMonthReports,
    sendWeekReports,
    unloadReportsPage
} from "../../../reducers/reports/page";
import {ThemeProvider} from "@mui/material";
import {defaultMarcoTheme} from "../../../theme";

const tableRef = React.createRef();
const ReportsGrid = () => {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        return () => dispatch(unloadReportsPage())
    }, [dispatch])

    const refreshTable = () => tableRef.current && tableRef.current.onQueryChange();

    const onSendDayReports = async(_e) => {
        try {
            setLoading(true);
            await dispatch(sendDayReports());
        } catch (e) {
            dispatch(handleApiError(e));
        } finally {
            setLoading(false);
            refreshTable();
        }
    }

    const onSendWeekReports = async(_e) => {
        try {
            setLoading(true);
            await dispatch(sendWeekReports());
        } catch (e) {
            dispatch(handleApiError(e));
        } finally {
            setLoading(false);
            refreshTable();
        }
    }

    const onSendMonthReports = async(_e) => {
        try {
            setLoading(true);
            await dispatch(sendMonthReports());
        } catch (e) {
            dispatch(handleApiError(e));
        } finally {
            setLoading(false);
            refreshTable();
        }
    }

    const getData = async ({pageSize, page}) => {
        try {
            setLoading(true);
            const fetchedPage = await dispatch(loadReportsPage(page, pageSize));
            return ({
                data: fetchedPage.content,
                page,
                totalCount: fetchedPage.totalElements
            });
        } catch (e) {
            dispatch(handleApiError(e));
        } finally {
            setLoading(false);
        }

        return ({
            data: [],
            page,
            totalCount: 0
        });
    };

    return (
        <ThemeProvider theme={defaultMarcoTheme}>
            <MaterialTable
                isLoading={isLoading}
                tableRef={tableRef}
                options={{
                    sorting: false,
                    search: false,
                    cellStyle: {padding: '0.3em'},
                    headerStyle: {padding: '0.3em'},
                    pageSize: 15,
                    pageSizeOptions: [15, 30, 100],
                    rowStyle: (_access, index) => {
                        return {
                            backgroundColor: index % 2 ? "white" : "aliceBlue",
                        }
                    }
                }}
                actions={[
                    {
                        icon: 'view_day',
                        tooltip: 'Rozeslat denní report',
                        isFreeAction: true,
                        onClick: onSendDayReports
                    }, {
                        icon: 'view_week',
                        tooltip: 'Rozeslat týdenní report',
                        isFreeAction: true,
                        onClick: onSendWeekReports
                    }, {
                        icon: 'calendar_view_month',
                        tooltip: 'Rozeslat měsíční report',
                        isFreeAction: true,
                        onClick: onSendMonthReports
                    }, {
                        icon: 'refresh',
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: () => tableRef.current && tableRef.current.onQueryChange()
                    }]
                }
                components={{
                    Container: props => <Paper {...props} elevation={0}/>
                }}
                columns={[
                    {
                        title: 'Uživatel',
                        field: 'userName'
                    },{
                        title: 'Společnost',
                        field: 'userCompanyName',
                    }, {
                        title: 'Perioda',
                        field: 'period'
                    }, {
                        title: 'Datum',
                        field: 'dateTime',
                        render: ({dateTime}) => formatDate(dateTime)
                    }
                ]}
                data={getData}
                title="Report"
            />
        </ThemeProvider>
    );
};

export default ReportsGrid;
