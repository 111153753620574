import React from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import * as PropsTypes from "prop-types";
import {Checkbox, Grid, ImageList, ImageListItem} from "@mui/material";
import {groupBy, values} from "rambda";
import {createDashboard, DASHBOARD_TYPE, deleteDashboard} from "../../reducers/dashboards/entities";
import {createSelector} from "reselect";
import {useDispatch, useSelector} from "react-redux";
import HasRole from "../HasRole";
import {ROLE} from "../../constants";

const DashboardOption = ({title, description, enabled, onEnableChange}) => {
    return (
        <Grid>
            <Grid item>
                <Checkbox
                    checked={enabled}
                    onChange={(_event, checked)=>{onEnableChange(checked)}}
                />{title}
            </Grid>
            <Grid item>
                <p>{description}</p>
            </Grid>
        </Grid>
    )
}

const dashboardsSelector = createSelector(
    state => state.dashboards.entities,
    entities => values(entities)
);

const DashboardsSettingsModal = ({handleClose}) => {
    const dashboards = useSelector(dashboardsSelector);
    const types = groupBy(dashboard => dashboard.type)(dashboards);
    const dispatch = useDispatch();

    const onEnabledChanged = (enable, type, changedBoards) => {
        if (enable) {
            dispatch(createDashboard(type));
        } else {
            const dashboard = changedBoards[0];
            dispatch(deleteDashboard(dashboard.id));
        }
    };

    return (
        <Dialog
            maxWidth={"lg"}
            open={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Nastavení</DialogTitle>
            <DialogContent>
                <ImageList>
                    <ImageListItem key={DASHBOARD_TYPE.OUT_OF_RANGE_REGISTERS}>
                        <DashboardOption
                            title={"Registry mimo rozsah"}
                            description={"Přehled registrů, které jsou momentálně mimo očekávané hodnoty"}
                            enabled={types[DASHBOARD_TYPE.OUT_OF_RANGE_REGISTERS]}
                            onEnableChange={enabled => onEnabledChanged(enabled, DASHBOARD_TYPE.OUT_OF_RANGE_REGISTERS, types[DASHBOARD_TYPE.OUT_OF_RANGE_REGISTERS])}/>
                    </ImageListItem>
                    <ImageListItem key={DASHBOARD_TYPE.UNREACHABLE_REGISTERS}>
                        <DashboardOption
                            title={"Nedostupné registry"}
                            description={"Přehled registrů, které nejsou dostupné"}
                            enabled={types[DASHBOARD_TYPE.UNREACHABLE_REGISTERS]}
                            onEnableChange={enabled => onEnabledChanged(enabled, DASHBOARD_TYPE.UNREACHABLE_REGISTERS, types[DASHBOARD_TYPE.UNREACHABLE_REGISTERS])}/>/>
                    </ImageListItem>

                    <HasRole role={ROLE.ADMIN}>
                        <ImageListItem key={DASHBOARD_TYPE.SYSTEM_STATUS}>
                            <DashboardOption
                                title={"Stav systému"}
                                description={"Přehled stavu systému"}
                                enabled={types[DASHBOARD_TYPE.SYSTEM_STATUS]}
                                onEnableChange={enabled => onEnabledChanged(enabled, DASHBOARD_TYPE.SYSTEM_STATUS, types[DASHBOARD_TYPE.SYSTEM_STATUS])}/>/>
                        </ImageListItem>
                    </HasRole>

                    <ImageListItem>
                        <Grid container>
                            Dashboardy registrů:
                            <ol>
                                {dashboards ? dashboards.map(dashboard => (<li key={dashboard.id}>{dashboard.id}:{dashboard.type}</li>)) : null}
                            </ol>
                        </Grid>
                    </ImageListItem>
                    ))
                </ImageList>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained" color="primary"
                    onClick={handleClose}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
}

DashboardsSettingsModal.propTypes = {
    handleClose: PropsTypes.func.isRequired,
    dashboards: PropsTypes.object.isRequired
}

export default DashboardsSettingsModal;
