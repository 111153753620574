import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import store, {history, persistor} from "./store";
import {Provider} from "react-redux";
import {ConnectedRouter} from "connected-react-router";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import DefaultLayout from "./containers/layouts/Default";
import RegistersPage from "./containers/pages/registers/RegistersPage";
import LoginPage from "./containers/pages/LoginPage";
import PrivateRoute from "./components/PrivateRoute";
import {PersistGate} from 'redux-persist/integration/react'
import HomePage from "./containers/pages/HomePage";
import ObjectsPage from "./containers/pages/objects/ObjectsPage";
import UsersPage from "./containers/pages/users/UsersPage";
import SideMenuLayout from "./components/SideMenuLayout";
import RegisterTypesPage from "./containers/pages/registers/RegisterTypesPage";
import ObjectsMapPage from "./containers/pages/objects/ObjectsMapPage";
import RegisterTypeCreatePage from "./containers/pages/registers/RegisterTypeCreatePage";
import RegisterTypePage from "./containers/pages/registers/RegisterTypePage";
import RegisterEditPage from "./containers/pages/registers/RegisterEditPage";
import RegisterCreatePage from "./containers/pages/registers/RegisterCreatePage";
import CompanyPage from "./containers/pages/admin/CompanyPage";
import CompanyCreatePage from "./containers/pages/admin/CompanyCreatePage";
import ObjectCreatePage from "./containers/pages/objects/ObjectCreatePage";
import UserPage from "./containers/pages/users/UserPage";
import UserCreatePage from "./containers/pages/users/UserCreatePage";
import UsersAccessPage from "./containers/pages/users/UsersAccessPage";
import ObjectPage from "./containers/pages/objects/ObjectPage";
import UserObjectsPage from "./containers/pages/users/UserObjectsPage";
import UserRolesPage from "./containers/pages/users/UserRolesPage";
import ObjectPlcsPage from "./containers/pages/objects/ObjectPlcsPage";
import ObjectPlcPage from "./containers/pages/objects/ObjectPlcPage";
import ObjectPlcCreatePage from "./containers/pages/objects/ObjectPlcCreatePage";
import PasswordChangePage from "./containers/pages/settings/PasswordChangePage";
import ProfilePage from "./containers/pages/settings/ProfilePage";
import UserPasswordChangePage from "./containers/pages/users/UserChangePasswordPage";
import {ROLE} from "./constants";
import ExportImportPage from "./containers/pages/admin/ExportImportPage";
import RegistersOutOfRangePage from "./containers/pages/registers/RegistersOutOfRangePage";
import NotificationsPage from "./containers/pages/admin/NotificationsPage";
import ReportsPage from "./containers/pages/admin/ReportsPage";
import ObjectRegistersPage from "./containers/pages/objects/ObjectRegistersPage";
import RegisterNotificationsPage from "./containers/pages/registers/RegisterNotificationsPage";
import RegisterAlarmsPage from "./containers/pages/registers/RegisterAlarmsPage";
import RegisterPage from "./containers/pages/registers/RegisterPage";
import UserNotificationsPage from "./containers/pages/users/UserNotificationsPage";
import CompaniesPage from "./containers/pages/admin/CompaniesPage";


const roleAuth = role => `ROLE_${role}`;

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
              <BrowserRouter>
              <ConnectedRouter history={history}>
                  <DefaultLayout>
                      <Switch>
                          <Route path="/login" component={LoginPage}/>
                          <PrivateRoute path="/registers/:id(\d+)">
                              <SideMenuLayout createLinks={match => [
                                  {link: `${match.url}`, label: "Registr"},
                                  {link: `${match.url}/edit`, label: "Editace", role: ROLE.OBJECTS_EDIT},
                                  {link: `${match.url}/alarms`, label: "Alarmy"},
                                  {link: `${match.url}/notifications`, label: "Notifikace"},
                              ]}>
                                  <PrivateRoute
                                      path="/registers/:id/notifications"
                                      component={RegisterNotificationsPage}
                                      exact
                                  />
                                  <PrivateRoute
                                      path="/registers/:id(\d+)/alarms"
                                      component={RegisterAlarmsPage}
                                      exact
                                  />
                                  <PrivateRoute
                                      path="/registers/:id(\d+)/edit"
                                      component={RegisterEditPage}
                                      exact
                                  />
                                  <PrivateRoute
                                      path="/registers/:id(\d+)"
                                      component={RegisterPage}
                                      exact
                                  />
                              </SideMenuLayout>
                          </PrivateRoute>
                          <PrivateRoute path="/registers">
                              <SideMenuLayout links={[
                                  {link: "/registers/", label: "Registry"},
                                  {link: "/registers/out-of-range", label: "Poruchy"},
                                  {link: "/registers/types", label: "Typy registrů", role: ROLE.OBJECTS_EDIT}
                                  ]}>
                                   <Switch>
                                       <PrivateRoute
                                           path="/registers/out-of-range"
                                           component={RegistersOutOfRangePage}
                                           exact/>
                                       <PrivateRoute
                                           path="/registers/types"
                                           component={RegisterTypesPage}
                                           auth={roleAuth(ROLE.OBJECTS_EDIT)}
                                           exact/>
                                       <PrivateRoute
                                           path="/registers/types/create"
                                           component={RegisterTypeCreatePage}
                                           auth={roleAuth(ROLE.OBJECTS_EDIT)}
                                           exact/>
                                       <PrivateRoute
                                           path="/registers/types/:id"
                                           component={RegisterTypePage}
                                           auth={roleAuth(ROLE.OBJECTS_EDIT)}
                                           exact/>
                                       <PrivateRoute
                                           path="/registers/create"
                                           component={RegisterCreatePage}
                                           auth={roleAuth(ROLE.OBJECTS_EDIT)}
                                           exact/>
                                       <PrivateRoute
                                           path="/registers/"
                                           component={RegistersPage}
                                           exact/>
                                   </Switch>
                              </SideMenuLayout>
                          </PrivateRoute>

                          <PrivateRoute path={"/objects/:id(\\d+)"}>
                              <SideMenuLayout createLinks={match => [
                                  {link: `${match.url}`, label: "Registry"},
                                  {link: `${match.url}/edit`, label: "Detail", auth: roleAuth(ROLE.OBJECTS_EDIT)},
                                  {link: `${match.url}/plcs`, label: "Plc", auth: roleAuth(ROLE.OBJECTS_EDIT)}
                              ]}>
                                  <Switch>
                                      <PrivateRoute
                                          path="/objects/:objectId/plcs/create"
                                          component={ObjectPlcCreatePage}
                                          role={roleAuth(ROLE.OBJECTS_EDIT)}
                                          exact
                                      />
                                      <PrivateRoute
                                          path="/objects/:objectId/plcs/:id"
                                          component={ObjectPlcPage}
                                          role={roleAuth(ROLE.OBJECTS_EDIT)}
                                          exact
                                      />
                                      <PrivateRoute
                                          path="/objects/:objectId/plcs"
                                          component={ObjectPlcsPage}
                                          role={roleAuth(ROLE.OBJECTS_EDIT)}
                                          exact
                                      />
                                      <PrivateRoute
                                          path="/objects/:id/edit"
                                          role={roleAuth(ROLE.OBJECTS_EDIT)}
                                          component={ObjectPage}
                                          exact
                                      />
                                      <PrivateRoute
                                          path="/objects/:id"
                                          component={ObjectRegistersPage}
                                          exact
                                      />
                                  </Switch>
                              </SideMenuLayout>
                          </PrivateRoute>

                          <PrivateRoute path="/objects" role={roleAuth(ROLE.OBJECTS_EDIT)}>
                              <SideMenuLayout links={[
                                  {link: "/objects/", label: "Objekty"},
                                  {link: "/objects/map", label: "Objekty - mapa"}
                              ]}>
                               <Switch>
                                   <PrivateRoute exact path="/objects/map" component={ObjectsMapPage}/>
                                   <PrivateRoute exact path="/objects/create" component={ObjectCreatePage}/>
                                   <PrivateRoute exact path="/objects/" component={ObjectsPage}/>
                               </Switch>
                              </SideMenuLayout>
                          </PrivateRoute>

                          <PrivateRoute path="/admin" role={roleAuth(ROLE.ADMIN)}>
                              <SideMenuLayout links={[
                                  {link: "/admin/companies/", label: "Společnosti"},
                                  {link: "/admin/notifications/", label: "Notifikace"},
                                  {link: "/admin/reports/", label: "Reporty"},
                                  {link: "/admin/export-import/", label: "Export/Import"}
                              ]}>
                                  <Switch>
                                      <PrivateRoute exact path="/admin/export-import" component={ExportImportPage}/>
                                      <PrivateRoute exact path="/admin/companies" component={CompaniesPage}/>
                                      <PrivateRoute exact path="/admin/companies/create" component={CompanyCreatePage}/>
                                      <PrivateRoute exact path="/admin/companies/:id" component={CompanyPage}/>
                                      <PrivateRoute exact path="/admin/notifications" component={NotificationsPage}/>
                                      <PrivateRoute exact path="/admin/reports" component={ReportsPage}/>
                                  </Switch>
                              </SideMenuLayout>
                          </PrivateRoute>

                          <PrivateRoute path={"/users/:id(\\d+)"} role={roleAuth(ROLE.USERS_EDIT)}>
                              <SideMenuLayout createLinks={match => [
                                  {link: `${match.url}`, label: "Detail"},
                                  {link: `${match.url}/objects`, label: "Objekty"},
                                  {link: `${match.url}/roles`, label: "Role"},
                                  {link: `${match.url}/password-change`, label: "Změna hesla"},
                                  {link: `${match.url}/notifications`, label: "Notifikace"}
                              ]}>
                                  <Switch>
                                      <PrivateRoute path="/users/:id/objects" component={UserObjectsPage}/>
                                      <PrivateRoute path="/users/:id/roles" component={UserRolesPage}/>
                                      <PrivateRoute path="/users/:id/notifications" component={UserNotificationsPage}/>
                                      <PrivateRoute path="/users/:id/password-change" component={UserPasswordChangePage}/>
                                      <PrivateRoute path="/users/:id" component={UserPage}/>
                                  </Switch>
                              </SideMenuLayout>
                          </PrivateRoute>

                          <PrivateRoute path="/users" role={roleAuth(ROLE.USERS_EDIT)}>
                              <SideMenuLayout links={[
                                  {link: "/users/", label: "Uživatelé"},
                                  {link: "/users/access", label: "Příhlášení"}
                              ]}>
                                  <Switch>
                                      <PrivateRoute path="/users/access" component={UsersAccessPage}/>
                                      <PrivateRoute path="/users/create" component={UserCreatePage}/>
                                      <PrivateRoute path="/users/" component={UsersPage} exact/>
                                  </Switch>
                              </SideMenuLayout>
                          </PrivateRoute>
                          <PrivateRoute path={"/settings"}>
                              <SideMenuLayout links={[
                                  {link: "/settings", label: "Profil"},
                                  {link: "/settings/password-change", label: "Změna hesla"}
                              ]}>
                                  <Switch>
                                      <PrivateRoute path="/settings/password-change" component={PasswordChangePage}/>
                                      <PrivateRoute path="/settings" component={ProfilePage}/>
                                  </Switch>
                              </SideMenuLayout>
                          </PrivateRoute>
                          <PrivateRoute exact component={HomePage}/>
                      </Switch>
                  </DefaultLayout>
              </ConnectedRouter>
              </BrowserRouter>
          </PersistGate>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
