import React from 'react';
import {Form} from "react-final-form";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import SaveIcon from '@mui/icons-material/Save';
import {OBJECT_STATE} from "../../../reducers/objects/constants";
import {required} from "../../../utils/validators";
import {Select, TextField} from "mui-rff";

const ObjectForm = ({onSubmit, companyList, regionList, initialValues}) => {
    return (

        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <Grid container direction={"row"} alignItems={"flex-start"}>
                        <Grid container direction={"column"} alignItems={"flex-start"} spacing={1} xs={3}>
                            <TextField
                                required
                                validate={required}
                                label="Název"
                                name="name"
                                margin="dense"/>
                            <TextField
                                name="number"
                                label="Číslo objektu"
                                margin="dense"/>
                            <TextField
                                name="code"
                                margin="dense"
                                label="Kód objektu"
                                type="text"/>
                            <Select
                                required
                                validate={required}
                                name="companyId"
                                margin="dense"
                                label="Společnost">
                                {companyList.map(company => (
                                    <MenuItem value={company.id}>
                                        {company.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <TextField
                                name="vncIp"
                                margin="dense"
                                label="VNC IP"/>
                            <TextField
                                margin="dense"
                                name="vncPort"
                                label="VNC port"/>
                            <Select
                                name="state"
                                required
                                validate={required}
                                margin="dense"
                                label="Stav">
                                <MenuItem value={OBJECT_STATE.ACTIVE}>
                                    Aktivní
                                </MenuItem>
                                <MenuItem value={OBJECT_STATE.INACTIVE}>
                                    Neaktivní
                                </MenuItem>
                                <MenuItem value={OBJECT_STATE.MAINTENANCE}>
                                    Servis
                                </MenuItem>
                            </Select>
                        </Grid>
                        <Grid container direction={"column"} alignItems={"flex-start"} spacing={1} xs={3} marginLeft="10px">
                            <Select
                                name="regionId"
                                margin="dense"
                                label="Region">
                                {regionList.map(region => (
                                    <MenuItem value={region.id}>
                                        {region.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <TextField
                                name="street"
                                label="Ulice"
                                margin="dense"/>
                            <TextField
                                name="city"
                                label="Město"
                                margin="dense"/>
                            <TextField
                                name="zip"
                                label="PSČ"
                                margin="dense"/>
                        </Grid>
                    </Grid>

                    <Button
                        type={"submit"}
                        startIcon={<SaveIcon/>}
                        variant="contained"
                        color="primary">
                        Uložit
                    </Button>
                </form>
            )}/>
    );
};

ObjectForm.propsType = {
    onSubmit: PropTypes.func,
    regionList: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
    }),
    typeList: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
    }),
    companyList: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
    }),
    initialValues: PropTypes.shape({
        name: PropTypes.string,
        number: PropTypes.string,
        code: PropTypes.string,
        companyId: PropTypes.number,
        typeId: PropTypes.number,
        vncIp: PropTypes.string,
        vncPort: PropTypes.string,
        state: PropTypes.oneOf(['ACTIVE', 'INACTIVE', 'MAINTENANCE']),
        regionId: PropTypes.number,
        street: PropTypes.string,
        city: PropTypes.string,
        zipCode: PropTypes.string
    })
};

export default ObjectForm;
