import React, {useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import {loadObject} from "../../../reducers/objects/map";
import ObjectPlcsGrid from "../../panels/objects/ObjectPlcsGrid";
import {useParams} from "react-router-dom";

const ObjectPlcsPage = () => {
    const {objectId} = useParams();
    const object = useSelector(state => state.objects.map[objectId]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!object) {
            dispatch(loadObject(objectId));
        }
    });

    if (!object) {
        return (<CircularProgress/>)
    }

    return (
        <Grid container direction={"column"}>
            <Typography variant="h4" component="h1" gutterBottom>
                Objekt: {object.name} - PLC
            </Typography>
            <ObjectPlcsGrid objectId={objectId}/>
        </Grid>
    )
}

export default ObjectPlcsPage;