import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import MaterialTable from "material-table";
import Paper from "@mui/material/Paper";
import {addUserRoles, deleteUserRole, loadUserRoles, unloadUserRoles} from "../../../reducers/users/roles";
import PropTypes from "prop-types";
import AddUserRoleModal from "../../../components/modals/AddUserRoleModal";
import {keys} from "rambda";
import {ROLE} from "../../../constants";
import localized from "../../../utils/localized";
import {formatDate} from "../../../utils/formatters";
import {defaultMarcoTheme} from "../../../theme";
import {ThemeProvider} from "@mui/material";

const UserRolesGrid = ({userId}) => {
    const dispatch = useDispatch();
    const userRoles = useSelector(state => state.users.roles[userId]);
    const [showAddRole, setShowAddRole] = useState(false);

    const usedRoles = !userRoles ? [] : userRoles.map(userRole => userRole.role);
    const freeRoles = keys(ROLE).filter(role => !usedRoles.includes(role));

    useEffect(() => {
        dispatch(loadUserRoles(userId));
        return () => dispatch(unloadUserRoles(userId))
    }, [dispatch, userId]);

    const handleDelete = async(_event, selectedUserRoles) => {
        selectedUserRoles.forEach(async userRole => await dispatch(deleteUserRole(userId, userRole.id)));
    };

    const handleAddRoles = async(roles) => {
        await dispatch(addUserRoles(userId, roles));
        await dispatch(loadUserRoles(userId));
    };

    const handleCloseAddRoleModal = () => {
        setShowAddRole(false);
    }

    const handleOpenAddRoleModal = () => {
        setShowAddRole(true);
    };

    return (
        <ThemeProvider theme={defaultMarcoTheme}>
        <MaterialTable
                actions={[{
                    icon: 'remove',
                    position: "toolbarOnSelect",
                    tooltip: 'Odebrat',
                    onClick: handleDelete
                }, {
                    icon: 'add',
                    position: "toolbar",
                    tooltip: 'Přidat',
                    onClick: handleOpenAddRoleModal
                }]}
                options={{
                    selection: true,
                    search: false,
                    showTitle: false,
                    cellStyle: {padding: '0.3em'},
                    headerStyle: {padding: '0.3em'},
                    pageSize:15,
                    pageSizeOptions: [15, 30, 100],
                    rowStyle: (_userRole, index) => {
                        return {
                            backgroundColor: index % 2 ? "white" : "aliceBlue"
                        }
                    }
                }}
                components={{
                    Container: props => <Paper {...props} elevation={0}/>
                }}
                columns={[{
                        title: 'Role',
                        field: 'role',
                        render: assignment => (<span>{localized.getString(assignment.role)}</span>)
                    },{
                        title: 'Popis',
                        sorting: false,
                        render: assignment => (<span>{localized.getString(`${assignment.role}_description`)}</span>)
                    }, {
                        title: 'Přidělil', field: 'assigner.username'
                    },{
                        title: 'Datum', field: 'date', render: ({date}) => formatDate(date),
                    }
                ]}
                data={userRoles}
                title="Přidělené role"
            />
            {showAddRole && (
                <AddUserRoleModal
                    handleAddRoles={handleAddRoles}
                    handleClose={handleCloseAddRoleModal}
                    roles={freeRoles}/>
                )}
        </ThemeProvider>
    );
}

UserRolesGrid.propTypes = {
    userId: PropTypes.number.isRequired
}
export default UserRolesGrid;
