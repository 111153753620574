import React from 'react';
import PropTypes from 'prop-types';
import CompanyForm from "../../../components/forms/companies/CompanyForm";
import {handleApiError, MESSAGE_SEVERENITY, newMessage} from "../../../reducers/messages";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {createCompany, updateCompany} from "../../../reducers/companies/map";

const CompanyEdit = ({company}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const initialValues = !company ? {} : {
        name: company.name,
        maintenance: company.maintenance
    };


    async function updateEntity(values) {
        try {
            await dispatch(updateCompany(company.id, values));
            dispatch(newMessage(MESSAGE_SEVERENITY.SUCCESS, "Společnost uložena"));
        } catch (error) {
            dispatch(handleApiError(error));
        }
    }

    async function createEntity(values) {
        try {
            const newCompany = await dispatch(createCompany(values));
            dispatch(newMessage(MESSAGE_SEVERENITY.SUCCESS, "Nová společnost vytvořena"));
            history.push(`/admin/companies/${newCompany.id}`);
        } catch (error) {
            dispatch(handleApiError(error));
        }
    }

    const handleOnSubmit = async (values) => {
        if (company) {
            await updateEntity(values);
        } else {
            await createEntity(values);
        }
    }
    return (
        <CompanyForm
            initialValues={initialValues}
            onSubmit={handleOnSubmit}/>
    );
}

CompanyEdit.propsTypes = {
    company: PropTypes.object
}

export default CompanyEdit;
