import React from "react";
import {Field, Form} from "react-final-form";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import {TextField} from "mui-rff";
import Button from "@mui/material/Button";

const LoginForm = ({onSubmit}) => {
    return (
        <Form
            onSubmit={onSubmit}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <Grid container direction={"column"}>
                        <TextField
                            required
                            label="Jméno"
                            name="username"
                            margin="dense"/>
                        <TextField
                            required
                            name="password"
                            key="password"
                            label="Heslo"
                            type="password"
                            margin="dense"/>
                        <Button variant="contained" type="submit">Přihlásit</Button>
                    </Grid>
                </form>
            )
            }/>
    );
};

LoginForm.propsType = {
  onSubmit: PropTypes.func
};

export default LoginForm;
