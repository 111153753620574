import React, {useState} from "react";
import moment from "moment";
import DateFilterForm from "../../../components/forms/DateFilterForm";
import {useDispatch} from "react-redux";
import BoardModal from "../../../components/modals/BoardModal";
import PropTypes from "prop-types";
import RegistersStatistics from "../registers/RegistersStatistics";
import {createRegistersDashboard, DASHBOARD_TYPE} from "../../../reducers/dashboards/entities";

const initState = {
    from: moment().subtract(1, 'days').format(),
    to: moment().format()
};

const RegistersStatsBoardModal = ({dataKey, registerIds, handleClose}) => {
    const [filter, setFilterState] = useState(initState);
    const dispatch = useDispatch();
    const {from, to} = filter;

    const handleSubmitFilter = ({from, to}) => {
        setFilterState(Object.assign({}, filter,{from, to}));
    };

    const handleCreateDashboard = async (_e) => {
        await dispatch(createRegistersDashboard(registerIds, DASHBOARD_TYPE.REGISTERS_STATS));
    }

    return (
        <BoardModal
            title={"Statistiky"}
            handleClose={handleClose}
            handleCreateDashboard={handleCreateDashboard}>
            <DateFilterForm
                from={from}
                to={to}
                onSubmit={handleSubmitFilter}/>
                <RegistersStatistics
                    registerIds={registerIds}
                    dataKey={dataKey}
                    from={from}
                    to={to}/>
        </BoardModal>
    )
};

RegistersStatsBoardModal.propTypes = {
    dataKey: PropTypes.string.isRequired,
    registerIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    handleClose: PropTypes.func.isRequired
}

export default RegistersStatsBoardModal;
