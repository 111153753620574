import {createAction, handleActions} from "redux-actions";
import {getUrl} from "../../utils/api";

const USER_ACCESS_PAGE_LOADED = "USER_ACCESS_PAGE_LOADED";
const USER_ACCESS_PAGE_UNLOADED = "USER_ACCESS_PAGE_UNLOADED";

export function loadUserAccessesPage(page, size) {
    return async (dispatch) => {
        const pageResponse = await dispatch(getUrl('/user-accesses', {page, size}));
        dispatch(accessesPageLoaded(pageResponse));
        return pageResponse;
    }
}

function accessesPageLoaded(page) {
    return createAction(USER_ACCESS_PAGE_LOADED)({page});
}

export function unloadUserAccessesPage() {
    return createAction(USER_ACCESS_PAGE_UNLOADED)();
}

export default handleActions({
    [USER_ACCESS_PAGE_LOADED]: (_state, {payload: {page}}) => page,
    [USER_ACCESS_PAGE_UNLOADED]: () => {return {}}
}, {});
