import React from "react";
import {Field, Form} from "react-final-form";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {DateField} from '@mui/x-date-pickers/DateField';

import 'moment/locale/cs';
import moment from "moment";
import {DatePicker} from "@mui/x-date-pickers";

const DateFilterForm = ({from, to, onSubmit}) => {
    return (
        <Form
            initialValues={{from, to}}
            onSubmit={onSubmit}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de">
                    <Grid direction={"row"} container>
                        <Grid item>
                            <Field name={"from"}>
                                {props =>(
                                    <DatePicker name={props.input.name}
                                               value={props.input.value}
                                               onChange={props.input.onChange}/>
                                )}
                            </Field>
                        </Grid>
                        <Grid item>
                            <Field name={"to"}>
                                {props =>(
                                    <DatePicker name={props.input.name}
                                               value={props.input.value}
                                               onChange={props.input.onChange}/>
                                )}
                            </Field>
                        </Grid>
                        <Grid item>
                            <Button type="submit" variant="contained" color="primary">Načíst</Button>
                        </Grid>
                    </Grid>
                    </LocalizationProvider>
                </form>
            )
            }/>
    );
};

DateFilterForm.propsType = {
    onSubmit: PropTypes.func
};

export default DateFilterForm;
