import {createAction, handleActions} from 'redux-actions';
import {getUrl, putUrl} from "../../utils/api";
import {GLOBAL_RESET_ACTION} from "../global";

const PROFILE_LOADED = 'PROFILE_LOADED';
const PROFILE_UNLOADED = 'PROFILE_UNLOADED';

const profileLoaded = profile => createAction(PROFILE_LOADED)({profile});

export const loadProfile = () => async (dispatch) => {
    const profile = await dispatch(getUrl('/settings/profile'));
    dispatch(profileLoaded(profile));
    return profile;
}

export const changePassword = password => {
    return async dispatch => {
        await dispatch(putUrl(`/settings/change-password`, {password},null, false));
    }
}

export const updateProfile = values => {
    return async dispatch => {
        const profile = await dispatch(putUrl('/settings/profile', values));
        dispatch(profileLoaded(profile));
    }
}

export const unloadProfile = () => createAction(PROFILE_UNLOADED)();

export default handleActions({
    [GLOBAL_RESET_ACTION]: () => null,
    [PROFILE_LOADED]: (state, {payload: {profile}}) => profile,
    [PROFILE_UNLOADED]: () => null
}, null);