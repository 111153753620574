import {createAction, handleActions} from "redux-actions";
import {GLOBAL_RESET_ACTION} from "./global";

const NEW_MESSAGE = "NEW_MESSAGE";
const CLEAR_MESSAGES = "CLEAR_MESSAGES";

export const MESSAGE_SEVERENITY = {
    ERROR: "error",
    WARNING: "warning",
    INFO: "info",
    SUCCESS: "success"
};

export const newMessage = (severenity, text) => {
    return createAction(NEW_MESSAGE)({severenity, text});
}

export const handleFetchError = error => {
    return dispatch => dispatch(handleApiError(error));
}

export const handleApiError = error => {
    return dispatch => {
        console.error(error);
        dispatch(newMessage(MESSAGE_SEVERENITY.ERROR, error.message));
    }
}

export const clearMessages = () => {
    return createAction(CLEAR_MESSAGES)();
};

export default handleActions({
    [GLOBAL_RESET_ACTION]: () => [],
    [NEW_MESSAGE]: (state, {payload: {severenity, text}}) => {
        state.push({severenity, text});
        return Object.assign([], state);
    },
    [CLEAR_MESSAGES]: () => []
}, []);
