import {createAction, handleActions} from 'redux-actions';
import {getUrl} from "../../utils/api";
import {LOAD_STATE} from "../../constants";
import {GLOBAL_RESET_ACTION} from "../global";

const COMPANIES_LIST_LOADED = 'COMPANIES_LIST_LOADED';

const initState = {
    content: [],
    loadState: LOAD_STATE.PENDING
};

const companiesLoaded = companies => {
    return createAction(COMPANIES_LIST_LOADED)({companies});
}

export function loadCompaniesList() {
    return dispatch => {
        return dispatch(getUrl('/companies'))
            .then(companies => dispatch(companiesLoaded(companies)))
    }
}


export default handleActions({
    [GLOBAL_RESET_ACTION]: () => initState,
    [COMPANIES_LIST_LOADED]: (state, {payload: {companies}}) => Object.assign({}, state, {
        content: companies,
        loadState: LOAD_STATE.LOADED
    })
}, initState);
