import React from "react";
import {Redirect, Route} from "react-router-dom";
import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import {hasAuthSelector} from "../selectors/authSelectors";

const hasAnyAuthSelector = createSelector(
    state => state.auth,
    auth => auth.authority && auth.authority.length > 0
);

const PrivateRoute = ({component, auth, _role, path,...props}) => {
    const selector = auth ? hasAuthSelector(auth) : hasAnyAuthSelector;
    const hasRight = useSelector(selector);
    const Component = component;

    return (
        <Route
            {...props}
            path={path}
            render={() => {
                return hasRight ? (<Component/>) : (<Redirect to={"/login"}/>)
            }}
        />
     );
};

PrivateRoute.propsType = {
    auth: PropTypes.string,
    role: PropTypes.string,
    path: PropTypes.string.isRequired,
    component: PropTypes.element.isRequired
};

export default PrivateRoute;
