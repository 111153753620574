import React, {useState} from "react";
import TopNav from "../../components/TopNav";
import Grid from "@mui/material/Grid";
import {useDispatch, useSelector} from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import {clearMessages} from "../../reducers/messages";
import {Alert} from "@mui/material";
import {ErrorBoundary} from "react-error-boundary";

const DAlert = (props) =>(<Alert elevation={6} variant="filled" {...props} />);

export default function DefaultLayout({children}) {
    const [snackState, setSnackState] = useState({open: false});
    const dispatch = useDispatch();
    const messages = useSelector(state => state.messages);

    const handleSnackClose = () => {
        setSnackState({ ...snackState, open: false });
        dispatch(clearMessages());
    };

    return (
        <ErrorBoundary
            fallback={<div>Something went wrong</div>}
            onReset={(_details) => dispatch(dispatch(clearMessages()))}>
            <Grid container direction={"column"} spacing={2}>
                <Grid item>
                    <TopNav/>
                </Grid>
               <Grid item>
                    {children}
               </Grid>
                {messages && messages.map((msg, key) => (
                    <Snackbar
                        onClose={handleSnackClose}
                        open={snackState}
                        anchorOrigin={{vertical:'bottom', horizontal:'left'}}
                        autoHideDuration={6000}
                        key={key}>
                        <div>
                        <DAlert severity={msg.severenity}>
                            {msg.text}
                        </DAlert>
                        </div>
                    </Snackbar>
                ))}
            </Grid>
        </ErrorBoundary>
    );
}
