import {createAction, handleActions} from 'redux-actions';
import {deleteUrl, getUrl, postUrl, putUrl} from "../../utils/api";
import {MESSAGE_SEVERENITY, newMessage} from "../messages";
import {GLOBAL_RESET_ACTION} from "../global";
import * as R from "rambda";

const initState = {

}

const DASHBOARD_LOADED = 'DASHBOARD_LOADED';
const DASHBOARD_DELETED = 'DASHBOARD_DELETED';
const DASHBOARDS_LOADED = 'DASBOARDS_LOADED';
const DASHBOARDS_POSITIONS_CHANGED = 'DASHBOARDS_POSITIONS_CHANGED';
const DASHBOARDS_UNLOAD = 'DASHBOARDS_UNLOAD';
const DASHBOARDS_LOAD_ERROR = 'DASHBOARDS_LOAD_ERROR';
const DASHBOARD_INTERVAL_CHANGED = 'DASHBOARD_INTERVAL_CHANGED';
const DASHBOARD_VISUALIZATION_CHANGED = 'DASHBOARD_VISUALIZATION_CHANGED';
const DASHBOARD_HOURS_CHANGED = 'DASHBOARD_HOURS_CHANGED';


export const DASHBOARD_TYPE = {
    REGISTERS_GRAPH: 'REGISTERS_GRAPH',
    REGISTERS_STATS: 'REGISTERS_STATS',
    OUT_OF_RANGE_REGISTERS: 'OUT_OF_RANGE_REGISTERS',
    UNREACHABLE_REGISTERS: 'UNREACHABLE_REGISTERS',
    AUDIT_EVENTS: 'AUDIT_EVENTS',
    SYSTEM_STATUS: 'SYSTEM_STATUS',
};

export function createDashboard(type) {
    return async(dispatch) => await apiCreateDashboard(dispatch, {type});
}

function apiCreateDashboard(dispatch, request) {
    return dispatch(postUrl('/dashboards', request))
        .then(() => dispatch(newMessage(MESSAGE_SEVERENITY.SUCCESS, "Vytvořen nový dashboard")))
        .then(() => dispatch(loadDashboards()));
}

export function createRegistersDashboard(registerIds, type) {
    return async(dispatch) => {
        const request = {
            type,
            registerIds,
            name: 'Registry'
        };

        return await apiCreateDashboard(dispatch, request);
    }
}

export function deleteDashboard(id) {
     return dispatch => dispatch(deleteUrl(`/dashboards/${id}`))
            .then(() => dispatch(dashboardDeleted(id)))
            .then(() => dispatch(newMessage(MESSAGE_SEVERENITY.SUCCESS, "Dashboard smazán")));
}

export function updateRegistersDashboard(id, values) {
    return async dispatch => {
        await dispatch(putUrl(`/dashboards/${id}`, {type: 'editRegistersDashboard', ...values}));
        await dispatch(loadDashboard(id));
    }
}

export function changeDashboardInterval(id, interval) {
    return async dispatch => {
        dispatch(dashboardIntervalChanged(id, interval));
        dispatch(putUrl(`/dashboards/${id}/interval`,  null,{interval}));
    }
}

export function changeDashboardVisualization(id, visualization) {
    return async  dispatch => {
        dispatch(dashboardVizualizationChanged(id, visualization));
        dispatch(putUrl(`/dashboards/${id}/visualization`,  null,{visualization}));
    }
}

export function changeDashboardHours(id, hours) {
    return async  dispatch => {
        dispatch(dashboardHoursChanged(id, hours));
        dispatch(putUrl(`/dashboards/${id}/hours`,  null,{hours}));
    }
}

export function updateDashboard(id, values) {
    return async dispatch => {
        await dispatch(putUrl(`/dashboards/${id}`, {type: 'editDashboard', ...values}));
        await dispatch(loadDashboard(id));
    }
}

function dashboardIntervalChanged(id, interval) {
    return createAction(DASHBOARD_INTERVAL_CHANGED)({id, interval});
}

function dashboardVizualizationChanged(id, vizualization) {
    return createAction(DASHBOARD_VISUALIZATION_CHANGED)({id, vizualization});
}

function dashboardHoursChanged(id, hours) {
    return createAction(DASHBOARD_HOURS_CHANGED)({id, hours});
}

const dashboardDeleted = (id) => {
    return createAction(DASHBOARD_DELETED)({id})
}

function dashboardsPositionsChanged(dashboards) {
    return createAction(DASHBOARDS_POSITIONS_CHANGED)({dashboards});
}

function savePositions(dashboards) {
    const positions = dashboards.map(({id, rowIndex, columnIndex}) => {
        return {dashboardId: id, rowIndex, columnIndex}
    });
    return async(dispatch) => dispatch(putUrl('/dashboards/update-positions', {positions}));
}


export function changeDashboardPosition(oldColumnIndex, oldRowIndex, newColumnIndex, newRowIndex) {
    return async (dispatch, getState) => {
        const dashboards = R.values(getState().dashboards.entities);
        const dashboard = dashboards.find(dashboard =>
            (dashboard.rowIndex === oldRowIndex) && (dashboard.columnIndex === oldColumnIndex)
        );

        if (!dashboard) {
            throw new Error(`Dashboard(columnIndex=${oldColumnIndex}, rowIndex=${oldRowIndex}) not found`);
        }

        const isDashboard = R.propEq('id', dashboard.id);
        const updatedDashboard = Object.assign({}, dashboard, {
            columnIndex: newColumnIndex,
            rowIndex: newRowIndex
        })

        const restDashboards = R.reject(isDashboard)(dashboards);
        const targetColumn  = R.filter(R.propEq('columnIndex', newColumnIndex))(restDashboards);
        const sortedTargetColumn = R.sortBy(R.prop("rowIndex"), targetColumn);

        const updateRowIndex = (dashboard, index) => Object.assign({}, dashboard, {rowIndex: index});
        sortedTargetColumn.splice(newRowIndex, 0, updatedDashboard)
        const updatedTargetColumn = sortedTargetColumn.map(updateRowIndex);

        const restColumnsDashboards = R.reject(R.propEq("columnIndex", newColumnIndex), restDashboards);
        const restColumns = R.groupBy(R.prop("columnIndex"), restColumnsDashboards);
        const updatedRestColumns = R.map(column => R.sortBy(R.prop("rowIndex"), column).map(updateRowIndex), restColumns);
        const updatedRestColumnsDashboards = R.reduce(R.concat, [], R.values(updatedRestColumns));
        const updatedDashboards = R.concat(updatedRestColumnsDashboards, updatedTargetColumn);

        dispatch(dashboardsPositionsChanged(updatedDashboards));
        await dispatch(savePositions(updatedDashboards));

        return updatedDashboards;
    }
}

export function loadDashboard(dashboardId) {
    return async dispatch => {
        const dashboard = await dispatch(getUrl(`/dashboards/${dashboardId}`));
        dispatch(dashboardLoaded(dashboard));
    }
}

function dashboardLoaded(dashboard) {
    return createAction(DASHBOARD_LOADED)({dashboard});
}

export const loadDashboards = () => {
    return dispatch => {
        return dispatch(getUrl('/dashboards'))
            .then(dashboards => dispatch(dashboardsLoaded(dashboards)))
            .catch(e => {
                console.error(e.message);
                dispatch(loadError());
            })
    }
};

const dashboardsLoaded = dashboards => {
    return createAction(DASHBOARDS_LOADED)({dashboards});
}

const loadError = createAction(DASHBOARDS_LOAD_ERROR);

function normalizeDashboard(dashboard) {
    if (dashboard.registers) {
        dashboard.registerIds = dashboard.registers.map(register => register.id);
        delete dashboard.registers;
    }
    return dashboard;
}

function convertToMap(dashboards) {
    return dashboards.reduce((map, dashboard) => {
        dashboard = normalizeDashboard(dashboard);
        map[dashboard.id] = dashboard;
        return map;
    }, {});
}

export default handleActions({
    [GLOBAL_RESET_ACTION]: () => initState,
    [DASHBOARDS_LOADED]: (state, {payload: {dashboards}}) => {
        return convertToMap(dashboards);
    },
    [DASHBOARDS_POSITIONS_CHANGED]: (state, {payload: {dashboards}}) => {
        return convertToMap(dashboards);
    },
    [DASHBOARD_LOADED]: (state, {payload: {dashboard}}) => {
        return Object.assign({}, state, {[dashboard.id]: normalizeDashboard(dashboard)})
    },
    [DASHBOARD_DELETED]: (state, {payload: {id}}) => {
        delete state[id];
        return Object.assign({}, state);
    },
    [DASHBOARD_VISUALIZATION_CHANGED]: (state, {payload: {id, vizualization}}) => {
        const dashboard = state[id];
        if (!dashboard) {
            console.warn(`Dashboard(id=${id}) not loaded. Cannot change vizualization in store`);
            return state;
        }
        return Object.assign({}, state, {[id]: {...dashboard, vizualization}});
    },
    [DASHBOARD_INTERVAL_CHANGED]: (state, {payload: {id, interval}}) => {
        const dashboard = state[id];
        if (!dashboard) {
            console.warn(`Dashboard(id=${id}) not loaded. Cannot change interval in store`);
            return state;
        }
        return Object.assign({}, state, {[id]: {...dashboard, interval}});
    },
    [DASHBOARD_HOURS_CHANGED] : (state, {payload: {id, hours}}) => {
        const dashboard = state[id];
        if (!dashboard) {
            console.warn(`Dashboard(id=${id}) not loaded. Cannot change interval in store`);
            return state;
        }
        return Object.assign({}, state, {[id]: {...dashboard, hours}});
    },
    [DASHBOARDS_UNLOAD]: () => initState
}, initState);