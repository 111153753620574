import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import RegistersGrid from "../registers/RegistersGrid";
import PropTypes from "prop-types";
import {isNil} from "rambda";
import {
    dashboardRegistersAddRegister,
    dashboardRegistersRemoveRegister,
    loadDashboardRegisters,
    unloadDashboardRegisters
} from "../../../reducers/dashboards/registers";
import {handleApiError} from "../../../reducers/messages";
import {useDispatch, useSelector} from "react-redux";
import MaterialTable from "material-table";
import Paper from "@mui/material/Paper";
import {loadDashboard} from "../../../reducers/dashboards/entities";
import {defaultMarcoTheme} from "../../../theme";
import {ThemeProvider} from "@mui/material";

const gridRef = React.createRef();
const SelectedRegistersEdit = ({dashboard}) =>{
    const dashboardId = dashboard.id;
    const registers = useSelector(state => state.dashboards.registers[dashboardId]) || [];
    const [isInProgress, setIsInProgress] = useState(false);
    const dispatch = useDispatch();

    const loadData = async() => {
        //if (isNil(registers)) {
            try {
                return await dispatch(loadDashboardRegisters(dashboardId));
            } catch (e) {
                dispatch(handleApiError(e));
            }

    }

    const unloadData = async() => {
        dispatch(unloadDashboardRegisters(dashboardId));
    }

    const handleAddRegister = async(_event, register) => {
        try {
            setIsInProgress(true);
            await dispatch(dashboardRegistersAddRegister(dashboardId, register.id));
            await dispatch(loadDashboardRegisters(dashboardId));
            dispatch(loadDashboard(dashboardId));
        } catch (e) {
            dispatch(handleApiError(e))
        } finally {
            setIsInProgress(false);
        }
    }

    const handleRemoveRegister = async(_event, register) => {
        try {
            setIsInProgress(true);
            await dispatch(dashboardRegistersRemoveRegister(dashboardId, register.id));
            await dispatch(loadDashboardRegisters(dashboardId));
            gridRef.current.refresh();
            dispatch(loadDashboard(dashboardId))
        } catch (e) {
            dispatch(handleApiError(e))
        } finally {
            setIsInProgress(false);
        }
    }

    useEffect(() => {
        loadData();
    }, [dispatch, dashboardId])

    useEffect(() => {
        return () => unloadData();
    }, []);

    if (isNil(dashboard) || isNil(registers)) {
        return null;
    }


    return (
        <ThemeProvider theme={defaultMarcoTheme}>
            <Grid container>
                <MaterialTable
                    style={{width: '100%'}}
                    actions={[{
                        icon: 'remove',
                        tooltip: 'odstranit',
                        position: 'row',
                        disabled: isInProgress,
                        onClick: handleRemoveRegister
                    }]}
                    options={{
                        search: false,
                        toolbar: false,
                        filtering: false,
                        cellStyle: {padding: '0.3em'},
                        headerStyle: {padding: '0.3em'},
                        pageSize: 5,
                        pageSizeOptions: [5],
                        rowStyle: (_registerType, index) => {
                            return {
                                backgroundColor: index % 2 ? "white" : "aliceBlue",
                            }
                        }
                    }}
                    components={{
                        Container: props => <Paper {...props} elevation={0}/>
                    }}
                    columns={[
                        {title: 'Název', field: 'name'},
                        {title: 'Typ', field: 'type'},
                        {title: 'Objekt', field: 'objectName'}
                    ]}
                    data={registers}
                    title="Registry"
                />
                <RegistersGrid
                    gridRef={gridRef}
                    displayMode={"basic"}
                    searchEnabled={true}
                    dashboardId={dashboardId}
                    filterMode={"withoutDashboardId"}
                    actions={[{
                        icon: 'add',
                        tooltip: 'Přidat',
                        position: "row",
                        disabled: isInProgress,
                        onClick: handleAddRegister
                    }]}/>
            </Grid>
        </ThemeProvider>
    )
}

SelectedRegistersEdit.propTypes = {
    dashboard: PropTypes.object.isRequired
}

export default SelectedRegistersEdit;
