import React from "react";
import RegistersGrid from "../../panels/registers/RegistersGrid";

const RegistersPage = () => {
    return (
        <div>
            <RegistersGrid searchEnabled={true}/>
        </div>
    );
};

export default RegistersPage;