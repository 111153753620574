import React, {createRef, useEffect, useMemo, useRef, useState} from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import {useDispatch, useSelector} from "react-redux";
import {loadRegistersValues, unloadRegisterValues} from "../../../reducers/registers/values";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import {registersSelector} from "../../../reducers/registers/selectors";
import {loadMissingRegisters} from "../../../reducers/registers/map";
import {handleApiError} from "../../../reducers/messages";
import {keys} from "rambda";
import {METRIC_INTERVAL, METRIC_VIZUALIZATION} from "../../../constants";
import Chart from "react-apexcharts";
import {formatRegisterValue} from "../../../utils/formatters";
import {ThemeProvider} from "@mui/material";
import {defaultMarcoTheme} from "../../../theme";
import {ErrorBoundary} from "react-error-boundary";


function calcSeries(valuesData, registers) {
    if (!valuesData || !registers) {
        return null;
    }

    const series = valuesData.map(({registerId, values}) => {
        const register = registers.find(item => item.id === registerId);
        if (!register) {
            return null;
        }

        const data = values.map(({date, value}) => {
            return {
                x: moment.utc(date),
                y: value
            }
        });

        return {
            name: `${register.object.name} ${register.name}`,
            data
        }
    }).filter(series => series !== null);

    return series;
}

function calYAxis(registers) {
    const usedYAxis = new Map();
    const yaxis = registers.map(register => {
        const unit = register.type.unit;
        var axis = usedYAxis.get(unit);
        if (axis) {
            return {...axis, show: false};
        }

        const seriesName = `${register.object.name} ${register.name}`;
        axis = {
            seriesName,
            labels: {
                formatter: function (value) {
                    const registerType = register.type;
                    return formatRegisterValue(registerType.unit, value, registerType.precision);
                }
            }
        };
        usedYAxis.set(unit, axis);
        return axis;
    }).filter(axis => axis !== null);
    return yaxis;
}

function calcGraphOptions(yaxis, registers) {
    const options = {
        chart: {
            id: "basic-bar",
            animations: {
                enabled: false,
            },
            defaultLocale: 'cs',
            locales: [{
                name: 'cs',
                options: {
                    months: ['leden', 'únor', 'březen', 'duben', 'květen', 'červen', 'červenec', 'srpen', 'září', 'říjen', 'listopad', 'prosinec'],
                    shortMonths: ['led', 'úno', 'bře', 'dub', 'kvě', 'črv', 'čvn', 'srp', 'zář', 'říj', 'lis', 'pro'],
                    days: ['neděle', 'pondělí', 'úterý', 'středa', 'čtvrtek', 'pátek', 'sobota'],
                    shortDays: ['ne', 'po', 'út', 'st', 'čt', 'pá', 'so'],
                    toolbar: {
                        download: 'Stáhnout SVG',
                        selection: 'Výbrat',
                        selectionZoom: 'Detaily',
                        zoomIn: 'Přiblížit',
                        zoomOut: 'Oddálit',
                        pan: 'Panning', /*TODO localization*/
                        reset: 'Resetovat',
                    }
                }
            }]
        },
        xaxis: {
            type: 'datetime',
            labels: {
                datetimeUTC: false
            }
        },
        yaxis,
        tooltip: {
            x: {
                format: "dd.MM.yyyy HH:mm:ss"
            },
            y: {
                formatter: (value, {series, seriesIndex, dataPointIndex}) => {
                    const registerType = registers[seriesIndex].type;
                    return formatRegisterValue(registerType.unit, value, registerType.precision);
                }
            }
        }
    };
    return options;
}

const RegistersGraph = ({registerIds, dataKey, from, to, _width, vizualization, interval}) => {
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const registers = useSelector(registersSelector(registerIds));
    const valuesData = useSelector(state => state.registers.values[dataKey]);

    const fetchData = async() => {
        if (!registerIds) {
            return;
        }

        try {
            setLoading(true);
            await dispatch(loadMissingRegisters(registerIds));
            await dispatch(loadRegistersValues(dataKey, registerIds, moment(from).utc().format(), moment(to).utc().format(), vizualization, interval));
        } catch (e) {
            dispatch(handleApiError(e));
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchData();
    }, [from, to, registerIds, vizualization, interval, dataKey]);

    useEffect(() => {
    return () => {
        dispatch(unloadRegisterValues(dataKey));
    };}, []);


    const series = useMemo(() => calcSeries(valuesData, registers), [registers,valuesData]);
    const yaxis = useMemo(()=> calYAxis(registers), [registers]);
    const options = useMemo(() => calcGraphOptions(yaxis, registers), [yaxis, registers]);

    if (isLoading) {
        return (<CircularProgress />)
    }
    if (!valuesData || !registers) {
        return null;
    }

    const chartType = vizualization === METRIC_VIZUALIZATION.INCREMENTS ? 'bar' : 'line';
    //const chartType = 'line';

    return (
        <ThemeProvider theme={defaultMarcoTheme}>
            <Grid container>
                <ErrorBoundary fallback={<div>Something went wrong</div>}>
                    <Chart
                        options={options}
                        series={series}
                        type={chartType}
                        width={800}
                    />
                </ErrorBoundary>
            </Grid>
        </ThemeProvider>
    );
};

RegistersGraph.propTypes = {
    dataKey: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    vizualization: PropTypes.oneOf(keys(METRIC_VIZUALIZATION)).isRequired,
    interval: PropTypes.oneOf(keys(METRIC_INTERVAL)),
    registerIds: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default RegistersGraph;
