import React from 'react';
import {Form, FormSpy} from "react-final-form";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import SaveIcon from '@mui/icons-material/Save';
import PropTypes from "prop-types";
import {composeValidators, mustBeNumber, required} from "../../../utils/validators";
import {VALUE_TYPE} from "../../../reducers/constants";
import {Checkboxes, Select, TextField} from "mui-rff";
import {defaultMarcoTheme} from "../../../theme";
import {ThemeProvider} from "@mui/material";


const RegisterForm = ({onSubmit, initialValues, typeList, objectList, plcList}) => {
    return (
        <ThemeProvider theme={defaultMarcoTheme}>
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <Grid container direction={"row"} alignItems={"flex-start"}>
                        <Grid container direction={"column"} alignItems={"flex-start"} spacing={2} xs={3}>
                            <TextField
                                label="Název"
                                validate={required}
                                name="name"
                                type="text"
                                margin="dense"
                            />
                            <TextField
                                name="code"
                                label="Kód registru"
                                type="text"
                                margin="dense"
                            />
                            <Select
                                margin="dense"
                                validate={required}
                                name="objectId"
                                label="Objekt">
                                {objectList.map(object => (
                                    <MenuItem value={object.id}>
                                        {object.name}
                                    </MenuItem>
                                ))}
                            </Select>

                            <FormSpy render={({values}) => (
                                <Select
                                    margin="dense"
                                    validate={required}
                                    name="plcId"
                                    label="PLC">
                                    {plcList.filter(plc => plc.objectId === values.objectId)
                                        .map(plc => (
                                            <MenuItem value={plc.id}>
                                                {plc.ipAddress}:{plc.port}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            )}/>

                            <TextField
                                margin="dense"
                                validate={required}
                                name="number"
                                label="Číslo registru"
                            />

                            <Select
                                margin="dense"
                                validate={required}
                                name="typeId"
                                label="Typ registru">
                                {typeList.map(type => (
                                    <MenuItem value={type.id}>
                                        {type.name}
                                    </MenuItem>
                                ))}
                            </Select>

                            <FormSpy
                                subscription={{values: true, modified: true}}
                                render={({values, _modified}) => {
                                    const type = values.typeId ? typeList.find(type => type.id === values.typeId) : null;
                                    const rawValueType = type ? type.rawValueType : null;
                                    return (
                                        <>
                                            {
                                                rawValueType
                                                && (rawValueType === VALUE_TYPE.BIT || rawValueType === VALUE_TYPE.BYTE)
                                                && (
                                                    <TextField
                                                        margin="dense"
                                                        name="index"
                                                        defaultValue={0}
                                                        label="Index"
                                                        type="number"/>
                                                )
                                            }
                                            <TextField
                                                margin="dense"
                                                validate={composeValidators(required, mustBeNumber)}
                                                defaultValue={type ? type.coeficient : null}
                                                name="coeficient"
                                                label="Koeficient"/>
                                            <TextField
                                                defaultValue={type ? type.minValue : null}
                                                validate={composeValidators(required, mustBeNumber)}
                                                name="minValue"
                                                margin="dense"
                                                label="Min. hodnota"/>
                                            <TextField
                                                name="maxValue"
                                                validate={composeValidators(required, mustBeNumber)}
                                                margin="dense"
                                                defaultValue={type ? type.maxValue : null}
                                                label="Max. hodnota"/>
                                        </>
                                    )
                                }}/>

                        </Grid>

                        <Grid container direction={"column"} xs={3} paddingLeft={"10px"}>
                            <Checkboxes
                                name={"outOfRangeNotif"}
                                data={[{label:"Notifikovat poruchu"}]}/>
                            {/* TODO <Checkboxes
                                name={"repeatNotifs"}
                                data={[{label:"Notifikovat opakovaně"}]}/>
                            */}<Checkboxes
                                name={"dayReport"}
                                data={[{label:"Denní report"}]}/>
                            <Checkboxes
                                name={"weekReport"}
                                data={[{label:"Týdenní report"}]}/>
                            <Checkboxes
                                name={"monthReport"}
                                data={[{label:"Měsíční report"}]}/>
                            <Checkboxes
                                name="active"
                                data={[{label: "Aktivní registr"}]}/>
                        </Grid>
                    </Grid>

                    <Button
                        type={"submit"}
                        startIcon={<SaveIcon />}
                        variant="contained"
                        color="primary">
                        Uložit
                    </Button>
                </form>
            )}/>
        </ThemeProvider>
        );
    };

RegisterForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    objectList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
    })).isRequired,
    plcList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        ipAddress: PropTypes.string,
        port: PropTypes.number,
        objectId: PropTypes.number
    })).isRequired,
    typeList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
    })).isRequired,
    initialValues: PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.string,
        objectId: PropTypes.number,
        number: PropTypes.string,
        typeId: PropTypes.number,
        coeficient: PropTypes.number,
        minValue: PropTypes.number,
        maxValue: PropTypes.number,
        active: PropTypes.bool,
        outOfRangeNotif: PropTypes.bool,
        dayReport: PropTypes.bool,
        weekReport: PropTypes.bool,
        monthReport: PropTypes.bool
    })
};

export default RegisterForm;
