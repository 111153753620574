import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import UserEdit from "../../panels/users/UserEdit";
import {useDispatch, useSelector} from "react-redux";
import {deleteUser, loadUser} from "../../../reducers/users/map";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import DeleteIcon from '@mui/icons-material/Delete';
import {handleApiError, MESSAGE_SEVERENITY, newMessage} from "../../../reducers/messages";
import DeleteConfirmModal from "../../../components/modals/DeleteConfirmModal";


const UserPage = () => {
    const {id} = useParams();
    const history = useHistory();

    const user = useSelector(state => state.users.map[id]);
    const dispatch = useDispatch();
    const [deleteConfirm, setDeleteConfirm] = useState({open: false, items: []});

    const fetchData = async () => {
        await dispatch(loadUser(id));
    }

    useEffect(() => {
        fetchData();
    }, [dispatch, id]);

    if (!id) {
        history.push('/users');
    }

    if (!user) {
        return (<CircularProgress/>)
    }

    const handleDelete = async (_e) => {
        try {
            await dispatch(deleteUser(id))
            dispatch(newMessage(MESSAGE_SEVERENITY.SUCCESS, "Uživatel smazán"));
            history.push("/users")
        } catch (e) {
            dispatch(handleApiError(e));
        } finally {
            hideDeleteConfirm();
        }
    }

    const showDeleteConfirm = () => setDeleteConfirm({open: true, items:[user.username]});
    const hideDeleteConfirm = () => setDeleteConfirm({open: false, items: []});

    return (
        <Grid container direction={"column"}>
            <Grid container direction={"row"} alignContent={"space-around"}>
                <Grid item xs={10}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Uživatel: {user.username}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Button
                        onClick={showDeleteConfirm}
                        variant="contained"
                        color="secondary"
                        startIcon={<DeleteIcon/>}>
                        Smazat
                    </Button>
                </Grid>
            </Grid>
            <UserEdit user={user}/>
            <DeleteConfirmModal
                handleConfirm={handleDelete}
                items={deleteConfirm.items}
                handleClose={hideDeleteConfirm}
                open={deleteConfirm.open} />
        </Grid>
    );
}

export default UserPage;
