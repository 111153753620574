import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import ObjectForm from "../../../components/forms/objects/ObjectForm";
import {useDispatch, useSelector} from "react-redux";
import {loadCompaniesList} from "../../../reducers/companies/list";
import {loadRegionsList} from "../../../reducers/regions/list";
import {handleApiError, MESSAGE_SEVERENITY, newMessage} from "../../../reducers/messages";
import {useHistory} from "react-router-dom";
import {createObject, updateObject} from "../../../reducers/objects/map";

const ObjectEdit = ({object}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const companyList = useSelector(
        state => state.companies.list.content.map(company => {
            return {
                id: company.id,
                name: company.name
            }
        })
    );

    const regionList = useSelector(
        state => state.regions.list.content.map(region => {
            return {
                id: region.id,
                name: region.name
            }
        })
    )

    useEffect(() => {
        if (!companyList || companyList.length === 0) {
            dispatch(loadCompaniesList());
        }

        if (!regionList || regionList.length === 0) {
            dispatch(loadRegionsList());
        }
    }, [dispatch]);

    async function updateEntity(values) {
        try {
            await dispatch(updateObject(object.id, values));
            dispatch(newMessage(MESSAGE_SEVERENITY.SUCCESS, "Objekt uložen"));
        } catch (error) {
            dispatch(handleApiError(error));
        }
    }

    async function createEntity(values) {
        try {
            const object = await dispatch(createObject(values));
            dispatch(newMessage(MESSAGE_SEVERENITY.SUCCESS, "Nový objekt vytvořen"));
            history.push(`/objects/${object.id}`);
        } catch (error) {
            dispatch(handleApiError(error));
        }
    }

    const handleOnSubmit = async (values) => {
        if (object) {
            await updateEntity(values);
        } else {
            await createEntity(values);
        }
    }
    const initialValues = !object ? {} : {
        name: object.name,
        number: object.number,
        code: object.code,
        companyId: object.company.id,
        state: object.state,
        regionId: object.region ? object.region.id : null,
        street: object.street,
        city: object.city,
        zipCode: object.zipCode
    };

    return (
        <ObjectForm
            initialValues={initialValues}
            regionList={regionList}
            companyList={companyList}
            onSubmit={handleOnSubmit}/>
    )
}

ObjectEdit.propsTypes = {
    object: PropTypes.object
}

export default ObjectEdit;
