import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";
import {Link as RouterLink, useLocation, useRouteMatch} from "react-router-dom";
import Link from "@mui/material/Link";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";

const SideMenuLayout = ({links, createLinks, children}) => {
    const match = useRouteMatch();
    const location = useLocation();
    const authorities  = useSelector(state => state.auth.authority);
    if (createLinks !== undefined) {
        links = createLinks(match);
    }
    return (
        <Grid container spacing={1}>
            <Grid item xs={2}>
                <List>
                    {links.map(({link, label, role}, index) => {
                        if (role && !authorities.includes("ROLE_" + role)) {
                            return null;
                        }

                        return (
                            <ListItem button key={index} selected={link === location.pathname}>
                                <Link
                                    component={RouterLink}
                                    color="inherit" to={link}>{label}</Link>
                            </ListItem>
                        );
                    })}
                </List>
            </Grid>
            <Grid item xs={10}>
                {children}
            </Grid>
        </Grid>
    );
};

SideMenuLayout.propTypes = {
    links: PropTypes.arrayOf(PropTypes.shape({
        link: PropTypes.string,
        label: PropTypes.string
    })),
    createLinks: PropTypes.func,
    children: PropTypes.element.isRequired
}
export default SideMenuLayout;
