
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import UserForm from "../../../components/forms/users/UserForm";
import {useDispatch, useSelector} from "react-redux";
import {loadCompaniesList} from "../../../reducers/companies/list";
import {useHistory} from "react-router-dom";
import {handleApiError, MESSAGE_SEVERENITY, newMessage} from "../../../reducers/messages";
import {createUser, updateUser} from "../../../reducers/users/map";

const UserEdit = ({user}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const companyList = useSelector(state => state.companies.list.content.map(company => {
        return {
            id: company.id,
            name: company.name
        }
    }));

    const handleOnSubmit = async (values) => {
        if (user) {
            try {
                await dispatch(updateUser(user.id, values));
                dispatch(newMessage(MESSAGE_SEVERENITY.SUCCESS, "Uživatel uložen"));
            } catch (error) {
                dispatch(handleApiError(error));
            }
        } else {
            try {
                const user = await dispatch(createUser(values));
                dispatch(newMessage(MESSAGE_SEVERENITY.SUCCESS, "Nový uživatel vytvořen"));
                history.push(`/users/${user.id}`);
            } catch (error) {
                dispatch(handleApiError(error));
            }
        }
    }

    useEffect(() => {
        if (!companyList || companyList.length === 0) {
            dispatch(loadCompaniesList());
        }
    }, [dispatch, companyList])

    const initialValues = !user ? {} : {
        username: user.username,
        firstName: user.firstName,
        surname: user.surname,
        email: user.email,
        phone: user.phone,
        companyId: user.company.id,
        blocked: user.blocked,
        sendNotifs: user.sendNotifs,
        sendDayReport: user.sendDayReport,
        sendWeekReport: user.sendWeekReport,
        sendMonthReport: user.sendMonthReport
    };

    return (
        <UserForm
            initialValues={initialValues}
            companyList={companyList}
            showGeneratePassword={user == null}
            onSubmit={handleOnSubmit}/>
    );
}

UserEdit.propsTypes = {
    user: PropTypes.object
}

export default UserEdit;
