import {createAction, handleActions} from 'redux-actions';
import {deleteUrl, getUrl, postUrl, putUrl} from "../../utils/api";
import {handleFetchError} from "../messages";
import {GLOBAL_RESET_ACTION} from "../global";

const OBJECT_LOADED = 'OBJECT_LOADED';
const OBJECT_UNLOADED = 'OBJECT_UNLOADED';
const initState = {};

function objectLoaded(object) {
    return createAction(OBJECT_LOADED)({object});
}

export const loadObject = (id) => {
    return dispatch => {
        return dispatch(getUrl(`/objects/${id}`))
            .then(object => dispatch(objectLoaded(object)))
            .catch(error => handleFetchError(error));
    }
};

export function deleteObject(id)  {
    return async(dispatch) => {
        await dispatch(deleteUrl(`/objects/${id}`));

    }
}

function unloadObject(id) {
    return createAction(OBJECT_UNLOADED)({id});
}

export function updateObject(id, values) {
    return dispatch => {
        return dispatch(putUrl(`/objects/${id}`, values))
            .then(object => dispatch(objectLoaded(object)));
    }
}

export function createObject(values) {
    return dispatch => {
        return dispatch(postUrl('/objects', values))
            .then(object => {
                dispatch(objectLoaded(object));
                return object;
            });
    }
}

export default handleActions({
    [GLOBAL_RESET_ACTION]: () => initState,
    [OBJECT_LOADED]: (state, {payload: {object}}) => Object.assign({}, state, {[object.id]: object}),
    [OBJECT_UNLOADED]: (state, {payload: {id}}) => Object.assign({}, delete state[id])
}, initState);
