import {createAction, handleActions} from 'redux-actions';
import {getUrl} from "../../utils/api";

const NOTIFICATIONS_PAGE_LOADED = "NOTIFICATIONS_PAGE_LOADED";
const NOTIFICATIONS_PAGE_UNLOADED = "NOTIFICATIONS_PAGE_UNLOADED";

export function loadNotificationsPage(page, size, filter) {
    return async (dispatch) => {
        const pageResponse = await dispatch(getUrl('/notifications', {page, size, ...filter}));
        dispatch(pageLoaded(pageResponse));
        return pageResponse;
    }
}

function pageLoaded(page) {
    return createAction(NOTIFICATIONS_PAGE_LOADED)({page});
}

export function unloadNotificationsPage() {
    return createAction(NOTIFICATIONS_PAGE_UNLOADED)();
}

export default handleActions({
    [NOTIFICATIONS_PAGE_LOADED]: (state, {payload: {page}}) => page,
    [NOTIFICATIONS_PAGE_UNLOADED]: () => {return {}}
}, {});