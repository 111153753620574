import {combineReducers} from 'redux';
import registers from "./registers";
import auth from "./auth";
import {connectRouter} from "connected-react-router";
import objects from "./objects";
import companies from "./companies";
import users from "./users";
import registerTypes from "./registerTypes";
import dashboards from "./dashboards";
import messages from "./messages";
import regions from "./regions";
import plcs from "./plcs";
import settings from "./settings";
import notifications from "./notifications";
import system from "./system";

const createRootReducer = (history) => combineReducers({
    regions,
    registers,
    objects,
    companies,
    users,
    registerTypes,
    auth,
    dashboards,
    messages,
    plcs,
    settings,
    notifications,
    system,
    router: connectRouter(history)
});


export default createRootReducer;
