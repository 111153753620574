import React, {useEffect} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {loadUser} from "../../../reducers/users/map";
import UserObjectsGrid from "../../panels/users/UserObjectsGrid";
import CircularProgress from "@mui/material/CircularProgress";

const UserObjectsPage = () => {
    const {id} = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!user) {
            dispatch(loadUser(id));
        }
    }, [id, dispatch]);

    const user = useSelector(state => state.users.map[id]);
    if (!user) {
        return (
            <div>
                <CircularProgress />
            </div>
        )
    }

    return (
        <Grid container direction={"column"}>
            <Typography variant="h4" component="h1" gutterBottom>
                Uživatel: {user.username}
            </Typography>
            <UserObjectsGrid userId={id}/>
        </Grid>
    );
}


export default UserObjectsPage;