import React from 'react';
import PropTypes from 'prop-types';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PlcEdit from "../../panels/objects/ObjectPlcEdit";
import {useHistory, useParams} from "react-router-dom";

const ObjectPlcCreatePage = () => {
    const {objectId} = useParams();
    const history = useHistory();
    if (!objectId) {
        history.push(`/objects`);
    }

    return (
        <Grid container direction={"column"}>
            <Typography variant="h4" component="h1" gutterBottom>
                Nové PLC
            </Typography>
            <PlcEdit objectId={objectId}/>
        </Grid>
    );
}

ObjectPlcCreatePage.propTypes = {
    objectId: PropTypes.number.isRequired
}

export default ObjectPlcCreatePage;

