import React from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DashboardIcon from "@mui/icons-material/Dashboard";
import * as PropsTypes from "prop-types";

const BoardModal = ({handleClose, handleCreateDashboard, title, children}) => {
    return (
        <Dialog
            fullWidth={true}
            maxWidth={"lg"}
            open={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>

            <DialogActions>
                {handleCreateDashboard && (
                    <Button
                        variant="contained"
                        onClick={handleCreateDashboard}
                        startIcon={<DashboardIcon/>}>
                        Dashboard
                    </Button>
                )}
                <Button
                    variant="contained" color="primary"
                    onClick={handleClose}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

BoardModal.propTypes = {
    handleClose: PropsTypes.func.isRequired,
    children: PropsTypes.element.isRequired,
    title: PropsTypes.string.isRequired,
    handleCreateDashboard: PropsTypes.func
}

export default BoardModal;
