import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import MaterialTable from "material-table";
import Paper from "@mui/material/Paper";
import {useHistory} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import {loadObjectPlcs, unloadObjectPlcs} from "../../../reducers/objects/plcs";
import {defaultMarcoTheme} from "../../../theme";
import {ThemeProvider} from "@mui/material";

const ObjectPlcsGrid = ({objectId}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const plcs = useSelector(state => state.objects.plcs[objectId]);

    useEffect(() => {
        dispatch(loadObjectPlcs(objectId))
    }, [dispatch, objectId]);

    useEffect(() => {
        return () => dispatch(unloadObjectPlcs(objectId));
    }, []);


    const handleEdit = (_event, plc) => {
        history.push(`/objects/${objectId}/plcs/${plc.id}`);
    };

    const handleCreate = () => {
        history.push(`/objects/${objectId}/plcs/create`);
    }

    if (!plcs) {
        return (
            <CircularProgress/>
        )
    }

    return (
        <ThemeProvider theme={defaultMarcoTheme}>
            <MaterialTable
                options={{
                    pageSize:15,
                    pageSizeOptions: [15, 30, 100],
                    cellStyle: {padding: '0.3em'},
                    headerStyle: {padding: '0.3em'},
                    rowStyle: (_plc, index) => {
                        return {
                            backgroundColor: index % 2 ? "white" : "aliceBlue"
                        }
                    }
                }}
                actions={[{
                    icon: 'edit',
                    position: "row",
                    tooltip: 'Editovat',
                    onClick: handleEdit
                }, {
                    icon: 'add',
                    tooltip: 'Přidat',
                    position: "toolbar",
                    onClick: handleCreate
                }]}
                components={{
                    Container: props => <Paper {...props} elevation={0}/>
                }}
                columns={[
                    {title: 'Ip adresa', field: 'ipAddress'},
                    {title: 'Port', field: 'port'}
                ]}
                data={plcs}
                title="PLC"
            />
        </ThemeProvider>
    );
}

ObjectPlcsGrid.propTypes = {
    objectId: PropTypes.string.isRequired
}

export default ObjectPlcsGrid;
