import React, {useEffect} from "react";
import MaterialTable from "material-table";
import Paper from "@mui/material/Paper";
import {useDispatch, useSelector} from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import {loadRegisterTypesList} from "../../../reducers/registerTypes/list";
import {LOAD_STATE} from "../../../constants";
import {useHistory} from "react-router-dom";
import {ThemeProvider} from "@mui/material";
import {defaultMarcoTheme} from "../../../theme";

const RegisterTypesGrid = () => {
    const {loadState, content} = useSelector(state => state.registerTypes.list);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleEdit = (_event, registerType) => {
        history.push(`/registers/types/${registerType.id}`);
    };

    const handleCreate = _event => {
        history.push(`/registers/types/create`);
    }

    useEffect(()=> {
        dispatch(loadRegisterTypesList());
    }, [dispatch]);


    if (loadState === LOAD_STATE.PENDING) {
        return (
            <CircularProgress />
        )
    }

    if (!content) {
        return null;
    }

    return (
        <ThemeProvider theme={defaultMarcoTheme}>
            <MaterialTable
            options={{
                filtering: true,
                cellStyle: { padding: '0.3em'},
                headerStyle: { padding: '0.3em'},
                pageSize:15,
                pageSizeOptions: [15, 30, 100],
                rowStyle: (_registerType, index) => {
                    return {
                        backgroundColor: index % 2 ? "white" : "aliceBlue",
                    }
                }
            }}
            actions={[{
                    icon: 'edit',
                    tooltip: 'Editovat',
                    onClick: handleEdit
                },
                {
                    icon: 'add',
                    tooltip: 'Přidat',
                    isFreeAction: true,
                    onClick: handleCreate
                }]
            }
            components={{
                Container: props => <Paper {...props} elevation={0}/>
            }}
            columns={[
                { title: 'Název', field: 'name' },
                { title: 'Report', field: 'reportType'},
                { title: 'Max', field: 'maxValue', filtering: false},
                { title: 'Min', field: 'minValue', filtering: false},
                { title: 'Hodnota', field: 'valueType'},
                { title: 'PLC hodnota', field: 'rawValueType'},
                { title: 'Koeficient', field: 'coeficient', filtering: false},
                { title: 'Jednotka', field: 'unit'},
            ]}
            data={content}
            title="Registry - typy"
        />
        </ThemeProvider>
    )
}

export default RegisterTypesGrid;
