import {createSelector} from "reselect";

export const hasAnyAuthSelector = createSelector(
    state => state.auth,
        auth => auth.authority && auth.authority.length > 0
);


export function hasAuthSelector(authority) {
    return createSelector(state => state.auth.authority, authorities => authorities.includes(authority));
}

export function hasRoleSelector(role) {
    return createSelector(state => state.auth.authority, authorities => authorities.includes("ROLE_" + role));
}