import React from "react";
import {useSelector} from "react-redux";
import {hasRoleSelector} from "../selectors/authSelectors";
import PropTypes from "prop-types";

export const HasRole = ({children, role}) => {
    const hasAuth = useSelector(hasRoleSelector(role));
    return hasAuth ? (<>{children}</>) : null;
};

HasRole.propTypes = {
    role: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired
}
export default HasRole;
