import React from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import RegisterTypeEdit from "../../panels/registers/RegisterTypeEdit";


const RegisterTypeCreatePage = () => {
    return (
        <Grid container direction={"column"}>
            <Typography variant="h4" component="h1" gutterBottom>
                Nový typ registru
            </Typography>
            <RegisterTypeEdit/>
        </Grid>
    );
}

export default RegisterTypeCreatePage;
