import React from "react";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DashboardEdit from "../dashboards/DashboardEdit";
import {useSelector} from "react-redux";


const DashboardConfigModal = ({dashboardId, handleClose}) => {
    const dashboard = useSelector(state => state.dashboards.entities[dashboardId]);
    if (!dashboard) {
        return null;
    }

    return (
        <Dialog
            maxWidth={"sm"}
            open={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Nastavení</DialogTitle>
            <DialogContent>
                <DashboardEdit dashboard={dashboard} onClose={handleClose}/>
            </DialogContent>
        </Dialog>
    )
};

DashboardConfigModal.propTypes = {
    dashboardId: PropTypes.number.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default DashboardConfigModal;
