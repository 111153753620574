import {createAction, handleActions} from 'redux-actions';
import {LOAD_STATE} from "../../constants";
import {getUrl} from "../../utils/api";
import {handleFetchError} from "../messages";
import {GLOBAL_RESET_ACTION} from "../global";

const USERS_LIST_LOADED = 'USERS_LIST_LOADED';
const initState = {
    loadState: LOAD_STATE.PENDING,
    content: []
};

export const usersListLoaded = createAction(USERS_LIST_LOADED, page => page);

export function loadUsersList() {
    return dispatch => {
        return dispatch(getUrl('/users'))
            .then(users => dispatch(usersListLoaded(users)))
            .catch(error => dispatch(handleFetchError(error)));
    }
}


export default handleActions({
    [GLOBAL_RESET_ACTION]: () => initState,
    [USERS_LIST_LOADED]: (state, {payload}) => Object.assign({}, state, {
        content: payload,
        loadState: LOAD_STATE.ERROR
    })
}, initState);
