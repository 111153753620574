import {createAction, handleActions} from "redux-actions";
import {getUrl} from "../../utils/api";
import {GLOBAL_RESET_ACTION} from "../global";

const REGISTER_STATES_LOADED = "REGISTER_STATES_LOADED";
const REGISTER_STATES_UNLOADED = "REGISTER_STATES_UNLOADED";

function statesLoaded(states) {
    return createAction(REGISTER_STATES_LOADED)({states});
}

export function unloadRegisterStates(registerIds) {
    return createAction(REGISTER_STATES_UNLOADED)({registerIds});
}

export function loadRegisterStates(registerIds) {
    return async dispatch => {
        const states = await dispatch(getUrl("/register-states", {registerIds}));
        dispatch(statesLoaded(states));
    }
}

export default handleActions({
    [GLOBAL_RESET_ACTION] : () => {},
    [REGISTER_STATES_LOADED]: (state, {payload:{states}}) => {
        const newState = {};
        states.forEach(registerState => newState[registerState.registerId] = registerState);
        return Object.assign(state, newState);
    },
    [REGISTER_STATES_UNLOADED]: (state, {payload: {registerIds}}) => {
        const newState = {};
        registerIds.forEach(id => delete newState[id]);
        return Object.assign(state, newState);
    }
}, {});