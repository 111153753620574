import React, {useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import UserProfileEdit from "../../panels/settings/UserProfileEdit";
import CircularProgress from "@mui/material/CircularProgress";
import {loadProfile, unloadProfile} from "../../../reducers/settings/profile";

const ProfilePage = () => {
    const profile = useSelector(state => state.settings.profile);
    const dispatch = useDispatch();
    const loadData = () => dispatch(loadProfile());

    useEffect(() => {
        loadData();
    }, [dispatch])

    useEffect(() => {
        return () => {
            dispatch(unloadProfile());
        }
    }, []);

    if (!profile) {
        return (<CircularProgress/>);
    }

    return (
        <Grid container direction={"column"}>
            <Typography variant="h4" component="h1" gutterBottom>
                Profil - {profile.username}
            </Typography>
            <UserProfileEdit profile={profile}/>
        </Grid>
    );
}


export default ProfilePage;