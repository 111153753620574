import {createAction, handleActions} from "redux-actions";
import {getUrl} from "../../utils/api";
import {GLOBAL_RESET_ACTION} from "../global";

const initState = {};
const LOADED = "REGISTER_STATISTICS_LOADED";
const UNLOADED = "REGISTER_STATISTICS_UNLOADED";

function loaded(key, statistics) {
    return createAction(LOADED)({key, statistics});
}

function unloadRegisterStatistics(key) {
    return createAction(UNLOADED)(key);
}

export function loadRegisterStatistics(key, registerIds, from, to) {
    return async dispatch => {
        const stats = await dispatch(getUrl('/register-statistics', {registerIds, from, to}));
        return dispatch(loaded(key, stats));
    }
}

export default handleActions({
    [GLOBAL_RESET_ACTION]: initState,
    [LOADED]: (state, {payload: {key, statistics}}) => Object.assign({}, state, {[key]: statistics}),
    [UNLOADED]: (state, {payload: {key}}) => {
        const {[key]: values, ...newState} = state;
        return newState;
    }
 }, initState);