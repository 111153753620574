import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {loadRegisterStatistics} from "../../../reducers/registers/statistics";
import {handleApiError} from "../../../reducers/messages";
import MaterialTable from "material-table";
import Paper from "@mui/material/Paper";
import {formatDate, formatPercent, formatRegisterValue} from "../../../utils/formatters";
import moment from "moment";
import {Link} from "react-router-dom";
import {ThemeProvider, Tooltip} from "@mui/material";
import {defaultMarcoTheme} from "../../../theme";

const RegistersStatistics = ({dataKey, registerIds, from, to, width}) => {
    const dispatch = useDispatch();
    const statistics = useSelector(state => state.registers.statistics[dataKey])
    const [isLoading, setLoading] = useState(true);

    const loadData = async() => {
        try {
            setLoading(true);
            await dispatch(loadRegisterStatistics(dataKey, registerIds, moment(from).utc().format(), moment(to).utc().format()));
        } catch (e) {
            dispatch(handleApiError(e));
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {loadData()},[dispatch, from, to,  registerIds, dataKey]);
    return (
        <ThemeProvider theme={defaultMarcoTheme}>
            <MaterialTable
            isLoading={isLoading}
            style={{width}}
            options={{
                toolbar: false,
                showTitle: false,
                search: false,
                cellStyle: { padding: '0.3em'},
                headerStyle: { padding: '0.3em'},
                pageSize:5,
                pageSizeOptions: [5, 10, 15],
                rowStyle: (_object, index) => {
                    return {
                        fontSize: 14,
                        backgroundColor: index % 2 ? "white" : "aliceBlue",
                    }
                }
            }}
            components={{
                Container: props => <Paper {...props} elevation={0}/>
            }}
            columns={[
                {
                    title: 'Název',
                    field: 'registerName',
                    render: ({registerId, registerName}) => (<Link to={`/registers/${registerId}`}>{registerName}</Link>)
                }, {
                    title: 'Objekt',
                    field: 'objectName',
                    render: ({objectId, objectName}) => (<Link to={`/objects/${objectId}`}>{objectName}</Link>)
                }, {
                    title: 'Min',
                    field: 'statistics.min',
                    render: ({unit, min, precision, minDateTime}) => (
                        <Tooltip title={formatDate(minDateTime)}>
                            <span>{formatRegisterValue(unit, min, precision)}</span>
                        </Tooltip>
                    )
                },{
                    title: 'Max',
                    field: 'statistics.max',
                    render: ({unit, max, precision, maxDateTime}) => (
                        <Tooltip title={formatDate(maxDateTime)}>
                            <span>{formatRegisterValue(unit, max, precision)}</span>
                        </Tooltip>
                    )
                },{
                    title: 'Průměr',
                    field: 'statistics.avg',
                    render: ({unit, avg, precision}) => formatRegisterValue(unit, avg, precision)
                }, {
                    title: 'Mimo rozsah',
                    field: 'statistics.outOfRangePercent',
                    render: ({outOfRangePercent}) => formatPercent(outOfRangePercent)
                }
            ]}
            data={statistics}
        />
        </ThemeProvider>
    )
}

RegistersStatistics.propTypes = {
    registerIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    dataKey: PropTypes.string.isRequired,
    from: PropTypes.object.isRequired,
    to: PropTypes.object.isRequired,
    width: PropTypes.number,
}

RegistersStatistics.defaultProps = {
    width: 850,
}

export default RegistersStatistics;
