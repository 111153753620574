import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import React from "react";
import PropTypes from "prop-types";
import {ThemeProvider} from "@mui/material";
import {defaultMarcoTheme} from "../theme";

export const defaultHours = 24;
export const hoursOptions = [
    {hours: 1, label: "1h"},
    {hours: 4, label: "4h"},
    {hours: 24, label: "1d"},
    {hours: 168, label: "7d"},
    {hours: 744, label: "1m"},
    {hours: 2208, label: "3m"}
];

const HoursDateTimeFilter = ({selectedHours, onHoursSelected}) => {
    return (
        <ThemeProvider theme={defaultMarcoTheme}>
            <ButtonGroup size={"small"}>
                {hoursOptions.map(({hours, label}) => (
                    <Button
                        onClick={() => onHoursSelected(hours)}
                        key={hours}
                        variant={selectedHours === hours ? "contained" : "outlined"}
                        aria-selected={"true"}>
                        {label}
                    </Button>
                ))}
            </ButtonGroup>
        </ThemeProvider>
    )
}

HoursDateTimeFilter.propTypes = {
    selectedHours: PropTypes.oneOf(hoursOptions),
    onHoursSelected: PropTypes.func.isRequired
}

export default HoursDateTimeFilter;