import {createAction, handleActions} from 'redux-actions';
import {getUrl, putUrl} from "../../utils/api";

const DASHBOARD_REGISTERS_LOADED = 'DASHBOARD_REGISTERS_LOADED';
const DASHBOARD_REGISTERS_UNLOADED = 'DASHBOARD_REGISTERS_UNLOADED';

export function loadDashboardRegisters(dashboardId) {
    return async dispatch => {
        const registers = await dispatch(getUrl(`/dashboards/${dashboardId}/registers`));
        dispatch(dashboardRegistersLoaded(dashboardId, registers));
        return registers;
    }
}

export function unloadDashboardRegisters(dashboardId) {
    return createAction(DASHBOARD_REGISTERS_UNLOADED)({dashboardId});
}

function dashboardRegistersLoaded(dashboardId, registers) {
    return createAction(DASHBOARD_REGISTERS_LOADED)({dashboardId, registers})
}

export function dashboardRegistersAddRegister(dashboardId, registerId) {
    return async dispatch => {
        await dispatch(putUrl(`/dashboards/${dashboardId}/add-register`, null, {registerId}))
    }
}

export function dashboardRegistersRemoveRegister(dashboardId, registerId) {
    return async dispatch => {
        await dispatch(putUrl(`/dashboards/${dashboardId}/remove-register`, null, {registerId}))
    }
}


const initState = {}
export default handleActions({
    [DASHBOARD_REGISTERS_LOADED]: (state, {payload: {dashboardId, registers}}) => {
        return Object.assign({}, state, {[dashboardId]: registers})
    },
    [DASHBOARD_REGISTERS_UNLOADED]: (state, {payload: {dashboardId}}) => {
        return Object.assign({}, delete state[dashboardId])
    }
}, initState);