import {combineReducers} from "redux";
import list from "./list";
import map from "./map";
import roles from "./roles";
import objects from "./objects";
import accessesPage from "./accessesPage";

export default combineReducers({
    map,
    list,
    roles,
    objects,
    accessesPage
});
