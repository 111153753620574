import React, {useEffect} from 'react';
import RegisterForm from "../../../components/forms/registers/RegisterForm";
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import {loadObjectsList} from "../../../reducers/objects/list";
import {loadRegisterTypesList} from "../../../reducers/registerTypes/list";
import {createRegister, updateRegister} from "../../../reducers/registers/map";
import {loadPlcsList} from "../../../reducers/plcs/list";
import {LOAD_STATE} from "../../../constants";
import CircularProgress from "@mui/material/CircularProgress";
import {MESSAGE_SEVERENITY, newMessage} from "../../../reducers/messages";
import {useHistory} from "react-router-dom";

const RegisterEdit = ({register}) => {
    const history = useHistory();
    const objectList = useSelector(state => state.objects.list)
    const plcList = useSelector(state => state.plcs.list);
    const typeList = useSelector(state => state.registerTypes.list);

    const dispatch = useDispatch();
    useEffect(() => {
            dispatch(loadObjectsList());
            dispatch(loadRegisterTypesList());
            dispatch(loadPlcsList());
    }, [dispatch]);

    const handleOnSubmit = async (values) => {
        if (register) {
            await dispatch(updateRegister(register.id, values));
            dispatch(newMessage(MESSAGE_SEVERENITY.SUCCESS, "Registr uložen"));
        } else {
            const register = await dispatch(createRegister(values));
            if (register) {
                dispatch(newMessage(MESSAGE_SEVERENITY.SUCCESS, "Nový registr vytvořen"));
                history.push(`/registers/${register.id}`);
            }
        }
    }

    const initValue = !register ? {} : {
        name: register.name,
        code: register.code,
        objectId: register.object.id,
        plcId: register.plc.id,
        index: register.index,
        number: register.number,
        typeId: register.type.id,
        coeficient: register.coeficient,
        minValue: register.minValue,
        maxValue: register.maxValue,
        active: register.active,
        notifications: register.notifications,
        outOfRangeNotif: register.outOfRangeNotif,
        dayReport: register.dayReport,
        weekReport: register.weekReport,
        monthReport: register.monthReport
    };

    if (plcList.loadState === LOAD_STATE.PENDING
        || typeList.loadState === LOAD_STATE.PENDING
        || objectList.loadState === LOAD_STATE.PENDING
    ) {
        return (<CircularProgress/>);
    }

    return (
        <RegisterForm
            plcList={plcList.content}
            typeList={typeList.content}
            objectList={objectList.content}
            initialValues={initValue}
            onSubmit={handleOnSubmit}/>
    )
}

RegisterEdit.propsTypes = {
   register: PropTypes.object
}

export default RegisterEdit;
