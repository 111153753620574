import React from 'react';
import {Form} from "react-final-form";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import SaveIcon from '@mui/icons-material/Save';
import {required} from "../../../utils/validators";
import {TextField} from "mui-rff";

const ChangePasswordForm = ({onSubmit}) => {
    const handleValidate = ({password, passwordConfirmation}) => {
        const errors = {};
        if (password !== passwordConfirmation) {
            errors.passwordConfirmation = 'Hesla se neshodují'
        }
        return errors;
    };

    return (
        <Form
            onSubmit={onSubmit}
            validate={handleValidate}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <Grid container direction={"row"} alignItems={"flex-start"}>
                        <Grid container direction={"column"} alignItems={"flex-start"} spacing={1} xs={3}>
                            <TextField
                                required
                                label="Nové heslo"
                                name="password"
                                margin="dense"
                                type="password"/>
                            <TextField
                                required
                                name="passwordConfirmation"
                                label="Nové heslo - potvrzení"
                                margin="dense"
                                type="password"/>
                        </Grid>
                    </Grid>
                    <Button
                        type={"submit"}
                        startIcon={<SaveIcon/>}
                        variant="contained"
                        color="primary">
                        Uložit
                    </Button>
                </form>
            )}/>
    );
};

ChangePasswordForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
};

export default ChangePasswordForm;
