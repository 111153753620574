import LocalizedStrings from "localized-strings";
import {OBJECT_STATE} from "../reducers/objects/constants";
import {METRIC_INTERVAL, METRIC_VIZUALIZATION, ROLE} from "../constants";
import {OUT_OF_RANGE_STATE} from "../reducers/registers/events/outOfRangePage";
import {REGISTER_ITEM_STATE} from "../reducers/registers/registersPage";
import {REGISTER_READ_STATE} from "../reducers/constants";

const localized = new LocalizedStrings({
        cz: {
            [REGISTER_ITEM_STATE.OK]: 'Ok',
            [REGISTER_ITEM_STATE.OUT_OF_RANGE]: 'Mimo rozsah',
            [REGISTER_ITEM_STATE.MAINTENANCE]: 'Údržba',
            [REGISTER_ITEM_STATE.INACTIVE]: 'Neaktivní',
            [REGISTER_ITEM_STATE.ERROR]: 'Chyba',
            [REGISTER_READ_STATE.ONLINE]: 'Online',
            [OUT_OF_RANGE_STATE.CLOSED]: 'Uzavřeno',
            [OUT_OF_RANGE_STATE.OPEN]: 'Aktivní',
            [OBJECT_STATE.ACTIVE]: 'Aktivní',
            [ROLE.ADMIN]: 'Správce systému',
            [`${ROLE.ADMIN}_description`]: 'Může vše',
            [ROLE.COMPANY_USERS_EDIT]: 'Správce uživatelů společnosti',
            [ROLE.OBJECTS_EDIT]: 'Správce objektů',
            [`${ROLE.OBJECTS_EDIT}_description`]: 'Spravuje objekty, registry, typy registrů',
            [ROLE.USERS_EDIT]: 'Správce uživatelů',
            [`${ROLE.USERS_EDIT}_description`]: 'Spravuje uživatele, jejich role',
            [METRIC_VIZUALIZATION.INCREMENTS]: 'Přírustky',
            [METRIC_VIZUALIZATION.GAUGE]: 'Hodnota',
            [METRIC_INTERVAL.DAILY]: 'denně',
            [METRIC_INTERVAL.HOURLY]: 'hodinově',
            [METRIC_INTERVAL.QUARTER_AN_HOUR]: 'čtvrthodina',
            [METRIC_INTERVAL.WEEKLY]: 'týdně'
        }},
    {/* options */}
);

export default localized;
