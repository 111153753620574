import React, {useState} from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {red} from '@mui/material/colors';
import {makeStyles} from '@mui/styles';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {useDispatch} from "react-redux";
import {Grid, Typography} from "@mui/material";
import CardActions from "@mui/material/CardActions";
import {deleteDashboard} from "../../../reducers/dashboards/entities";
import DashboardConfigRegistersModal from "../modals/DashboardConfigRegistersModal";
import DashboardConfigModal from "../modals/DashboardConfigModal";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 900,
        margin: 5
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

const DashboardContainer = ({id, title, children, registersMenuEnabled}) => {
    const classes = useStyles();
    const [menuAnchor, setMenuAnchor] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [showConfigRegisters, setShowConfigRegisters] = useState(false);
    const dispatch = useDispatch();

    const handleOnDelete = async (_e) => {
        await dispatch(deleteDashboard(id))
            .then(() => setMenuAnchor(null))
    };

    const handleOnShowSettings = () => {
        setShowSettings(true);
        setMenuAnchor(null)
    }

     const handleOnCloseSettings = () => {
        setShowSettings(false);
     }

    const handleOnShowConfigRegisters = () => {
        setShowConfigRegisters(true);
        setMenuAnchor(null)
    }

    const handleOnCloseConfigRegisters = () => {
        setShowConfigRegisters(false);
    }

    return <>
        <Card className={classes.root}>
            <CardActions style={{textAlign: "right"}}>
                <Grid container direction={"row"} alignContent={"flex-start"} justifyContent={"space-between"}>
                        <Typography display={"block"} variant={"h6"}>
                            {title}
                        </Typography>
                    <Grid container alignContent={"flex-end"} justifyContent={"flex-end"} direction={"row"} alignItems={"stretch"} md={6}>
                        <IconButton
                            aria-label="settings"
                            onClick={e => setMenuAnchor(e.currentTarget)}
                            size="large">
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={menuAnchor}
                            keepMounted
                            open={Boolean(menuAnchor)}
                            onClose={() => setMenuAnchor(null)}>
                            <MenuItem onClick={handleOnShowSettings}>Nastavení</MenuItem>
                            {registersMenuEnabled && (
                                <MenuItem onClick={handleOnShowConfigRegisters}>Registry</MenuItem>
                            )}
                            <MenuItem onClick={handleOnDelete}>Odstranit</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </CardActions>

            <CardContent>
                {children}
            </CardContent>
        </Card>

        {showSettings && (
            <DashboardConfigModal handleClose={handleOnCloseSettings} dashboardId={id}/>
        )}

        {showConfigRegisters && (
            <DashboardConfigRegistersModal
                handleClose={handleOnCloseConfigRegisters}
                handleConfirm={handleOnCloseConfigRegisters}
                dashboardId={id}/>
        )}
    </>;
}


DashboardContainer.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
    id: PropTypes.number.isRequired,
    dateTimeFilterEnabled: PropTypes.func,
    hoursSelected: PropTypes.func,
    onHoursSelected: PropTypes.func,
    registersMenuEnabled: PropTypes.bool
}

DashboardContainer.defaultProps = {
    dateTimeFilterEnabled: false,
    registersMenuEnabled: false
}

export default DashboardContainer;
