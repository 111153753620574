import React from 'react';
import {Form} from "react-final-form";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import SaveIcon from '@mui/icons-material/Save';
import {Checkboxes, Select, TextField} from "mui-rff";

const UserForm = ({onSubmit, companyList, initialValues, showGeneratePassword}) => {
    return (
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <Grid container direction={"row"} alignItems={"flex-start"}>
                        <Grid container direction={"column"} alignItems={"flex-start"} spacing={1} xs={3}>
                            <TextField
                                required
                                margin="dense"
                                label="Identifikace"
                                name="username"
                                type="text"/>
                            <TextField
                                required
                                margin="dense"
                                name="firstName"
                                label="Jméno"
                                type="text"/>
                            <TextField
                                required
                                margin="dense"
                                name="surname"
                                label="Příjmení"
                                type="text"/>
                            <TextField
                                name="email"
                                margin="dense"
                                label="Email"
                                type="text"/>
                            <TextField
                                name="phone"
                                margin="dense"
                                label="Telefon"
                                type="text"/>
                            <Select
                                margin="dense"
                                required
                                name="companyId"
                                label="Společnost">
                                {companyList.map(company => {
                                    return (
                                        <MenuItem value={company.id}>
                                            {company.name}
                                        </MenuItem>
                                    )
                                })
                                }
                            </Select>
                            {showGeneratePassword && (
                                <Checkboxes
                                    label="Vygenerovat a odeslat heslo"
                                    data={[{label: "Vygenerovat a odeslat heslo"}]}
                                    name="generateNewPassword"/>
                                )}
                        </Grid>
                        <Grid container direction={"column"} alignItems={"flex-start"} spacing={1} xs={3} marginLeft="10px">
                            <Checkboxes
                                data={[{label: "Blokovat"}]}
                                name="blocked"/>
                            <Checkboxes
                                data={[{label: "Odesílat poruchy"}]}
                                name="sendNotifs"/>
                            <Checkboxes
                                data={[{label: "Odesílat denní report"}]}
                                name="sendDayReport"/>
                            <Checkboxes
                                data={[{label: "Odesílat týdenní report"}]}
                                name="sendWeekReport"/>
                            <Checkboxes
                                data={[{label: "Odesílat týdenní report"}]}
                                name="sendMonthReport"/>
                        </Grid>
                    </Grid>
                    <Button
                        type={"submit"}
                        startIcon={<SaveIcon/>}
                        variant="contained"
                        color="primary">
                        Uložit
                    </Button>
                </form>
            )}/>
    );
};

UserForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    companyList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    })).isRequired,
    showGeneratePassword: PropTypes.bool,
    initialValues: PropTypes.shape({
        username: PropTypes.string,
        surname: PropTypes.string,
        firstName: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
        companyId: PropTypes.number,
        blocked: PropTypes.bool,
        sendSms: PropTypes.bool,
        sendNotifs: PropTypes.bool,
        sendDayReport: PropTypes.bool,
        sendWeekReport: PropTypes.bool,
        sendMonthReport: PropTypes.bool
    })
};

export default UserForm;
