import React from "react";
import RegisterTypesGrid from "../../panels/registerTypes/RegisterTypesGrid";

const RegisterTypesPage = () => {
    return (
        <RegisterTypesGrid/>
    )
};

export default RegisterTypesPage;
