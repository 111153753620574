import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import CompanyEdit from "../../panels/companies/CompanyEdit";
import {deleteCompany, loadCompany} from "../../../reducers/companies/map";
import {handleApiError, MESSAGE_SEVERENITY, newMessage} from "../../../reducers/messages";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteConfirmModal from "../../../components/modals/DeleteConfirmModal";


const CompanyPage = () => {
    const {id} = useParams();
    const history = useHistory();

    const [deleteConfirm, setDeleteConfirm] = useState({open: false, items: []});
    const company = useSelector(state => state.companies.map[id]);
    const dispatch = useDispatch();

    const handleDelete = async (_event) => {
        try {
            await dispatch(deleteCompany(id));
            dispatch(newMessage(MESSAGE_SEVERENITY.SUCCESS, "Společnost smazána"));
            history.push('/admin/companies');
        } catch (e) {
            dispatch(handleApiError(e))
        } finally {
            hideDeleteConfirm();
        }
    }

    const showDeleteConfirm = () => setDeleteConfirm({open: true, items: [company.name]});
    const hideDeleteConfirm = () => setDeleteConfirm({open: false, items: []});

    useEffect(() => {
        dispatch(loadCompany(id));
    }, [id, dispatch, loadCompany]);

    if (!id) {
        history.push('/admin/companies');
    }

    if (!company) {
        return (<CircularProgress/>)
    }

    return (
        <Grid container direction={"column"}>
            <Grid container direction={"row"} alignContent={"space-around"}>
                <Grid item xs={10}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Společnost: {company.name}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Button
                        onClick={showDeleteConfirm}
                        variant="contained"
                        color="secondary"
                        startIcon={<DeleteIcon/>}>
                        Smazat
                    </Button>
                </Grid>
            </Grid>
            <CompanyEdit company={company}/>
            <DeleteConfirmModal
                handleConfirm={handleDelete}
                items={deleteConfirm.items}
                handleClose={hideDeleteConfirm}
                open={deleteConfirm.open} />
        </Grid>
    );
}

export default CompanyPage;
