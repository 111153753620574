import React from "react";
import {Field, Form} from "react-final-form";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import PropTypes from "prop-types";
import {required} from "../../utils/validators";
import {TextField} from "mui-rff";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((_theme) => ({
    formControl: {
        minWidth: 180
    },
    button: {
        margin: 3
    }
}));

const DashboardForm = ({onSubmit, initialValues, onClose}) => {
    const classes = useStyles();
    return (
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <Grid container direction={"row"} alignItems={"flex-start"}>
                        <Grid container direction={"column"} alignItems={"flex-start"}>
                            <TextField
                                validate={required}
                                label="Název"
                                name="name"
                                className="input"
                                type="text"
                            />
                            <Grid item>
                                <Button
                                    className={classes.button}
                                    type={"submit"}
                                    startIcon={<SaveIcon/>}
                                    variant="contained"
                                    color="primary">
                                    Uložit
                                </Button>
                                <Button
                                    onClick={() => onClose()}
                                    className={classes.button}
                                    type={"button"}
                                    variant="contained"
                                    color="secondary">
                                    Zavřít
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            )}/>
    );
}

DashboardForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
        name: PropTypes.string
    })
}

export default DashboardForm;
