import {createAction, handleActions} from 'redux-actions';
import {getUrl} from "../../utils/api";
import {GLOBAL_RESET_ACTION} from "../global";

const OBJECT_PLCS_LOADED = 'OBJECT_PLCS_LOADED';
const OBJECT_PLCS_UNLOADED = 'OBJECT_PLCS_UNLOADED';

export const loadObjectPlcs = objectId => {
    return async(dispatch) => {
        var plcs = await dispatch(getUrl(`/objects/${objectId}/plcs`));
        dispatch(loadedPlcs(objectId, plcs));
    }
}

const loadedPlcs = (objectId, plcs) => createAction(OBJECT_PLCS_LOADED)({objectId, plcs});
export const unloadObjectPlcs = objectId => createAction(OBJECT_PLCS_UNLOADED)({objectId});

export default handleActions({
    [GLOBAL_RESET_ACTION]: () => {},
    [OBJECT_PLCS_LOADED]: (state, {payload: {objectId, plcs}}) => Object.assign({}, state, {[objectId]: plcs}),
    [OBJECT_PLCS_UNLOADED]: (state, {payload: {objectId}}) => Object.assign({}, delete state[objectId])
}, {});