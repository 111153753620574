import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {loadUsersList} from "../../../reducers/users/list";
import MaterialTable from "material-table";
import Paper from "@mui/material/Paper";
import {Link, useHistory} from "react-router-dom";
import {handleApiError} from "../../../reducers/messages";
import {ThemeProvider} from "@mui/material";
import {defaultMarcoTheme} from "../../../theme";

const UsersGrid = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading]  = useState(false);
    useEffect(() => {
        try {
            setIsLoading(true);
            dispatch(loadUsersList());
        } catch (e) {
          dispatch(handleApiError(e));
        } finally {
            setIsLoading(false);
        }
    }, [dispatch]);
    const history = useHistory();
    const users = useSelector(state => state.users.list);

    const handleEdit = (_event, user) => {
        history.push(`/users/${user.id}`);
    };

    const handleCreate = () => {
        history.push('/users/create');
    };


    return (
        <div>
            <ThemeProvider theme={defaultMarcoTheme}>
            <MaterialTable
                isLoading={isLoading}
                actions={[{
                    icon: 'edit',
                    position: "row",
                    tooltip: 'Editovat',
                    onClick: handleEdit
                }, {
                    icon: 'add',
                    tooltip: 'Přidat',
                    position: "toolbar",
                    onClick: handleCreate
                }]}
                options={{
                    filtering: true,
                    cellStyle: {padding: '0.3em'},
                    headerStyle: {padding: '0.3em'},
                    pageSize:15,
                    pageSizeOptions: [15, 30, 100],
                    rowStyle: (user, index) => {
                        return {
                            backgroundColor: index % 2 ? "white" : "aliceBlue",
                            color: user.blocked === true ? "gray" : "inherited"
                        }
                    }
                }}
                components={{
                    Container: props => <Paper {...props} elevation={0}/>
                }}
                columns={[
                    {
                        title: 'Jméno',
                        field: 'username',
                        render: ({username, id}) => (<Link to={`/users/${id}`}>{username}</Link>)
                    },
                    {title: 'Kř. jméno', field: 'firstName'},
                    {title: 'Příjmení', field: 'surname'},
                    {title: 'Email', field: 'email'},
                    {title: 'Společnost', field: 'company.name'},
                    {
                        title: 'Blokován',
                        field: 'blocked',
                        filtering: false,
                        render: user => (<span>{user.blocked ? 'blokován' : 'aktivní'}</span>)
                    }
                ]}
                data={users.content}
                title="Uživatelé"
            />
            </ThemeProvider>
        </div>
    );
}

export default UsersGrid;
