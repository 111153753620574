import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import MaterialTable from "material-table";
import Paper from "@mui/material/Paper";
import {Link, useHistory} from "react-router-dom";
import localized from "../../../utils/localized";
import {formatDate, formatRegisterValue} from "../../../utils/formatters";
import {hasRoleSelector} from "../../../selectors/authSelectors";
import {ROLE} from "../../../constants";
import {loadRegistersPage, REGISTER_ITEM_STATE, unloadRegistersPage} from "../../../reducers/registers/registersPage";
import {handleApiError} from "../../../reducers/messages";
import {OBJECT_STATE} from "../../../reducers/objects/constants";
import {REGISTER_READ_STATE} from "../../../reducers/constants";
import PropTypes from 'prop-types';
import {isNil, map, mergeAll} from "rambda";
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import RegistersGraphBoardModal from "../modals/RegistersGraphBoardModal";
import RegistersStatsBoardModal from "../modals/RegistersStatsBoardModal";
import {ThemeProvider} from "@mui/material";
import {defaultMarcoTheme} from "../../../theme";
import {
    Check,
    CheckRounded,
    CloudDone,
    CloudOff,
    Engineering,
    Error,
    House,
    OneKkOutlined,
    OneKkRounded
} from "@mui/icons-material";
import Typography from "@mui/material/Typography";

const tableRef = React.createRef();
const RegistersGrid = ({filterMode, displayMode, objectId, searchEnabled, actions, dashboardId, gridRef}) => {
    const dispatch = useDispatch();
    const [graph, setGraph] = useState({shown: false, registers: []});
    const [stats, setStats] = useState({shown: false, registers: []});
    const hasEditRole = useSelector(hasRoleSelector(ROLE.OBJECTS_EDIT));
    const history = useHistory();

    useEffect(() => {
        return () => dispatch(unloadRegistersPage());
    }, [dispatch]);

    const handleShowRegistersGraph = (_event, selectedRegisters) => setGraph({shown: true, registers: selectedRegisters});
    const handleShowRegisterGraph = (_event, register) => setGraph({shown: true, registers: [register]});

    const handleCloseGraph = () => setGraph(Object.assign({}, graph, {shown: false}));

    const handleShowStats = (_event, selectedRegisters) => setStats({shown: true, registers: selectedRegisters});
    const handleCloseStats = () => setStats(Object.assign({}, graph, {shown: false}));

    const handleCreate = () => history.push('/registers/create');
    const handleEdit = (_event, register) =>  history.push(`/registers/${register.id}/edit`);
    const refreshTable = () => tableRef.current && tableRef.current.onQueryChange();
    if (gridRef) {
        gridRef.current = {
            refresh: refreshTable
        };
    }

    const createGridActions = () => {
        if (actions) {
            actions.map(origActions => {
                const {onClick} = origActions;
                origActions.onClick = async (event, register) => {
                    await onClick(event, register);
                    await refreshTable();
                }
            })
            return actions;
        } else {
            actions=[];
        }

        if (hasEditRole) {
            actions.push({
                icon: 'edit',
                tooltip: 'Editovat',
                position: "row",
                onClick: handleEdit
            }, {
                icon: 'add',
                tooltip: 'Přidat',
                position: "toolbar",
                onClick: handleCreate
            });
        }
        actions.push({
            tooltip: 'Graf',
            icon: 'timeline',
            position: 'row',
            onClick: handleShowRegisterGraph
        });

        if (displayMode === 'basic') {
            return [];
        }

        actions.push({
            tooltip: 'Statistiky',
            icon: 'list',
            position: "toolbarOnSelect",
            onClick: handleShowStats
        }, {
            tooltip: 'Graf',
            icon: 'timeline',
            position: "toolbarOnSelect",
            onClick: handleShowRegistersGraph
        }, {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: refreshTable
        });

        return actions;
    }

    const getData = async (query) => {
        const {pageSize, page, filters, search, orderBy, orderDirection} = query;
        const filterMap = {
            "object": "objectName",
            "type": "typeName",
            "objectState": "objectStates",
            "readState": "readStates"
        };

        const apiFilters = mergeAll(filters.map(({value, column: {field}}) => {
            return {[filterMap[field] || field]: value}
        }));

        if (filterMode === 'unreachable') {
            apiFilters['readStates'] = [REGISTER_READ_STATE.ERROR];
            apiFilters['objectStates'] = [OBJECT_STATE.ACTIVE];
        }

        if (filterMode === 'withoutDashboardId') {
            apiFilters['withoutDashboardId'] = dashboardId
        }

        if (filterMode === 'object' && isNil(objectId)) {
            return null;
        }


        if (!isNil(objectId)) {
            apiFilters['objectId'] = objectId;
        }

        try {
            const fetchedPage = await dispatch(loadRegistersPage(page, pageSize, apiFilters, search, orderBy, orderDirection));
            return ({
                data: fetchedPage.content,
                page,
                totalCount: fetchedPage.totalElements
            });
        } catch (e) {
            dispatch(handleApiError(e));
        }

        return ({
            data: [],
            page,
            totalCount: 0
        });
    };

    const renderLastValue = ({unit, lastValue}) =>  (
        <Typography variant="body1" color="inherit" display="inline">
            {formatRegisterValue(unit, lastValue)}
        </Typography>
    );
    return (
        <ThemeProvider theme={defaultMarcoTheme}>
            <MaterialTable
                style={{width: '100%'}}
                tableRef={tableRef}
                options={{
                    sorting: false,
                    search: searchEnabled,
                    filtering: displayMode !== 'basic',
                    selection: displayMode !== 'basic',
                    showTitle: displayMode !== 'basic',
                    cellStyle: { padding: '0.3em'},
                    headerStyle: { padding: '0.3em'},
                    toolbar: displayMode !== 'basic' || searchEnabled,
                    pageSize: displayMode === 'basic' ? 7 : 15,
                    pageSizeOptions: displayMode === 'basic' ? [7] : [15, 30, 100],
                    rowStyle: (object, index) => {
                        return {
                            backgroundColor: index % 2 ? "white" : "aliceBlue",
                            color: REGISTER_ITEM_STATE.INACTIVE === object.state ? "gray" : "inherited"
                        }
                    }
                }}
                actions={createGridActions()}
                components={{
                    Container: props => <Paper {...props} elevation={0}/>
                }}
                columns={[
                    {
                        title: 'Název',
                        field: 'name',
                        render: ({id, name}) => (<Link to={`/registers/${id}`}>{name}</Link>)
                    },
                    {
                        title: 'Typ',
                        field: 'type',
                        hidden: displayMode === 'basic',
                        width: displayMode === 'basic' ? 0 : null,
                    }, {
                        title: 'Objekt',
                        field: 'objectName',
                        hidden: displayMode === 'object',
                        width: displayMode === 'object'? 0 : null,
                        render: ({objectId, objectName}) => (<Link to={`/objects/${objectId}`}>{objectName}</Link>)
                    }, {
                        title: 'Online',
                        field: 'readState',
                        hidden: displayMode === 'basic' && filterMode !== "unreachable",
                        width: displayMode === 'basic' && filterMode !== "unreachable" ? 0: null,
                        render: ({readState, errorMessage}) => {
                            return (
                                <span>
                                    {REGISTER_READ_STATE.ERROR === readState && (
                                        <CloudOff color="warning" titleAccess={errorMessage}/>
                                    )}

                                    {REGISTER_READ_STATE.ONLINE === readState && (
                                        <CloudDone color="info" titleAccess={readState}/>
                                    )}
                                </span>
                            )
                        },
                        lookup: map(readState => localized.getString(readState), REGISTER_READ_STATE)
                    }, {
                        title: 'Objekt',
                        field: 'objectState',
                        hidden: displayMode !== 'detailed',
                        lookup: map(state => localized.getString(state), OBJECT_STATE),
                        render: ({objectState}) => {
                            return (
                                <span>
                                    {objectState === OBJECT_STATE.ACTIVE && (
                                        <House color="info"/>
                                    )}
                                    {objectState === OBJECT_STATE.INACTIVE && (
                                        <House color="disabled"/>
                                    )}
                                    {objectState === OBJECT_STATE.MAINTENANCE && (
                                        <Engineering color="disabled"/>
                                    )}
                                </span>
                            )
                        }
                    }, {
                        title: 'Porucha',
                        field: 'outOfRange',
                        filtering: false,
                        hidden: displayMode === 'basic',
                        width: displayMode === 'basic' ? 0 : null,
                        render: ({outOfRange}) => {
                            return (
                                <span>
                                        {!outOfRange && (
                                            <Check color="secondary"/>
                                        )}

                                        {outOfRange && (
                                            <Error color="warning"/>
                                        )}
                                </span>
                            )
                        }
                    }, {
                        title: 'Hodnota',
                        render: renderLastValue,
                        hidden: displayMode === 'basic',
                        width: displayMode === 'basic' ? 0 : null
                    },  {
                        title: 'Čas',
                        render: ({valueDate}) => (
                            <Typography variant="body1" color="inherit" display="inline">
                                {formatDate(valueDate)}
                            </Typography>
                        ),
                        field: 'valueDate',
                        hidden: displayMode === 'basic',
                        filtering: false
                    }
                ]}
                data={getData}
                title="Registry"
            />
            {graph.shown && (
                <RegistersGraphBoardModal
                    registerIds={graph.registers.map(register => register.id)}
                    dataKey={"registersPage"}
                    handleClose={handleCloseGraph}/>
            )}

            {stats.shown && (
                <RegistersStatsBoardModal
                    registerIds={stats.registers.map(register => register.id)}
                    handleClose={handleCloseStats}
                    dataKey={"registersPage"} />
            )}
        </ThemeProvider>
    );
};

RegistersGrid.propTypes = {
    gridRef: PropTypes.object,
    actions: PropTypes.array,
    searchEnabled: PropTypes.bool,
    objectId: PropTypes.number,
    dashboardId: PropTypes.number,
    filterMode: PropTypes.oneOf(['unreachable', 'object', 'withoutDashboardId']),
    displayMode: PropTypes.oneOf(['basic', 'detailed', 'object'])
};

RegistersGrid.defaultProps = {
    actions: null,
    searchEnabled: false,
    dashboardId: null,
    objectId: null,
    filterMode: null,
    displayMode: 'detailed'
}

export default RegistersGrid;
