import React, {useEffect} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {loadObject} from "../../../reducers/objects/map";
import {handleApiError, MESSAGE_SEVERENITY, newMessage} from "../../../reducers/messages";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import {deletePlc, loadPlc, unloadPlc} from "../../../reducers/plcs/map";
import PlcEdit from "../../panels/objects/ObjectPlcEdit";


const ObjectPlcPage = () => {
    const {id, objectId} = useParams();
    const history = useHistory();
    const object = useSelector(state => state.objects.map[objectId]);
    const plc = useSelector(state => state.plcs.map[id]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadPlc(id));
        return () => dispatch(unloadPlc(id));
    }, [id, objectId]);

    useEffect(() => {
        if (!object) {
            dispatch(loadObject(objectId));
        }
    }, []);

    if (!id || !objectId) {
        history.push(`/objects/`);
    }

    if (!plc) {
        return (<CircularProgress/>)
    }

    const handleDelete = async (_event) => {
        try {
            await dispatch(deletePlc(id))
            dispatch(newMessage(MESSAGE_SEVERENITY.SUCCESS, "PLC/ smazáno"));
            history.push(`/objects/${objectId}/plcs`);
        } catch (e) {
            dispatch(handleApiError(e));
        }
    }

    return (
        <Grid container direction={"column"}>
            <Grid container direction={"row"} alignContent={"space-around"}>
                <Grid item xs={10}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        PLC: {object.name} - {plc.ipAddress}:{plc.port}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Button
                        onClick={handleDelete}
                        variant="contained"
                        color="secondary"
                        startIcon={<DeleteIcon/>}>
                        Smazat
                    </Button>
                </Grid>
            </Grid>
            <PlcEdit plc={plc} object={object}/>
        </Grid>
    );
}

export default ObjectPlcPage;
