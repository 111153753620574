import {createAction, handleActions} from 'redux-actions';
import {deleteUrl, getUrl, postUrl, putUrl} from "../../utils/api";
import {handleApiError, handleFetchError} from "../messages";
import {GLOBAL_RESET_ACTION} from "../global";

const REGISTER_TYPE_LOADED = 'REGISTER_TYPE_LOADED';
const REGISTER_TYPE_UNLOADED = 'REGISTER_TYPE_UNLOADED';
const initState = {};

function registerTypeLoaded(registerType) {
    return createAction(REGISTER_TYPE_LOADED)({registerType});
}

function unloadRegisterType(id) {
    return createAction(REGISTER_TYPE_UNLOADED)({id});
}

export function updateRegisterType(id, values) {
    return dispatch => {
        return dispatch(putUrl(`/register-types/${id}`, values))
            .then(registerType => dispatch(registerTypeLoaded(registerType)))
    }
}

export function deleteRegisterType(id) {
    return dispatch => {
        return dispatch(deleteUrl(`/register-types/${id}`))
            .then(() => dispatch(unloadRegisterType(id)))
    }
}

export function createRegisterType(values) {
    return dispatch => {
        return dispatch(postUrl('/register-types', values))
            .then(registerType => {
                dispatch(registerTypeLoaded(registerType));
                return registerType;
            })
            .catch(error => dispatch(handleApiError(error)));
    }
}

export const loadRegisterType = (id) => {
    return dispatch => {
        return dispatch(getUrl(`/register-types/${id}`))
            .then(registerType => dispatch(registerTypeLoaded(registerType)))
            .catch(error => handleFetchError(error));
    }
};

export default handleActions({
    [GLOBAL_RESET_ACTION]: () => initState,
    [REGISTER_TYPE_LOADED]: (state, {payload: {registerType}}) => Object.assign({}, state, {[registerType.id]: registerType}),
    [REGISTER_TYPE_UNLOADED]: (state, {payload: {id}}) => Object.assign({}, delete state[id])
}, initState);


