import React from 'react';
import PropTypes from "prop-types";
import DashboardContainer from "./DashboardContainer";
import SystemStatusPanel from "../system/SystemStatusPanel";

const SystemStatusDashboard = ({dashboard}) => {
    return (
        <DashboardContainer
            title={dashboard.name}
            id={dashboard.id}>
            <SystemStatusPanel/>
        </DashboardContainer>
    )
}

SystemStatusDashboard.propTypes = {
    dashboard: PropTypes.object.isRequired
};

export default SystemStatusDashboard;
