import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import RegisterTypeEdit from "../../panels/registers/RegisterTypeEdit";
import {deleteRegisterType, loadRegisterType} from "../../../reducers/registerTypes/map";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import {handleApiError, MESSAGE_SEVERENITY, newMessage} from "../../../reducers/messages";
import DeleteConfirmModal from "../../../components/modals/DeleteConfirmModal";
import {ThemeProvider} from "@mui/material";
import {defaultMarcoTheme} from "../../../theme";


const RegisterTypePage = () => {
    const {id} = useParams();
    const history = useHistory();

    const registerType = useSelector(state => state.registerTypes.map[id]);
    const dispatch = useDispatch();
    const [deleteConfirm, setDeleteConfirm] = useState({open: false, items: []});


    const loadData = async () => {
        await dispatch(loadRegisterType(id));
    }

    useEffect(() => {
        loadData();
    }, [id, dispatch]);

    if (!id) {
        history.push('/registers/types');
    }

    if (!registerType) {
        return (<CircularProgress/>)
    }

    const showDeleteConfirm = () => setDeleteConfirm({open: true, items:[registerType.name]});
    const hideDeleteConfirm = () => setDeleteConfirm({open: false, items: []});

    const handleDelete = async (_e) => {
        try {
            await dispatch(deleteRegisterType(id));
            dispatch(newMessage(MESSAGE_SEVERENITY.SUCCESS, "Typ registru smazán"));
            history.push('/registers/types');
        } catch (e) {
            dispatch(handleApiError(e))
        } finally {
            hideDeleteConfirm();
        }
    }

    return (
        <ThemeProvider theme={defaultMarcoTheme}>
            <Grid container direction={"column"}>
                <Grid container direction={"row"} alignContent={"space-around"}>
                    <Grid item xs={10}>
                        <Typography variant="h4" component="h1" gutterBottom>
                            Typ registru: {registerType.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            onClick={showDeleteConfirm}
                            variant="contained"
                            color="secondary"
                            startIcon={<DeleteIcon/>}>
                            Smazat
                        </Button>
                    </Grid>
                </Grid>
                <RegisterTypeEdit registerType={registerType}/>
                <DeleteConfirmModal
                    handleConfirm={handleDelete}
                    items={deleteConfirm.items}
                    handleClose={hideDeleteConfirm}
                    open={deleteConfirm.open}/>
            </Grid>
        </ThemeProvider>
    );
}

export default RegisterTypePage;
