import moment from "moment";
import {isNumber} from "recharts/lib/util/DataUtils";
import roundTo from "round-to";
import formatNumber from "format-number";

export function formatDate(date) {
    if (!date) {
        return 'N/A';
    }
    return moment.utc(date).local().format('YYYY-MM-DD HH:mm:ss');
}


export function formatRegisterValue(unit, value, precision=3) {
    if (isNumber(value)) {
        const formatter = formatNumber({suffix: unit ? ' ' + unit : '', round: precision, integerSeparator: " "});
        return formatter(value)
    }
    return value;
}


export function formatPercent(value) {
    if (isNumber(value)) {
        return `${roundTo(value, 2)} %`
    }
    return value;
}