import {createAction, handleActions} from 'redux-actions';
import {getUrl} from "../../utils/api";
import {handleFetchError} from "../messages";
import {LOAD_STATE} from "../../constants";
import {GLOBAL_RESET_ACTION} from "../global";

const OBJECTS_LIST_LOADED = 'OBJECTS_LIST_LOADED';
const initState = {
    loadState: LOAD_STATE.PENDING,
    content: []
};

export const objectsLoaded = createAction(OBJECTS_LIST_LOADED, page => page);

export function loadObjectsList() {
    return dispatch => {
        return dispatch(getUrl('/objects'))
            .then(objects => dispatch(objectsLoaded(objects)))
            .catch(error => dispatch(handleFetchError(error)));
    }
}

export default handleActions({
    [GLOBAL_RESET_ACTION]: () => initState,
    [OBJECTS_LIST_LOADED]: (state, {payload}) => Object.assign({}, state, {
        content: payload, loadState: LOAD_STATE.LOADED
    })
}, initState);
