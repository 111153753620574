import {combineReducers} from "redux";
import list from "./list";
import map from "./map";
import plcs from "./plcs";

export default combineReducers({
    list,
    map,
    plcs
});
