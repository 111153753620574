import React from 'react';
import UserAccessesGrid from "../../panels/users/UserAccessesGrid";

const UsersAccessPage = () => {
    return (
        <UserAccessesGrid/>
    );
}

export default UsersAccessPage;
