import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {LOAD_STATE, ROLE} from "../../../constants";
import CircularProgress from "@mui/material/CircularProgress";
import MaterialTable from "material-table";
import Paper from "@mui/material/Paper";
import {loadObjectsList} from "../../../reducers/objects/list";
import localized from "../../../utils/localized";
import * as R from "rambda";
import {OBJECT_STATE} from "../../../reducers/objects/constants";
import {hasRoleSelector} from "../../../selectors/authSelectors";
import {createTheme, ThemeProvider} from "@mui/material";
import {defaultMarcoTheme} from "../../../theme";


const ObjectsGrid = () => {
    const {loadState, content} = useSelector(state => state.objects.list);
    const hasEditRole = useSelector(hasRoleSelector(ROLE.OBJECTS_EDIT));
    const dispatch = useDispatch();
    const history = useHistory();

    const handleEdit = (_event, registerType) => {
        history.push(`/objects/${registerType.id}/edit`);
    };

    const handleCreate = () => {
        history.push(`/objects/create`);
    }

    const createEditActions = () => [{
        icon: 'edit',
        position: "row",
        tooltip: 'Editovat',
        onClick: handleEdit
    }, {
        icon: 'add',
        tooltip: 'Přidat',
        position: "toolbar",
        onClick: handleCreate
    }];

    useEffect(() => {
        dispatch(loadObjectsList());
    }, [dispatch]);


    if (loadState === LOAD_STATE.PENDING) {
        return (
            <CircularProgress/>
        )
    }

    if (!content) {
        return null;
    }

    const theme = createTheme();

    return (
        <ThemeProvider theme={theme}>
            <MaterialTable
                options={{
                    filtering: true,
                    cellStyle: {padding: '0.2em'},
                    headerStyle: {padding: '0.3em'},
                    pageSize: 15,
                    pageSizeOptions: [15, 30, 100],
                    rowStyle: (object, index) => {
                        return {
                            backgroundColor: index % 2 ? "white" : "aliceBlue",
                            color: OBJECT_STATE.INACTIVE === object.state ? "gray" : "inherited"
                        }
                    }
                }}
                actions={hasEditRole ? createEditActions() : []}
                components={{
                    Container: props => <Paper {...props} elevation={0}/>
                }}
                columns={[
                    {
                        title: 'Název',
                        field: 'name',
                        render: object => (<Link to={`/objects/${object.id}`}>{object.name}</Link>)
                    },
                    {title: 'Společnost', field: 'company.name'},
                    {title: 'Město', field: 'city'},
                    {
                        title: 'Stav',
                        field: 'state',
                        render: object => localized.getString(object.state),
                        lookup: R.map(state => localized.getString(state), OBJECT_STATE)
                    }
                ]}
                data={content}
                title="Objekty"
            />
        </ThemeProvider>
    )
}

export default ObjectsGrid;
