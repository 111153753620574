import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import {useDispatch, useSelector} from "react-redux";
import {deleteRegisters, loadRegister, unloadRegister} from "../../../reducers/registers/map";
import Typography from "@mui/material/Typography";
import RegisterEdit from "../../panels/registers/RegisterEdit";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import {handleApiError, MESSAGE_SEVERENITY, newMessage} from "../../../reducers/messages";
import DeleteConfirmModal from "../../../components/modals/DeleteConfirmModal";


const RegisterEditPage = () => {
    const {id} = useParams();
    const history = useHistory();
    const register = useSelector(state => state.registers.map[id]);
    const dispatch = useDispatch();
    const [deleteConfirm, setDeleteConfirm] = useState({open: false, items: []});

    const loadData = async () => {
        await dispatch(loadRegister(id));
    }

    useEffect(() => {
        loadData();
        return () => dispatch(unloadRegister(id));
    }, [id]);

    if (!id) {
        history.push('/registers');
    }

    if (!register) {
        return (<CircularProgress/>)
    }

    const showDeleteConfirm = () => setDeleteConfirm({open: true, items:[register.name]});
    const hideDeleteConfirm = () => setDeleteConfirm({open: false, items: []});

    const handleDelete = async (_e) => {
        try {
            await dispatch(deleteRegisters([id]))
            dispatch(newMessage(MESSAGE_SEVERENITY.SUCCESS, `Registr "${register.name}" smazán.`));
            history.push("/registers");
        } catch (e) {
            dispatch(handleApiError(e));
        } finally {
            hideDeleteConfirm();
        }
    };

    return (
        <Grid container direction={"column"}>
            <Grid container direction={"row"} alignContent={"space-around"}>
                <Grid item xs={10}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Registr: {register.name}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Button
                        onClick={showDeleteConfirm}
                        variant="contained"
                        color="secondary"
                        startIcon={<DeleteIcon/>}>
                        Smazat
                    </Button>
                </Grid>
            </Grid>

            <RegisterEdit register={register}/>
            <DeleteConfirmModal
                handleConfirm={handleDelete}
                items={deleteConfirm.items}
                handleClose={hideDeleteConfirm}
                open={deleteConfirm.open}/>
        </Grid>
    );
}

export default RegisterEditPage;
