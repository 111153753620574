import React from 'react';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import * as PropTypes from "prop-types";

const DeleteConfirmModal = ({open, items, handleClose, handleConfirm}) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description">
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    Opravdu chcete odstranit?
                    <ul>
                        {items.map(item => (<li>{item}</li>))}
                    </ul>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm} color="primary" variant={"contained"}>
                    OK
                </Button>
                <Button onClick={handleClose} color="secondary" variant={"contained"}>
                    Storno
                </Button>
            </DialogActions>
        </Dialog>
    )
}


DeleteConfirmModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired
}

export default DeleteConfirmModal;