import {createAction, handleActions} from "redux-actions";
import {deleteUrl, getUrl, postUrl} from "../../utils/api";
import {GLOBAL_RESET_ACTION} from "../global";

const USER_OBJECTS_LOADED = "USER_OBJECTS_LOADED";
const USER_OBJECTS_UNLOADED = "USER_OBJECTS_UNLOADED";

const userObjectsLoaded =  (userId, objects) => createAction(USER_OBJECTS_LOADED) ({userId, objects});
export const unloadUserObjects = userId => createAction(USER_OBJECTS_UNLOADED)({userId});

export const loadUserObjects = userId => {
    return dispatch => dispatch(getUrl(`/users/${userId}/objects`))
        .then(objects => dispatch(userObjectsLoaded(userId, objects)));
}

export const addUserObjects = (userId, objectIds) => {
    return dispatch => dispatch(postUrl(`/users/${userId}/objects`, objectIds))
        .then(objects => dispatch(userObjectsLoaded(userId, objects)))
}

export const deleteUserObjects = (userId, userObjectsIds) => {
    return dispatch => dispatch(deleteUrl(`/users/${userId}/objects`, {userObjectsIds}))
        .then(objects => dispatch(userObjectsLoaded(userId, objects)));
}

export default handleActions({
        [GLOBAL_RESET_ACTION]: () => {},
        [USER_OBJECTS_LOADED]: (state, {payload: {userId, objects}}) => Object.assign({}, state, {[userId]: objects}),
        [USER_OBJECTS_UNLOADED]: (state, {payload: {userId}}) => Object.assign({}, delete state[userId])
    }, {}
)