export const ROLE = {
    OBJECTS_EDIT: "OBJECTS_EDIT",
    USERS_EDIT: "USERS_EDIT",
    ADMIN: "ADMIN"
};

export const LOAD_STATE = {
    LOADED: 'LOADED',
    ERROR: 'ERROR',
    PENDING: 'PENDING'
}

export const METRIC_VIZUALIZATION = {
    GAUGE: 'GAUGE',
    INCREMENTS: 'INCREMENTS'
}

export const METRIC_INTERVAL = {
    QUARTER_AN_HOUR: 'QUARTER_AN_HOUR',
    HOURLY: 'HOURLY',
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY'
}
