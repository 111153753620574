import {Form} from "react-final-form";
import Grid from "@mui/material/Grid";
import {required} from "../../utils/validators";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import React from "react";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import {Select, TextField} from "mui-rff";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((_theme) => ({
    formControl: {
        minWidth: 180
    },
    button: {
        margin: 3
    }
}));

const RegistersDashboardForm = ({onSubmit, initialValues, onClose}) => {
    const classes = useStyles();
    return (
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <Grid container direction={"row"} alignItems={"flex-start"}>
                        <Grid container direction={"column"} alignItems={"flex-start"}>
                            <TextField
                                required
                                margin="dense"
                                validate={required}
                                label="Název"
                                name="name"
                                className="input"
                                type="text"/>
                            <Select
                                required
                                margin="dense"
                                validate={required}
                                className={classes.formControl}
                                name="metricVisualization"
                                label="Typ metrik">
                                    <MenuItem value="GAUGE">GAUGE</MenuItem>
                                    <MenuItem value="INCREMENTES">INCREMENTS</MenuItem>
                            </Select>
                            <Select
                                required
                                margin="dense"
                                validate={required}
                                name="metricInterval"
                                label="Typ metrik">
                                <MenuItem value="HOURLY">HOURLY</MenuItem>
                                <MenuItem value="DAILY">DAILY</MenuItem>
                                <MenuItem value="WEEKLY">WEEKLY</MenuItem>
                                <MenuItem value="QUARTER_AN_HOUR">QUARTER_AN_HOUR</MenuItem>
                            </Select>
                            <Grid item>
                                <Button
                                    className={classes.button}
                                    type={"submit"}
                                    startIcon={<SaveIcon/>}
                                    variant="contained"
                                    color="primary">
                                    Uložit
                                </Button>
                                <Button
                                    onClick={() => onClose()}
                                    className={classes.button}
                                    type={"button"}
                                    variant="contained"
                                    color="secondary">
                                    Zavřít
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            )}/>
    );
}

RegistersDashboardForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
        name: PropTypes.string,
        metricVisualization: PropTypes.string,
        metricInterval: PropTypes.string
    })
}

export default RegistersDashboardForm;
