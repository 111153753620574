import React from 'react';
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import {METRIC_INTERVAL, METRIC_VIZUALIZATION} from "../constants";
import {keys} from "rambda";
import localized from "../utils/localized";
import {Grid, ThemeProvider} from "@mui/material";
import {defaultMarcoTheme} from "../theme";

const MetricOptions = ({selectedVizualization, onVizualizationSelected, selectedInterval, onIntervalSelected}) => {
    const IntervalOptions = () => {
        return <ButtonGroup size="small" color="secondary">
            {keys(METRIC_INTERVAL).map(interval => (
                <Button
                    onClick={() => onIntervalSelected(interval)}
                    key={interval}
                    variant={selectedInterval === interval ? "contained" : "outlined"}
                    aria-selected={"true"}>
                    {localized.getString(interval)}
                </Button>
            ))}
        </ButtonGroup>;
    }

    const VizualizationOptions = () => {
        return (
            <ButtonGroup size={"small"}>
                {keys(METRIC_VIZUALIZATION).map(vizualization => (
                    <Button
                        onClick={() => onVizualizationSelected(vizualization)}
                        key={vizualization}
                        variant={selectedVizualization === vizualization ? "contained" : "outlined"}
                        aria-selected={"true"}>
                        {localized.getString(vizualization)}
                    </Button>
                ))}
            </ButtonGroup>
        );
    }

    return (
        <ThemeProvider theme={defaultMarcoTheme}>
            <Grid container direction="row">
                <Grid item>
                    <VizualizationOptions/>
                </Grid>
                    {selectedVizualization === METRIC_VIZUALIZATION.INCREMENTS && (
                        <Grid item>
                            <IntervalOptions/>
                        </Grid>
                    )}
            </Grid>
        </ThemeProvider>
    );
}

export default MetricOptions;
