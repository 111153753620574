import React, {useEffect} from "react";
import {
    CircularProgress,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {loadSystemStatus} from "../../../reducers/system/status";


const SystemStatusPanel = () => {
    const systemStatus = useSelector(state => state.system.status);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadSystemStatus());
    }, [dispatch]);

    if (!systemStatus) {
        return (<CircularProgress />)
    }

    return (
        <Grid container direction={"row"}>
            <Grid md={8} item>
                <TableContainer>
                    <Table size={"small"}>
                        <TableHead>
                            <TableRow>
                                <TableCell component={"th"}>Společnost</TableCell>
                                <TableCell component={"th"} align={"right"}>Registrů</TableCell>
                                <TableCell component={"th"} align={"right"}>Objektů</TableCell>
                                <TableCell component={"th"} align={"right"}>Uživatelů</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {systemStatus.companies.map((companyStats, index) => {
                                const rowStyle = {backgroundColor: index % 2 ? "white" : "aliceBlue"};

                                return (
                                    <TableRow style={rowStyle}>
                                        <TableCell align={"left"}>{companyStats.company.name}</TableCell>
                                        <TableCell align={"right"}>{companyStats.activeRegistersCount}</TableCell>
                                        <TableCell align={"right"}>{companyStats.activeObjectsCount}</TableCell>
                                        <TableCell align={"right"}>{companyStats.activeUsersCount}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid md={4} item>
                <TableContainer style={{marginLeft:10}}>
                    <Table size={"small"}>
                        <TableBody>
                            <TableRow style={{backgroundColor: "aliceBlue"}}>
                                <TableCell component={"th"}>Počet notifikací</TableCell>
                                <TableCell align={"right"}>{systemStatus.notificationsCount}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component={"th"}>Nedostupných registrů</TableCell>
                                <TableCell align={"right"}>{systemStatus.unreachableRegistersCount}</TableCell>
                            </TableRow>
                            <TableRow style={{backgroundColor: "aliceBlue"}}>
                                <TableCell component={"th"}>Záznamů za minutu</TableCell>
                                <TableCell align={"right"}>{systemStatus.minuteRecordsRate}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}

export default SystemStatusPanel;