import React, {useEffect, useState} from "react";
import Paper from "@mui/material/Paper";
import {formatDate, formatRegisterValue} from "../../../utils/formatters";
import MaterialTable from "material-table";
import {useDispatch} from "react-redux";
import {
    loadOutOfRangeEventsPage,
    OUT_OF_RANGE_STATE,
    OUT_OF_RANGE_TYPE,
    unloadOutOfRangeEventsPage
} from "../../../reducers/registers/events/outOfRangePage";
import {handleApiError} from "../../../reducers/messages";
import {red} from "@mui/material/colors";
import green from "@mui/material/colors/green";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import localized from "../../../utils/localized";
import * as R from "rambda";
import {isNil, mergeAll} from "rambda";
import RegistersGraphBoardModal from "../modals/RegistersGraphBoardModal";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {defaultMarcoTheme} from "../../../theme";
import {ThemeProvider} from "@mui/material";

const renderState = event => {
    let icon = 'Ok';
    let style = null;

    switch(event.state) {
        case OUT_OF_RANGE_STATE.OPEN:
            icon = 'warning';
            style={color: red[500]};
            break;

        case OUT_OF_RANGE_STATE.CLOSED:
            icon = 'check'
            style={color: green[500]};
            break;
    }

    return (
        <Grid container direction="row" alignItems="center">
            <Icon style={style}>{icon}</Icon>
            {localized.getString(event.state)}
        </Grid>
    );
}

const OutOfRangeEventsGrid = ({filterMode, displayMode, registerId}) => {
    const [graph, setGraph] = useState({shown: false, registerIds: []});
    const dispatch = useDispatch();

    const handleShowGraph = (_event, outOfRangeEvent) => {
        setGraph({shown: true, registerIds: [outOfRangeEvent.register.id]});
    }
    const handleCloseGraph = () => setGraph(Object.assign({}, graph, {shown: false}));

    const getData = async (query) => {
        const {pageSize, page, filters} = query;
        const filterMap = {
            "register.object": "objectName",
            "register.name": "registerName",
            "register.type": "registerType"
        };

        const apiFilters = mergeAll(filters.map(({value, column: {field}}) => {
            return {[filterMap[field] || field]: value}
        }));

        if (filterMode === 'active') {
            apiFilters['state'] = OUT_OF_RANGE_STATE.OPEN
        }

        if (!isNil(registerId)) {
            apiFilters['registerId'] = registerId;
        }

        try {
            const fetchedPage = await dispatch(loadOutOfRangeEventsPage(page, pageSize, apiFilters));
            return ({
                data: fetchedPage.content,
                page,
                totalCount: fetchedPage.totalElements
            });
        } catch (e) {
            dispatch(handleApiError(e));
        }

        return ({
            data: [],
            page,
            totalCount: 0
        });
    };

    useEffect(() => {
        return () => dispatch(unloadOutOfRangeEventsPage());
    },[dispatch]);

    return (
        <ThemeProvider theme={defaultMarcoTheme}>
            <MaterialTable
                actions={[{
                    tooltip: 'Graf',
                    icon: 'timeline',
                    position: "row",
                    onClick: handleShowGraph
                }]}
                options={{
                    toolbar:false,
                    showTitle: displayMode === "detailed",
                    search: displayMode === "detailed",
                    filtering: displayMode === "detailed",
                    sorting: false,
                    cellStyle: { padding: '0.3em'},
                    headerStyle: { padding: '0.3em'},
                    pageSize: displayMode !== "basic" ? 15 : 7,
                    pageSizeOptions: displayMode !== "basic" ? [15, 30, 100] : [7],
                    rowStyle: (_object, index) => {
                        return {
                            backgroundColor: index % 2 ? "white" : "aliceBlue",
                        }
                    }
                }}
                components={{
                    Container: props => <Paper {...props} elevation={0}/>
                }}
                columns={[
                    {
                        title: 'Název',
                        field: 'register.name',
                        render: ({register: {id, name}}) => (<Link to={`/registers/${id}`}>{name}</Link>)
                    },
                    {title: 'Typ', field: 'register.type', hidden: displayMode === "basic"},
                    {
                        title: 'Stav',
                        field: 'state',
                        filtering: true,
                        defaultFilter: [OUT_OF_RANGE_STATE.OPEN],
                        hidden: filterMode === "active",
                        render: renderState,
                        lookup: R.map(state => localized.getString(state), OUT_OF_RANGE_STATE)
                    },
                    {
                        hidden: displayMode === 'singleRegister',
                        title: 'Objekt',
                        field: 'register.objectName',
                        render: ({register: {objectId, objectName}}) => (
                            <Link to={`/objects/${objectId}`}>{objectName}</Link>
                        )
                    },
                    {
                        title: 'Hodnota',
                        field: 'value',
                        align: 'right',
                        filtering: false,
                        render: ({value, register, outOfRange}) => {
                            const title = `Min-max:  ${register.minValue} - ${register.maxValue} ${register.unit}`;
                            let icon = null;
                            if (OUT_OF_RANGE_TYPE.MAX === outOfRange) {
                                icon = 'north';
                            }

                            if (OUT_OF_RANGE_TYPE.MIN === outOfRange) {
                                 icon = 'south';
                            }
                            return (
                                <span>
                                    {formatRegisterValue(register.unit, value)}
                                    <Icon title={title} style={{color: red[500]}}>{icon}</Icon>
                                </span>
                            )
                        }
                    },
                    {title: 'Od', field: 'beginDateTime', filtering: false, render: ({beginDateTime}) => formatDate(beginDateTime)},
                    {title: 'Do', field: 'endDateTime', filtering: false, render: ({endDateTime}) => formatDate(endDateTime), hidden: filterMode === "active"}
                ]}
                data={getData}
                title="Poruchy"
            />

            {graph.shown && (
                <RegistersGraphBoardModal
                    registerIds={graph.registerIds}
                    dataKey={"outOfRangeEvents"}
                    handleClose={handleCloseGraph}/>
            )}
        </ThemeProvider>
    );
}

OutOfRangeEventsGrid.propTypes = {
    filterMode: PropTypes.oneOf(["active", "all"]),
    displayMode: PropTypes.oneOf(["detailed", "basic", "singleRegister"]),
    registerId: PropTypes.number
}

OutOfRangeEventsGrid.defaultProps = {
    displayMode: "detailed",
    filterMode: "all",
    registerId: null,
}

export default OutOfRangeEventsGrid;
