export const VALUE_TYPE = {
    INT: "INT",
    LONG: "LONG",
    FLOAT: "FLOAT",
    BYTE: "BYTE",
    STRING: "STRING",
    DWORD: "DWORD",
    WORD: "WORD",
    BIT: "BIT"
};

export const REGISTER_REPORT_TYPE = {
    DUMMY: "DUMMY",
    AVG: "AVG",
    SUM: "SUM",
    NONE: "NONE",
    ALARM: "ALARM"
};

export const REGISTER_READ_STATE = {
    ONLINE: "ONLINE",
    ERROR: "ERROR"
};

