import {combineReducers} from "redux";

import values from "./values";
import map from "./map";
import statistics from "./statistics";
import states from "./states";
import events from "./events";
import registersPage from "./registersPage";

export default combineReducers({
    map,
    registersPage,
    values,
    statistics,
    states,
    events
})
