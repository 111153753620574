import React from "react";
import {Field, Form} from "react-final-form";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import PropTypes from "prop-types";
import {required} from "../../utils/validators";
import {TextField} from "mui-rff";
import {makeStyles} from "@mui/styles";


const PlcForm = ({onSubmit, initialValues}) => {
    return (
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <Grid container direction={"row"} alignItems={"flex-start"}>
                        <Grid container direction={"column"} alignItems={"flex-start"} spacing={1} xs={3}>
                            <TextField
                                required
                                validate={required}
                                label="Ip adresa"
                                name="ipAddress"
                                margin="dense"/>
                            <TextField
                                required
                                validate={required}
                                label="Port"
                                name="port"
                                margin="dense"/>
                        </Grid>
                    </Grid>
                    <Button
                        type={"submit"}
                        startIcon={<SaveIcon/>}
                        variant="contained"
                        color="primary">
                        Uložit
                    </Button>
                </form>
            )}/>
    );
}

PlcForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
        ipAddress: PropTypes.string,
        port: PropTypes.number
    })
}

export default PlcForm;
