import {stringify} from "querystring";
import {logout, refreshTokens, selectRefreshToken, selectTokenData} from "../reducers/auth";

export const JSON_RESPONSE = "JSON_RESPONSE";
export const BLOB_RESPONSE = "BLOB_RESPONSE";
export const NONE_RESPONSE = "NONE_RESPONSE";

function handleErrors(dispatch, response) {
    if (response.status === 401) {
        console.warn("Http status code 401");
        dispatch(logout());
        return Promise.reject(response.text);
    }


    if (!response.ok) {
        return response.json()
            .then(body => Promise.reject(Error(body.message)));
    }
    return response;
}

function _conf(token, attrs, headers) {
    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    return {
        headers,
        cache: 'no-cache',
        ...attrs
    };
}

function _fetch(url, attrs, params, responseType, requestType = JSON_RESPONSE, checkTokens = true) {
    url = process.env.REACT_APP_API_URL + url;
    if (params) {
        url += `?${stringify(params)}`;
    }

    const headers = requestType === BLOB_RESPONSE ? {} : {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    return async (dispatch, getState) => {
        const {token, exp} = selectTokenData(getState());
        if (checkTokens && (!token || Date.now() >= exp * 1000)) {
            const refreshToken = selectRefreshToken();
            if (refreshToken) {
                await dispatch(refreshTokens(refreshToken));
            }
        }

        const config = _conf(token, attrs, headers);
        return fetch(url, config)
            .then(res => handleErrors(dispatch, res))
            .then(res => {
                if (responseType === JSON_RESPONSE) {
                    return res.json();
                }

                if (responseType === BLOB_RESPONSE) {
                    return res.blob();
                }

                return null;
            });
    }
}

export function postUrlFile(url,  file, params) {
    const formData = new FormData();
    formData.append('file', file);
    const attrs = {
        method: 'post',
        body: formData
    };

    return _fetch(url, attrs, params, JSON_RESPONSE, BLOB_RESPONSE);
}

export function getUrl(url, params) {
    return _fetch(url, null, params, JSON_RESPONSE);
}

export function getBlobUrl(url, params) {
    return _fetch(url, null, params, BLOB_RESPONSE);
}

export function postUrl(url, data, params, checkTokens = true) {
    const attrs = {
        method: 'post',
        body: JSON.stringify(data)
    };

    return _fetch(url, attrs, params, JSON_RESPONSE, JSON_RESPONSE, checkTokens);
}

export function putUrl(url, data, params, responseType= JSON_RESPONSE) {
    const attrs = {
        method: 'put',
        body: JSON.stringify(data)
    };
    return _fetch(url, attrs, params, responseType);
}

export function patchUrl(url, data, params) {
    const attrs = {
        method: 'patch',
        body: stringify(data)
    };
    return _fetch(url, attrs, params);
}

export function deleteUrl(url, params) {
    const attrs = {
        method: 'delete'
    };
    return _fetch(url, attrs, params, NONE_RESPONSE);
}
