import React from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ChangePasswordForm from "../../../components/forms/users/ChangePasswordForm";
import {useDispatch} from "react-redux";
import {changePassword} from "../../../reducers/settings/profile";
import {handleApiError, MESSAGE_SEVERENITY, newMessage} from "../../../reducers/messages";

const PasswordChangePage = () => {
    const dispatch = useDispatch();

    const handleChangeSubmit = async ({password}) => {
        try {
            await dispatch(changePassword(password))
            dispatch(newMessage(MESSAGE_SEVERENITY.SUCCESS, "Heslo změněno"));
        } catch (error) {
            dispatch(handleApiError(error));
        }
    }

    return (
        <Grid container direction={"column"}>
            <Grid item>
                <Typography variant="h4" component="h1" gutterBottom>
                    Změna hesla
                </Typography>
            </Grid>
            <Grid item>
                <ChangePasswordForm onSubmit={handleChangeSubmit}/>
            </Grid>
        </Grid>
    );
}

export default PasswordChangePage;