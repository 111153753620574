import {createAction, handleActions} from 'redux-actions';
import {getUrl} from "../../../utils/api";
import {GLOBAL_RESET_ACTION} from "../../global";

const LOADED = 'OUT_OF_RANGE_EVENTS_PAGE_LOADED';
const UNLOADED = 'OUT_OF_RANGE_EVENTS_PAGE_UNLOADED';

export const OUT_OF_RANGE_STATE = {
    OPEN: 'OPEN',
    CLOSED: 'CLOSED'
};

export const OUT_OF_RANGE_TYPE = {
    MAX: 'MAX',
    MIN: 'MIN'
};

function loaded(registers) {
    return createAction(LOADED)({registers});
}

export function loadOutOfRangeEventsPage(page, size, filter) {
    return async dispatch => {
        const pageResponse = await dispatch(getUrl('/registers/events/out-of-range', {page, size, ...filter}));
        dispatch(loaded(pageResponse));
        return pageResponse;
    }
}

export function unloadOutOfRangeEventsPage() {
    return dispatch => dispatch(createAction(UNLOADED)());
}

export default handleActions({
    [GLOBAL_RESET_ACTION]: () => [],
    [LOADED]: (state, {payload: {registers}}) => registers,
}, []);