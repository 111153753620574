import {createAction, handleActions} from 'redux-actions';
import {getUrl} from "../../utils/api";
import {handleFetchError} from "../messages";
import {LOAD_STATE} from "../../constants";
import {GLOBAL_RESET_ACTION} from "../global";

const PLCS_LIST_LOADED = 'PLCS_LIST_LOADED';
const PLCS_LIST_LOAD_ERROR = 'PLCS_LIST_LOAD_ERROR';

const initState = {
    loadState: LOAD_STATE.PENDING,
    content: []
};

export const plcsListLoaded = plcs => {
    return createAction(PLCS_LIST_LOADED)({plcs});
}

const loadError = error => {
    return dispatch => {
        dispatch(handleFetchError(error));
        dispatch(createAction(PLCS_LIST_LOAD_ERROR)({error}));
    }
}

export function loadPlcsList() {
    return dispatch => {
        return dispatch(getUrl('/plcs'))
            .then(plcs => dispatch(plcsListLoaded(plcs)))
            .catch(error => dispatch(loadError(error)));
    }
}

export default handleActions({
    [GLOBAL_RESET_ACTION]: () => initState,
    [PLCS_LIST_LOADED]: (state, {payload: {plcs}}) => Object.assign({}, state, {
        content: plcs, loadState: LOAD_STATE.LOADED
    }),
    [PLCS_LIST_LOAD_ERROR]: state => Object.assign({}, initState, {loadState: LOAD_STATE.ERROR})
}, initState);
