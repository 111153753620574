import {createAction, handleActions} from 'redux-actions';
import {getUrl} from "../../../utils/api";
import {LOAD_STATE} from "../../../constants";

const OUT_OF_RANGE_COUNT_LOADED = 'OUT_OF_RANGE_COUNT_LOADED';
const OUT_OF_RANGE_COUNT_UNLOADED = 'OUT_OF_RANGE_COUNT_UNLOADED';
const OUT_OF_RANGE_COUNT_PENDING = 'OUT_OF_RANGE_COUNT_PENDING';


function loaded(count) {
    return createAction(OUT_OF_RANGE_COUNT_LOADED)({count, loadDate: Date()})
}

export function loadOutOfRangeCount() {
    return async dispatch => {
        const {count} = await dispatch(getUrl('/registers/events/out-of-range/count'));
        dispatch(loaded(count));
    }
}

export default handleActions({
    [OUT_OF_RANGE_COUNT_PENDING]: state => Object.assign({}, state, {loadState: LOAD_STATE.PENDING}),
    [OUT_OF_RANGE_COUNT_LOADED]: (state, {payload: {loadDate, count}}) => Object.assign({},{count, loadDate, loadState: LOAD_STATE.LOADED}),
    [OUT_OF_RANGE_COUNT_UNLOADED]: () => null
}, null);