import React, {useEffect} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {changeUserPassword, loadUser} from "../../../reducers/users/map";
import CircularProgress from "@mui/material/CircularProgress";
import ChangePasswordForm from "../../../components/forms/users/ChangePasswordForm";
import {handleApiError, MESSAGE_SEVERENITY, newMessage} from "../../../reducers/messages";

const UserChangePasswordPage = () => {
    const {id} = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!user) {
            dispatch(loadUser(id));
        }
    }, [id, dispatch, loadUser]);

    const user = useSelector(state => state.users.map[id]);
    const handleChangePassword = async ({password}) => {
        try {
            await dispatch(changeUserPassword(id, password));
            dispatch(newMessage(MESSAGE_SEVERENITY.SUCCESS, "Heslo změněno"));
        } catch (error) {
            dispatch(handleApiError(error));
        }

    }

    if (!user) {
        return (
            <div>
                <CircularProgress />
            </div>
        )
    }

    return (
        <Grid container direction={"column"}>
            <Typography variant="h4" component="h1" gutterBottom>
                Uživatel: {user.username}
            </Typography>
            <ChangePasswordForm onSubmit={handleChangePassword}/>
        </Grid>
    );
}


export default UserChangePasswordPage;