import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import IconButton from "@mui/material/IconButton";
import {AccountCircle} from "@mui/icons-material";
import {useDispatch} from "react-redux";
import {logout} from "../reducers/auth";
import {useHistory} from "react-router-dom";

const  AccountMenu = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLogout = () => {
        dispatch(logout())
            .then(() => history.push("/"));
    };

    const handleSettings = () => {
        history.push("/settings");
        setAnchorEl(null);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                size="large">
                <AccountCircle  onClick={handleClick}/>
            </IconButton>
            <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <MenuItem onClick={handleSettings}>Nastavení</MenuItem>
                <MenuItem onClick={handleLogout}>Odhlásit</MenuItem>
            </Menu>
        </div>
    );
};

export default AccountMenu;

