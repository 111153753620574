import React, {useEffect, useState} from "react";
import {loadRegister, unloadRegister} from "../../../reducers/registers/map";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {isNil} from "rambda";
import RegistersGraph from "../../panels/registers/RegistersGraph";
import moment from "moment";
import HoursDateTimeFilter, {defaultHours} from "../../../components/HoursDateTimeFilter";
import RegistersStatistics from "../../panels/registers/RegistersStatistics";
import {METRIC_VIZUALIZATION} from "../../../constants";
import {handleApiError} from "../../../reducers/messages";

const initState = () => {
    return {
        from: moment().subtract(defaultHours, 'hours').format(),
        to: moment().format(),
        hoursSelected: defaultHours
    }
};

const RegisterPage = () => {
    const {id} = useParams();
    const history = useHistory();
    const register = useSelector(state => state.registers.map[id]);
    const dispatch = useDispatch();

    const loadData = async () => {
        if (!isNil(id)) {
            await dispatch(loadRegister(id));
        }
    }

    const [{from, to, hours}, setState] = useState(initState());
    const onHoursSelected = hours => {
        setState({
            from: moment().subtract(hours, 'hours').format(),
            to: moment().format(),
            hoursSelected: hours
        });
    }

    useEffect(() => {
        try {
            loadData();
        } catch (e) {
            dispatch(handleApiError(e))
        }
        return () => dispatch(unloadRegister(id));
    }, [id, dispatch]);

    if (isNil(id)) {
        history.push('/registers');
    }

    if (!register) {
        return (<CircularProgress/>)
    }


    return (
        <Grid container>
            <Grid container direction={"row"}>
                <Grid item md={8}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Registr: {register.object.name} - {register.name}
                    </Typography>
                </Grid>
                <Grid item md={4} alignContent={"flex-end"}>
                    <HoursDateTimeFilter selectedHours={hours} onHoursSelected={onHoursSelected}/>
                </Grid>
            </Grid>
            <RegistersGraph
                width={1200}
                to={to}
                registerIds={[id]}
                from={from}
                dataKey={id}
                vizualization={METRIC_VIZUALIZATION.GAUGE}
                label={register.name}/>
            <RegistersStatistics width={1200} to={to} registerIds={[id]} from={from} dataKey={register.name}/>
        </Grid>
    )
}

export default RegisterPage;