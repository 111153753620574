import React from 'react';
import ObjectEdit from "../../panels/objects/ObjectEdit";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const ObjectCreatePage = () => {
    return (
        <Grid container direction={"column"}>
            <Typography variant="h4" component="h1" gutterBottom>
                Nový objekt
            </Typography>
            <ObjectEdit/>
        </Grid>
    );
}

export default ObjectCreatePage;
