import React from "react";
import ObjectsGrid from "../../panels/objects/ObjectsGrid";

export default function ObjectsPage() {
    return (
        <div>
            <ObjectsGrid/>
        </div>
    );
}
