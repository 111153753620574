import {applyMiddleware, compose, createStore} from "redux";
import createRootReducer from "./reducers";
import {createBrowserHistory} from "history";
import {routerMiddleware} from "connected-react-router";
import thunk from "redux-thunk";
import {composeWithDevTools} from "redux-devtools-extension";
import logger from "redux-logger";
import {persistReducer, persistStore} from "redux-persist";
import storage from "redux-persist/lib/storage";
import reduxCatch from "redux-catch";
import {MESSAGE_SEVERENITY, newMessage} from "./reducers/messages";

const persistConfig = {
    key: 'root',
    storage,
};

const errorHandler = (error, getState, lastAction, dispatch) => {
    console.error(error);
    console.debug('last action was', lastAction);
    dispatch(newMessage(MESSAGE_SEVERENITY.ERROR, error.message));
};

export const history = createBrowserHistory();
const rootReducer = createRootReducer(history);
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
    persistedReducer,
    composeWithDevTools(compose(
        applyMiddleware(routerMiddleware(history)),
        applyMiddleware(thunk),
        applyMiddleware(logger),
        applyMiddleware(reduxCatch(errorHandler))
    ))
);

export const persistor = persistStore(store);
export default store;
