import {createAction, handleActions} from 'redux-actions';
import {getUrl} from "../../utils/api";
import {GLOBAL_RESET_ACTION} from "../global";
import {handleApiError} from "../messages";

const LOADED = 'REGISTERS_PAGE_LOADED';
const UNLOADED = 'REGISTERS_PAGE_UNLOADED';


export const REGISTER_ITEM_STATE = {
    INACTIVE: 'INACTIVE',
    OUT_OF_RANGE: 'OUT_OF_RANGE',
    ERROR: 'ERROR',
    OK: 'OK',
    MAINTENANCE: 'MAINTENANCE'
};

const pageLoaded = createAction(LOADED, registers => registers);
export const unloadRegistersPage = createAction(UNLOADED);

export function loadRegistersPage(page, size, filter, search, orderBy, orderDirection) {
    return async dispatch => {
        try {
            const pageResponse = await dispatch(getUrl('/registers/list', {page, size, search, orderBy, orderDirection,...filter}));
            dispatch(pageLoaded(pageResponse));
            return pageResponse;
        } catch (e) {
            dispatch(handleApiError(e));
        }
    }
}


export default handleActions({
    [GLOBAL_RESET_ACTION]: () => null,
    [UNLOADED]: () => null,
    [LOADED]: (state, {payload}) =>  payload,
}, null);
