import React, {useEffect} from 'react';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import {Link as RouterLink, useHistory} from "react-router-dom";
import {makeStyles} from '@mui/styles';
import Typography from "@mui/material/Typography";
import {HasAnyAuth} from "./HasAnyAuth";
import AccountMenu from "./AccountMenu";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import DashboardIcon from "@mui/icons-material/Dashboard";
import NotificationsIcon from '@mui/icons-material/Notifications';
import HasRole from "./HasRole";
import {LOAD_STATE, ROLE} from "../constants";
import {useDispatch, useSelector} from "react-redux";
import {loadOutOfRangeCount} from "../reducers/registers/events/outOfRangeCount";
import moment from "moment";
import {handleApiError} from "../reducers/messages";
import {hasAnyAuthSelector} from "../selectors/authSelectors";
import {ThemeProvider} from "@mui/material";
import {defaultMarcoTheme} from "../theme";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: '2px',
    },
    navMenu: {
        flexGrow: 1,
        '& a': {
            marginRight: '8px',
            marginLeft: '8px',
        }
    },
}));

let intervalId;
export default function TopNav() {
    const dispatch = useDispatch();
    const outOfRangeCountData = useSelector(state => state.registers.events.outOfRangeCount);
    const anyAuth = useSelector(hasAnyAuthSelector);

    const loadData = async () => {
        if (outOfRangeCountData === null ||
            (outOfRangeCountData.loadDate !== LOAD_STATE.PENDING && moment(outOfRangeCountData.loadDate).add(3,'m').isBefore())
        ) {
            try {
                await dispatch(loadOutOfRangeCount());
            } catch (e) {
                dispatch(handleApiError(e));
            }
        }
    }

    useEffect(() => {
        if (intervalId) {
            clearInterval(intervalId);
        }

        if (anyAuth) {
            intervalId = setInterval(() => loadData(), 1000000);
            loadData();
        }
        return () => clearInterval(intervalId);
    }, [loadData, anyAuth]);
    const classes = useStyles();
    const history = useHistory();
    const handleOnDashboardClick = _e => {
        history.push("/");
    }

    const outOfRangeCount = outOfRangeCountData ? outOfRangeCountData.count : 0;

    return (
        <ThemeProvider theme={defaultMarcoTheme}>
        <AppBar position="static" classes={classes.appBar}>
            <Toolbar>
                <HasAnyAuth>
                    <IconButton onClick={handleOnDashboardClick} size="large">
                        <Badge color="secondary">
                            <DashboardIcon/>
                        </Badge>
                    </IconButton>
                    <Typography variant={"h6"} className={classes.navMenu}>
                        <Link component={RouterLink} color="inherit" to="/registers">Registry</Link>
                        <Link component={RouterLink} color="inherit" to="/objects">Objekty</Link>
                        <HasRole role={ROLE.USERS_EDIT}>
                            <Link component={RouterLink} color="inherit" to="/users">Uživatelé</Link>
                        </HasRole>
                        <HasRole role={ROLE.ADMIN}>
                            <Link component={RouterLink} color="inherit" to="/admin">Administrace</Link>
                        </HasRole>
                    </Typography>

                        <Badge showZero={false} badgeContent={outOfRangeCount} color="secondary">
                            <Link component={RouterLink} color="inherit" to="/registers/out-of-range">
                                <NotificationsIcon/>
                            </Link>
                        </Badge>
                    <AccountMenu/>
                </HasAnyAuth>
                </Toolbar>
        </AppBar>
        </ThemeProvider>
    );
}
