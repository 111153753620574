import React, {useState} from 'react';
import PropTypes from "prop-types";
import moment from "moment";
import RegistersStatistics from "../registers/RegistersStatistics";
import {defaultHours} from "../../../components/HoursDateTimeFilter";
import DashboardContainer from "./DashboardContainer";

const initState = () => {
    return {
        from: moment().subtract(defaultHours, 'hours').format(),
        to: moment().format(),
        selectedHours: defaultHours
    }
};

const RegistersStatisticsDashboard = ({dashboard}) => {
    const {name, id, registerIds} = dashboard;
    const [{from, to, selectedHours}, setState] = useState(initState());
    const onHoursSelected = hours => {
        setState({
            from: moment().subtract(hours, "hours").format(),
            to: moment().format(),
            selectedHours: hours
        })
    };

    return (
        <DashboardContainer
            title={name}
            id={id}
            dateTimeFilterEnabled={true}
            registersMenuEnabled={true}
            hoursSelected={selectedHours}
            onHoursSelected={onHoursSelected}>
            <RegistersStatistics
                to={to}
                from={from}
                dataKey={`dashboard-${id}`}
                label={name}
                registerIds={registerIds}/>
        </DashboardContainer>
    )
}

RegistersStatisticsDashboard.propTypes = {
    dashboard: PropTypes.object.isRequired
};

export default RegistersStatisticsDashboard;
