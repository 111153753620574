import {createAction, handleActions} from "redux-actions";
import jwt_decode from "jwt-decode";
import {postUrl, putUrl} from "../utils/api";
import {GLOBAL_RESET_ACTION, globalResetAll} from "./global";
import {MESSAGE_SEVERENITY, newMessage} from "./messages";

export function authenticate(username, password) {
    return dispatch => {
        dispatch(authPending());
        return dispatch(postUrl("/auth",{username, password}, null, false))
            .then(({token, refreshToken}) => dispatch(storeTokens(token, refreshToken)))
            .catch(error => {
                if (error.message === "Access Denied") {
                    dispatch(newMessage(MESSAGE_SEVERENITY.WARNING, "Špatné přihlašovací údaje"))
                    console.warn(error)
                    return;
                }
                console.error(error)
            });
    }
}

export function refreshTokens(currentRefreshToken) {
    return async dispatch => {
        dispatch(authPending());
        const {token, refreshToken} = await dispatch(postUrl('/auth/refresh', {refreshToken: currentRefreshToken}, {}, false));
        return dispatch(storeTokens(token, refreshToken));
    }
}

export function logout() {
    return async(dispatch) => await dispatch(globalResetAll())
}

const STORAGE_REFRESH_TOKEN_KEY = 'REFRESH_TOKEN'
const AUTH_PENDING = 'AUTH_PENDING';
const AUTH_AUTHENTICATED = 'AUTH_AUTHENTICATED';
const AUTH_RESET = "AUTH_RESET";

const initAuth = {
    pending: false,
    authority: [],
    token: null,
    username: null,
    exp: null,
    iat: null
};

export const authPending = createAction(AUTH_PENDING);
export const authReset = createAction(AUTH_RESET);

export const storeTokens = (token, refreshToken) => {
    const data = jwt_decode(token);
    localStorage.setItem(STORAGE_REFRESH_TOKEN_KEY, refreshToken);
    return async(dispatch) => {
        return dispatch(createAction(AUTH_AUTHENTICATED)({token, data}));
    };
};

export function selectTokenData(state) {
    const {token, exp} = state.auth;
    return {
        token, exp
    };
}

export function selectRefreshToken() {
    return localStorage.getItem(STORAGE_REFRESH_TOKEN_KEY);
}

export const changePassword = newPassword => {
    return async(_dispatch) => await putUrl(`/profile/change-password`, newPassword);
}


export default handleActions({
    [GLOBAL_RESET_ACTION]: () => initAuth,
    [AUTH_RESET]: () => initAuth,
    [AUTH_PENDING]: (state, _action) =>  Object.assign({}, state, {pending:true}),
    [AUTH_AUTHENTICATED]: (state, {payload: {token, data:{sub, scope:{authority}, exp, iat}}}) =>
        Object.assign({}, state, {pending:false, token, username: sub, authority, exp, iat})
}, initAuth);

