import React from 'react';
import {Field, Form} from "react-final-form";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import SaveIcon from '@mui/icons-material/Save';
import {Checkboxes, TextField} from "mui-rff";

const UserProfileForm = ({onSubmit, initialValues}) => {
    return (
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <Grid container direction={"row"} alignItems={"flex-start"}>
                        <Grid container direction={"column"} alignItems={"flex-start"} spacing={1} xs={3}>
                            <TextField
                                name="firstName"
                                label="Jméno"
                                className="input"
                                type="text"
                            />
                            <TextField
                                name="surname"
                                label="Příjmení"
                                className="input"
                                type="text"
                            />
                            <TextField
                                name="email"
                                label="Email"
                                className="input"
                                type="text"
                            />
                            <TextField
                                name="phone"
                                label="Telefon"
                                className="input"
                                type="text"
                            />
                        </Grid>
                        <Grid container direction={"column"} alignItems={"flex-start"} spacing={1} xs={3}>
                            <Checkboxes
                                name="sendNotifs"
                                data={[{label: "Odesílat poruchy", value: true}]}/>
                            <Checkboxes
                                name="sendDayReport"
                                data={[{label: "Odesílat denní report", value: true}]}/>
                            <Checkboxes
                                name="sendWeekReport"
                                data={[{label: "Odesílat týdenní report", value: true}]}/>
                            <Checkboxes
                                name="sendMonthReport"
                                data={[{label: "Odesílat měsíční report", value: true}]}/>
                        </Grid>
                    </Grid>
                    <Button
                        type={"submit"}
                        startIcon={<SaveIcon/>}
                        variant="contained"
                        color="primary">
                        Uložit
                    </Button>
                </form>
            )}/>
    );
};

UserProfileForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
        surname: PropTypes.string,
        firstName: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
        sendSms: PropTypes.bool,
        sendNotifs: PropTypes.bool,
        sendDayReport: PropTypes.bool,
        sendWeekReport: PropTypes.bool,
        sendMonthReport: PropTypes.bool
    })
};

export default UserProfileForm;
