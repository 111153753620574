import React from 'react';
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {handleApiError} from "../../../reducers/messages";
import {updateDashboard, updateRegistersDashboard} from "../../../reducers/dashboards/entities";
import DashboardForm from "../../../components/forms/DashboardForm";
import RegistersDashboardForm from "../../../components/forms/RegistersDashboardForm";

const DashboardEdit = ({dashboard, onClose}) => {
    const dispatch = useDispatch();

    const handleOnSubmit = async (values) => {
            try {
                if (dashboard.type === 'REGISTERS_GRAPH') {
                    await dispatch(updateRegistersDashboard(dashboard.id, values));
                } else {
                    await dispatch(updateDashboard(dashboard.id, values));
                }
                onClose();
            } catch (error) {
                dispatch(handleApiError(error));
            }
        }


    if (dashboard.type === 'REGISTERS_GRAPH') {
        return (
            <RegistersDashboardForm
                onSubmit={handleOnSubmit}
                initialValues={{
                    name: dashboard.name,
                    metricInterval: dashboard.metricInterval,
                    metricVizualization: dashboard.metricVizualization
                }}
                onClose={onClose}/>
        );
    }

    return (
        <DashboardForm
            onClose={onClose}
            initialValues={{
                name: dashboard.name
            }}
            onSubmit={handleOnSubmit}/>
    )
}

DashboardEdit.propsTypes = {
    dashboard: PropTypes.object,
    onClose: PropTypes.func.isRequired
}

export default DashboardEdit;
