import {createSelector} from "reselect";
import {has} from "rambda";


export function registersSelector(ids) {
    return createSelector(
        state => state.registers.map,
        registers => {
            if (!ids) {
                return [];
            }
            const selected = ids.reduce((selected, id) => {
                if (has(id)(registers)) {
                    selected.push(registers[id]);
                }
                return selected;
            }, []);

            return selected.length === ids.length ? selected : [];
        }
    );
}
