import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DownloadLink from "react-download-link";
import {useDispatch} from "react-redux";
import {getRegistersExportFile} from "../../../reducers/export";
import Button from "@mui/material/Button";
import {postUrlFile} from "../../../utils/api";
import {handleApiError, MESSAGE_SEVERENITY, newMessage} from "../../../reducers/messages";
import ProgressDialog from "../../../components/modals/ProgressDialog";

const ExportImportPage = () => {


    // TODO removed dropzone dialog
    const dispatch = useDispatch();
    const getRegistersExport = async () => dispatch(getRegistersExportFile());
    const [showRegistersUploadDialog, setOpenRegistersUpload] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const closeRegistersUpload = () => {
        setOpenRegistersUpload(false);
    }

    const showRegistersUpload = () => {
        setOpenRegistersUpload(true);
    }

    const handleRegistersFiles = async (files) => {
        setShowLoader(true)
        closeRegistersUpload();
        try {
            const importResult = await dispatch(postUrlFile("/import/registers", files[0]));
            var msg = `Importováno - registrů: ${importResult.registersCount}, 
                typů registrů: ${importResult.registerTypesCount}, objektů: ${importResult.objectsCount}`;
            dispatch(newMessage(MESSAGE_SEVERENITY.SUCCESS, msg))
        } catch (e) {
            dispatch(handleApiError(e));
        } finally {
            setShowLoader(false);
        }
    }

    return (
        <Grid container direction={"column"}>
            <Grid item>
                <Typography variant="h4" component="h1" gutterBottom>
                    Export/Import
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="h5" component="h2" gutterBottom>
                    Registry:
                </Typography>
                <DownloadLink
                    label={"Stáhnout xlsx"}
                    filename={"registers-export.xlsx"}
                    exportFile={getRegistersExport}>
                </DownloadLink>
                <Button
                    variant={"contained"}
                    onClick={showRegistersUpload}>
                        Importovat registry
                </Button>
            </Grid>
            <ProgressDialog open={showLoader}/>
        </Grid>
    )
}

export default ExportImportPage;
