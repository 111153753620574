import React from 'react';
import {Form} from "react-final-form";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import PropTypes from "prop-types";
import {REGISTER_REPORT_TYPE, VALUE_TYPE} from "../../../reducers/constants";
import {keys} from "rambda";
import {composeValidators, mustBeNumber, required} from "../../../utils/validators";
import {Select, TextField} from "mui-rff";

const RegisterTypeForm = ({onSubmit, initialValues}) => {
    return (
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <Grid container direction={"row"} alignItems={"flex-start"}>
                        <Grid container direction={"column"} alignItems={"flex-start"} spacing={1} xs={3}>
                            <TextField
                                required
                                validate={required}
                                label="Název"
                                name="name"
                                margin="dense"
                                type="text"/>
                            <TextField
                                validate={composeValidators(required, mustBeNumber)}
                                name="coeficient"
                                label="Koeficient"
                                margin="dense"
                                required
                                defaultValue={1}
                                type="text"/>
                            <TextField
                                required
                                validate={composeValidators(required, mustBeNumber)}
                                name="minValue"
                                label="Min.hodnota"
                                margin="dense"
                                type="text"/>
                            <TextField
                                validate={composeValidators(required, mustBeNumber)}
                                name="maxValue"
                                margin="dense"
                                label="Max. hodnota"
                                type="text"/>
                            <Select
                                required
                                margin="dense"
                                validate={required}
                                name="rawValueType"
                                label="Datový typ - PLC">
                                {keys(VALUE_TYPE).map((valueType, index) => (
                                    <MenuItem key={index} value={valueType}>
                                        {valueType}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Select
                                required
                                margin="dense"
                                validate={required}
                                name="valueType"
                                label="Datový typ">
                                {keys(VALUE_TYPE).map((valueType, index) => (
                                    <MenuItem key={index} value={valueType}>
                                        {valueType}
                                    </MenuItem>
                                ))}>
                            </Select>

                            {/*TODO positive integer validator*/}
                            <TextField
                                margin="dense"
                                required
                                defaultValue={2}
                                validate={composeValidators(required, mustBeNumber())}                                name="precision"
                                label="Deset. míst"
                                type="text"/>
                            <Select
                                validate={required}
                                name="reportType"
                                label="Typ reportu">
                                {keys(REGISTER_REPORT_TYPE).map((reportType, index) => (
                                    <MenuItem key={index} value={reportType}>
                                        {reportType}
                                    </MenuItem>
                                ))}
                            </Select>
                            <TextField
                                margin="dense"
                                name="unit"
                                label="Veličina"
                                type="text"/>
                       </Grid>
                    </Grid>
                    <Button
                        type={"submit"}
                        startIcon={<SaveIcon />}
                        variant="contained"
                        color="primary">
                        Uložit
                    </Button>
                </form>
            )}/>
    );
};

RegisterTypeForm.propTypes = {
    initialValues: PropTypes.shape({
        name: PropTypes.string,
        coeficient: PropTypes.number,
        minValue: PropTypes.number,
        maxValue: PropTypes.number,
        rawValueType: PropTypes.oneOf(keys(VALUE_TYPE)),
        valueType: PropTypes.oneOf(keys(VALUE_TYPE)),
        reportType: PropTypes.oneOf(keys(REGISTER_REPORT_TYPE)),
        unit: PropTypes.string,
        precision: PropTypes.number
    }),
    onSubmit: PropTypes.func.isRequired
}

export default RegisterTypeForm;
