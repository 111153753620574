import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import MaterialTable from "material-table";
import Paper from "@mui/material/Paper";
import {addUserObjects, deleteUserObjects, loadUserObjects, unloadUserObjects} from "../../../reducers/users/objects";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import AddUserObjectsModal from "../../../components/modals/AddUserObjectsModal";
import {handleApiError, MESSAGE_SEVERENITY, newMessage} from "../../../reducers/messages";
import {loadObjectsList} from "../../../reducers/objects/list";
import {defaultMarcoTheme} from "../../../theme";
import {ThemeProvider} from "@mui/material";

const UserObjectsGrid = ({userId}) => {
    const dispatch = useDispatch();
    const userObjects = useSelector(state => state.users.objects[userId]);
    const objectsList = useSelector(state => state.objects.list);
    const [showAddModal, setShowAddModal] = useState(false);

    const assignedObjectIds = userObjects ? userObjects.map(userObject => userObject.object.id) : [];
    const unassignedObjectsList = objectsList ? objectsList.content.filter(object => !assignedObjectIds.includes(object.id)) : [];

    useEffect(() => {
        dispatch(loadUserObjects(userId));
        dispatch(loadObjectsList());
    }, [dispatch, userId]);

    useEffect(() => {
        return () => dispatch(unloadUserObjects(userId))
    }, []);

    const handleDelete = async(userObjects) => {
        try {
            const userObjectsIds = userObjects.map(userObject => userObject.id);
            await dispatch(deleteUserObjects(userId, userObjectsIds));
            dispatch(newMessage(MESSAGE_SEVERENITY.SUCCESS, "Přidělení odstraněno"));
        } catch (e) {
            dispatch(handleApiError(e));
        }
        await dispatch(loadUserObjects(userId));
    };

    const handleOpenAddObjectModal = () => {
        setShowAddModal(true);
    };

    const handleCloseAddObjectModal = () => {
        setShowAddModal(false);
    };

    const handleAddObjects = async(objects) => {
        try {
            await dispatch(addUserObjects(userId, objects.map(object => object.id)));
            dispatch(newMessage(MESSAGE_SEVERENITY.SUCCESS, "Přidělené objekty aktuálizovány"));
        } catch (e) {
            dispatch(handleApiError(e));
        }
        await dispatch(loadUserObjects(userId));
    };

    if (!userObjects) {
        return (
            <div>
                <CircularProgress />
            </div>
        )
    }

    return (
        <ThemeProvider theme={defaultMarcoTheme}>
        <MaterialTable
                actions={[{
                    icon: 'remove',
                    position: "toolbarOnSelect",
                    tooltip: 'Odebrat',
                    onClick: (_e, objects) => handleDelete(objects)
                }, {
                    icon: 'add',
                    position: "toolbar",
                    tooltip: 'Přidat',
                    onClick: handleOpenAddObjectModal
                }]}
                options={{
                    pageSize:15,
                    pageSizeOptions: [15, 30, 100],
                    selection: true,
                    cellStyle: {padding: '0.3em'},
                    headerStyle: {padding: '0.3em'},
                    rowStyle: (_object, index) => {
                        return {
                            backgroundColor: index % 2 ? "white" : "aliceBlue",
                        }
                    }
                }}
                components={{
                    Container: props => <Paper {...props} elevation={0}/>
                }}
                columns={[
                    {title: 'Objekt', field: 'object.name'},
                    {title: 'Společnost', field: 'object.company.name'},
                    {title: 'Přidělil', field: 'assigner.username'},
                    {title: 'Datum', field: 'date'}
                ]}
                data={userObjects}
                title="Přidělené objekty"
            />

            {showAddModal && (
                <AddUserObjectsModal
                    handleAddObjects={handleAddObjects}
                    handleClose={handleCloseAddObjectModal}
                    objectsList={unassignedObjectsList}/>
            )}
        </ThemeProvider>
    );
}

UserObjectsGrid.propTypes = {
    userId: PropTypes.number.isRequired
}
export default UserObjectsGrid;
